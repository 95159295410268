import React from 'react';
import Logo from './Logo';
import NavSticky from '../containers/NavSicky/NavSticky';
import NavSticky2 from '../containers/NavSicky/NavSticky2';

import Login from '../containers/Login/Login';
import BasketCard from './BasketCard';

//TODO
// Implement transparent nav shadow

const Header = ({toggleProfile, setUserStatus, inn, userName, userStatus, route, searchString,
                    onRouteChange, onMenuToggle, isMenuOpen, orderSum, goodsAmount, updateSearchString}) => {
    return(
        <header className="header">
            <div className="container">
            {/*<BasketCard/>*/}

            <NavSticky
                toggleProfile ={toggleProfile}
                setUserStatus = {setUserStatus}
                inn = {inn}
                userStatus={userStatus}
                route={route}
                onRouteChange={onRouteChange}
                onMenuToggle={onMenuToggle}
                isMenuOpen={isMenuOpen}
                orderSum={orderSum}
                goodsAmount={goodsAmount}
                updateSearchString={updateSearchString}
                searchString = {searchString}
            />
            <NavSticky2
                toggleProfile ={toggleProfile}
                setUserStatus = {setUserStatus}
                inn = {inn}
                userName={userName}
                userStatus={userStatus}
                route={route}
                onRouteChange={onRouteChange}
                onMenuToggle={onMenuToggle}
                isMenuOpen={isMenuOpen}
                orderSum={orderSum}
                goodsAmount={goodsAmount}
                updateSearchString={updateSearchString}
                searchString = {searchString}
            />
                {/* <Logo/> */}
                {/*<Login/>*/}
            </div>
        </header>
    )
};

export default Header;
import React, {useEffect, useState} from 'react';

//TODO
//  add code to folder obj, use code as id for element
const CatalogStructure = ({folders, toggleChildren, folderSelect, folder,visitedLinks}) => {
    const [isStick, setIsStick] = useState(false);
    const classItem = 'category-wrap ' + (isStick?'stick':'');

// Show button when page is scorlled upto given distance
    const toggleStick = () => {
        const w = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        if (w > 1200) {
            if (window.pageYOffset > 300) {
                setIsStick(true);
            } else {
                setIsStick(false);
            }
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", toggleStick);
    }, []);

    return(
        <div className={classItem}>
            {
                folders.map(elem => createFolderElement(elem, toggleChildren, folderSelect, folder,visitedLinks))
            }
        </div>
    )
};

const createSubFolderElement = (folderObj, toggleChildren, folderSelect, folder,visitedLinks) => {

    const folderHasChildren = !!folderObj.children.length;
    const isVisited = visitedLinks.includes(folderObj.code);
    const folderFunction = (folderHasChildren) ? toggleChildren : folderSelect;
    const folderClassList = 'aside__link' + (folderObj.code === folder ? ' black' : '') + (isVisited?' visited':'');
    //  + (folderHasChildren ? '' : ' dn');
    return(
        <li className="aside__row"><a href="#" id={folderObj.code} className={folderClassList} onClick={folderFunction}>{folderObj.name}</a></li>
    );

};

const createFolderElement = (folderObj, toggleChildren, folderSelect, folder,visitedLinks) => {

    const folderHasChildren = !!folderObj.children.length;
    const folderFunction = (folderHasChildren) ? toggleChildren : folderSelect;
    const folderClassList = 'mt1 pointer hover-blue underline-hover'
            + (folderObj.code === folder ? ' b' : '');
          //  + (folderHasChildren ? '' : ' dn');

    return(
        <div className="aside__item">
            <p id={folderObj.code} className="aside__head" onClick={folderFunction}>{folderObj.name}<i className="aside__arrow"></i></p>
            <ul className="aside__list dn">
            {
                folderObj.children.map(elem => createSubFolderElement(elem, toggleChildren, folderSelect, folder,visitedLinks))
            }
            </ul>
        </div>
    );

};


export default CatalogStructure;
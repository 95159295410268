const goods = [
    {
        folder: 'Промышленные разъемы',
        code: 9411,
        description: 'Вилка переносная 013 2Р+РE 16А 220В IP44',
        price: 115.74,
        measure: 'шт',
        spec: false,
        quantity: 1,
        sort: '013 Вилка переносная 2Р+РE 16А 220В IP44  (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Промышленные разъемы',
        code: 2495,
        description: 'Вилка переносная 014 3Р+PE 16А 380В IP44',
        price: 130.02,
        measure: 'шт',
        spec: false,
        quantity: 92,
        sort: '014 Вилка переносная 3Р+PE 16А 380В IP44   (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Промышленные разъемы',
        code: 9413,
        description: 'Вилка переносная 015 3Р+PE+N 16А 380В IP44',
        price: 155.52,
        measure: 'шт',
        spec: false,
        quantity: 5,
        sort: '015 Вилка переносная 3Р+PE+N 16А 380В IP44  (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Промышленные разъемы',
        code: 3063,
        description: 'Вилка переносная 023 2P+E 32А 220В IP44 ',
        price: 179.58,
        measure: 'шт',
        spec: false,
        quantity: 33,
        sort: '023 Вилка переносная 2P+E 32А 220В IP44   (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Промышленные разъемы',
        code: 1563,
        description: 'Вилка переносная 024 3P+PE 32А 380В IP44',
        price: 194.16,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: '024 Вилка переносная 3P+PE 32А 380В IP44  (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Промышленные разъемы',
        code: 1731,
        description: 'Вилка переносная 025 3P+E+N 32А 380В IP44',
        price: 230.16,
        measure: 'шт',
        spec: false,
        quantity: 52,
        sort: '025 Вилка переносная 3P+E+N 32А 380В IP44  (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Промышленные разъемы',
        code: 9410,
        description: 'Розетка стационарная 113 2Р+PE 16А 220В IP44',
        price: 187.32,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: '113 Розетка стационарная 2Р+PE 16А 220В IP44 ИЭК  (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Промышленные разъемы',
        code: 11132,
        description: 'Розетка стационарная 114 3Р+PE 16А 380В IP44',
        price: 199.8,
        measure: 'шт',
        spec: false,
        quantity: 20,
        sort: '114 Розетка стационарная 3Р+PE 16А 380В IP44  (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Промышленные разъемы',
        code: 9412,
        description: 'Розетка стационарная 115 3Р+PE+N 16А 380В IP44',
        price: 212.82,
        measure: 'шт',
        spec: false,
        quantity: 15,
        sort: '115 Розетка стационарная 3Р+PE+N 16А 380В IP44   (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Промышленные разъемы',
        code: 16136,
        description: 'Розетка стационарная 123 2Р+PE 32А 220В IP44',
        price: 237.84,
        measure: 'шт',
        spec: false,
        quantity: 44,
        sort: '123 Розетка стационарная 2Р+PE 32А 220В IP44  (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Промышленные разъемы',
        code: 1564,
        description: 'Розетка стационарная 124 3Р+PE 32А 380В IP44',
        price: 256.2,
        measure: 'шт',
        spec: false,
        quantity: 19,
        sort: '124 Розетка стационарная 3Р+PE 32А 380В IP44  (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Промышленные разъемы',
        code: 5066,
        description: 'Розетка стационарная 125 32А 3Р+N+E 380В IР44',
        price: 273.6,
        measure: 'шт',
        spec: false,
        quantity: 122,
        sort: '125 Розетка стационарная 32А 3Р+N+E IР44 380В  (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Пускорегулирующая аппаратура для люминесцентных ламп',
        code: 780,
        description: 'Дроссель для люминесцентных ламп АВТ 1И18/20',
        price: 152.58,
        measure: 'шт',
        spec: false,
        quantity: 184,
        sort: '1И18/20 Дроссель для люм.ламп АВТ    (40-30шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Пускорегулирующая аппаратура для люминесцентных ламп',
        code: 782,
        description: 'Дроссель для люминесцентных ламп АВТ 1И36/40',
        price: 152.58,
        measure: 'шт',
        spec: true,
        quantity: 713,
        sort: '1И36/40 Дроссель для люм.ламп АВТ   (30шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Приборы учета электроэнергии',
        code: 1948,
        description: 'Счетчик электроэнергии Меркурий 200.02 1ф 2т 5-60А',
        price: 1821.6,
        measure: 'шт',
        spec: true,
        quantity: 4,
        sort: '200.02 Счетчик эл.энергии Меркурий 1ф 2т 5-60А',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Приборы учета электроэнергии',
        code: 6137,
        description: 'Счетчик электроэнергии Меркурий 201.5 1ф 1т 5-60А',
        price: 668.4,
        measure: 'шт',
        spec: true,
        quantity: 99,
        sort: '201.5 Счетчик эл.энергии Меркурий 1ф 1т 5-60А',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Промышленные разъемы',
        code: 3329,
        description: 'Розетка переносная 213 2Р+E 16А 220B IP44',
        price: 141.06,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: '213 Розетка переносная 2Р+E 16А 220B IP44   (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Промышленные разъемы',
        code: 2494,
        description: 'Розетка переносная 214 3Р+PE 16А 380B IP44',
        price: 153.9,
        measure: 'шт',
        spec: false,
        quantity: 86,
        sort: '214 Розетка переносная 3Р+PE 16А 380B IP44   (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клеммники из PRC',
        code: 9991,
        description: 'СК-412 Клеммник 2х(0,08-2,5) для медных многожильных проводов',
        price: 10.2,
        measure: 'шт',
        spec: true,
        quantity: 7517,
        sort: '222-412 (TDM СК-412) Клеммник 2х(0,2-4мм) для многожильн.проводов (50шт.уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клеммники WAGO',
        code: 5152,
        description: '222-412 WAGO Клеммник 2х(0,08-2,5) для медных многожильных проводов',
        price: 17.28,
        measure: 'шт',
        spec: false,
        quantity: 336,
        sort: '222-412 Клеммник 2х(0,08-2,5-4,0) для многожильн.проводов (50шт.уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клеммники из PRC',
        code: 9992,
        description: 'СК-413 Клеммник 3х(0,08-2,5) для медных многожильных проводов',
        price: 13.86,
        measure: 'шт',
        spec: true,
        quantity: 5908,
        sort: '222-413 (TDM СК-413) Клеммник 3х(0,2-4мм) для многожильн.проводов (20шт.уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клеммники WAGO',
        code: 1012,
        description: '222-413 WAGO Клеммник 3х(0,08-2,5) для медных многожильных проводов',
        price: 21.54,
        measure: 'шт',
        spec: false,
        quantity: 920,
        sort: '222-413 Клеммник 3х(0,08-2,5-4,0) для многожильн.проводов (50шт.уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клеммники из PRC',
        code: 9993,
        description: 'СК-415 Клеммник 5х(0,08-2,5) для медных многожильных проводов',
        price: 22.32,
        measure: 'шт',
        spec: false,
        quantity: 2198,
        sort: '222-415 (TDM СК-415) Клеммник 5х(0,2-4мм) для многожильн.проводов (50шт./15шт. в упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клеммники WAGO',
        code: 1013,
        description: '222-415 WAGO Клеммник 5х(0,08-2,5) для медных многожильных проводов',
        price: 34.44,
        measure: 'шт',
        spec: false,
        quantity: 19,
        sort: '222-415 Клеммник 5х(0,08-2,5-4,0) для многожильн.проводов (40шт. в упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Промышленные разъемы',
        code: 1838,
        description: 'Розетка переносная 224 3P+PE 32А 380В IP44',
        price: 211.68,
        measure: 'шт',
        spec: false,
        quantity: 26,
        sort: '224 Розетка переносная 3P+PE 32А 380В IP44 (10шт.упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Промышленные разъемы',
        code: 1732,
        description: 'Розетка переносная 225 3P+E+N 32А 380В IP44',
        price: 261.18,
        measure: 'шт',
        spec: false,
        quantity: 20,
        sort: '225 Розетка переносная 3P+E+N 32А 380В IP44   (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клеммники WAGO',
        code: 17636,
        description: '2273-202 WAGO Клеммник 2х(0,5-2,5) для распределительных коробок',
        price: 6.36,
        measure: 'шт',
        spec: false,
        quantity: 626,
        sort: '2273-202 Клеммник 2х(0,5-2,5) для распред. кор. (100шт/уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клеммники WAGO',
        code: 12407,
        description: '2273-203 WAGO Клеммник 3х(0,5-2,5) для распределительных коробок',
        price: 6.84,
        measure: 'шт',
        spec: false,
        quantity: 1170,
        sort: '2273-203 Клеммник 3х(0,5-2,5) для распред. кор. (100шт/уп,)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клеммники WAGO',
        code: 9799,
        description: '2273-204 WAGO Клеммник 4х(0,5-2,5) для распределительных коробок',
        price: 8.64,
        measure: 'шт',
        spec: false,
        quantity: 924,
        sort: '2273-204 Клеммник 4х(0,5-2,5) для распред. кор. (100шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клеммники WAGO',
        code: 9802,
        description: '2273-205 WAGO Клеммник 5х(0,5-2,5) для распределительных коробок',
        price: 9.48,
        measure: 'шт',
        spec: false,
        quantity: 230,
        sort: '2273-205 Клеммник 5х(0,5-2,5) для распред. кор.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клеммники из PRC',
        code: 16622,
        description: '2273-232 Клеммник 2х(0,5-2,5) для распределительных коробок',
        price: 4.2,
        measure: 'шт',
        spec: false,
        quantity: 1096,
        sort: '2273-232 Клеммник 2х(0,5-2,5) С ПАСТОЙ для распред. кор. ТДМ (250шт/уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клеммники из PRC',
        code: 16623,
        description: '2273-233 Клеммник 3х(0,5-2,5) для распределительных коробок',
        price: 5.46,
        measure: 'шт',
        spec: false,
        quantity: 325,
        sort: '2273-233 Клеммник 3х(0,5-2,5) С ПАСТОЙ для распред. кор. ТДМ (180шт/уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клеммники из PRC',
        code: 16624,
        description: '2273-235 Клеммник 5х(0,5-2,5) для распределительных коробок',
        price: 7.56,
        measure: 'шт',
        spec: false,
        quantity: 150,
        sort: '2273-235 Клеммник 5х(0,5-2,5) С ПАСТОЙ для распред. кор. ТДМ (100шт/уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клеммники из PRC',
        code: 16620,
        description: '2273-244 Клеммник 4х(0,5-2,5) для распределительных коробок',
        price: 5.22,
        measure: 'шт',
        spec: false,
        quantity: 2460,
        sort: '2273-244 Клеммник 4х(0,5-2,5) для распред. кор. ТДМ (130шт/уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клеммники из PRC',
        code: 16621,
        description: '2273-246 Клеммник 6х(0,5-2,5) для распределительных коробок',
        price: 7.8,
        measure: 'шт',
        spec: false,
        quantity: 955,
        sort: '2273-246 Клеммник 6х(0,5-2,5) С ПАСТОЙ для распред. кор. ТДМ (90шт/уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клеммники из PRC',
        code: 16625,
        description: '2273-248 Клеммник 8х(0,5-2,5) для распределительных коробок',
        price: 9.54,
        measure: 'шт',
        spec: false,
        quantity: 134,
        sort: '2273-248 Клеммник 8х(0,5-2,5) С ПАСТОЙ для распред. кор. ТДМ (70шт/уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Приборы учета электроэнергии',
        code: 13,
        description: 'Счетчик электроэнергии Меркурий 230AM-01 3ф 1т 5-60А',
        price: 2262,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: '230AM-01 Счетчик эл.энергии Меркурий 3ф 1т 5-60А',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Приборы учета электроэнергии',
        code: 3480,
        description: 'Счетчик электроэнергии Меркурий 230AM-02 3ф 1т10-100А',
        price: 2262,
        measure: 'шт',
        spec: false,
        quantity: 5,
        sort: '230AM-02 Счетчик эл.энергии Меркурий 3ф 1т 10-100А',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Приборы учета электроэнергии',
        code: 14,
        description: 'Счетчик электроэнергии Меркурий 230AM-03 3ф 1т 5-7,5А',
        price: 2262,
        measure: 'шт',
        spec: false,
        quantity: 4,
        sort: '230AM-03 Счетчик эл.энергии Меркурий 3ф 1т 5-7,5А',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Приборы учета электроэнергии',
        code: 1603,
        description: 'Счетчик электроэнергии Меркурий 230ART-01 CN 3ф 2т 5-60А',
        price: 4942.62,
        measure: 'шт',
        spec: false,
        quantity: 3,
        sort: '230ART-01 CN Счетчик эл.энергии Меркурий 3ф 2т 5-60А',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Приборы учета электроэнергии',
        code: 1014,
        description: 'Счетчик электроэнергии Меркурий 230ART-02 CN 3ф 2т 10-100А',
        price: 4942.62,
        measure: 'шт',
        spec: false,
        quantity: 5,
        sort: '230ART-02 CN Счетчик эл. энергии Меркурий 3ф 2т 10-100А',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Приборы учета электроэнергии',
        code: 4028,
        description: 'Счетчик электроэнергии Меркурий 230ART-03 CN 3ф 2т 5-7,5А',
        price: 4942.62,
        measure: 'шт',
        spec: false,
        quantity: 2,
        sort: '230ART-03 CN (!!! см. описание) Счетчик эл.энергии Меркурий 3ф 2т 5-7,5А',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Приборы учета электроэнергии',
        code: 21363,
        description: 'Счетчик эл.энергии Меркурий 230ART-03 RN 3ф 2т 5-50А  ',
        price: 5071.38,
        measure: 'шт',
        spec: false,
        quantity: 2,
        sort: '230ART-03 RN Счетчик эл.энергии Меркурий 3ф 2т 5-50А',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Элементы питания и аккумуляторы',
        code: 6997,
        description: 'Элемент питания 6LF22 BL-1 "Крона"',
        price: 76.5,
        measure: 'шт',
        spec: false,
        quantity: 31,
        sort: '6LF22 BL-1 Элемент питания "Крона"',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 9918,
        description: 'Арматура светосигнальная AD22DS 230В зеленая',
        price: 46.8,
        measure: 'шт',
        spec: false,
        quantity: 7,
        sort: 'AD22DS Арматура светосигнальная 230В зеленая  (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 9919,
        description: 'Арматура светосигнальная AD22DS 230В красная',
        price: 46.8,
        measure: 'шт',
        spec: false,
        quantity: 6,
        sort: 'AD22DS Арматура светосигнальная 230В красная  (10шт.упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 17557,
        description: 'DIN-рейка L=7,5см',
        price: 6.48,
        measure: 'шт',
        spec: false,
        quantity: 28,
        sort: 'DIN-рейка   7,5 cм',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 4195,
        description: 'DIN-рейка L=30см',
        price: 19.92,
        measure: 'шт',
        spec: false,
        quantity: 80,
        sort: 'DIN-рейка  30 см',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 806,
        description: 'DIN-рейка L=60см',
        price: 39.12,
        measure: 'шт',
        spec: false,
        quantity: 47,
        sort: 'DIN-рейка  60 см',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 22480,
        description: 'DIN-рейка L=100см усиленная алюминиевая 1,5мм ТДМ',
        price: 152.1,
        measure: 'шт',
        spec: false,
        quantity: 1,
        sort: 'DIN-рейка 100см усиленная алюминиевая 1,5мм ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 5181,
        description: 'DIN-рейка L=120см',
        price: 76.98,
        measure: 'шт',
        spec: false,
        quantity: 102,
        sort: 'DIN-рейка 120см',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 3346,
        description: 'DIN-рейка L=125см',
        price: 90.9,
        measure: 'шт',
        spec: false,
        quantity: 197,
        sort: 'DIN-рейка 125 см',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 15337,
        description: 'DIN-рейка L=140см',
        price: 85.92,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'DIN-рейка 140 см',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 805,
        description: 'DIN-рейка L=2м',
        price: 110.64,
        measure: 'шт',
        spec: false,
        quantity: 167,
        sort: 'DIN-рейка 2 м',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели дифференциального тока (дифференциальные автоматы) 2п',
        code: 27475,
        description: '2CSR245072R1164 ABB Автоматический выключатель дифференциального тока DSH201R C16 16A/30mA',
        price: 2606.76,
        measure: 'шт',
        spec: false,
        quantity: 4,
        sort: 'DSH201R C16 16A/30mA диф.авт. ABB',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели дифференциального тока (дифференциальные автоматы) 2п',
        code: 27476,
        description: '2CSR245072R1254 ABB Автоматический выключатель дифференциального тока DSH201R C25 25А/30mA',
        price: 2606.76,
        measure: 'шт',
        spec: false,
        quantity: 1,
        sort: 'DSH201R C25 25A/30mA диф.авт. ABB',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Компактные люминесцентные лампы',
        code: 7641,
        description: '4050300010618 Лампа люминесцентная компактная OSRAM DULUX S 11W/21-840 G23',
        price: 101.76,
        measure: 'шт',
        spec: false,
        quantity: 1490,
        sort: 'DULUX S 11W/21-840 G23 OSRAM',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные фонари и аварийные указатели',
        code: 3041,
        description: 'Фонарь FA19M аккумуляторный прожектор 19 LED',
        price: 1375.02,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'FA19M Фонарь (аккум.прожектор, 19 LED)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Патроны',
        code: 16264,
        description: 'Патрон G23 пластиковый',
        price: 39.24,
        measure: 'шт',
        spec: false,
        quantity: 500,
        sort: 'G23 Патрон пластиковый   (250шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': 'пластик',
        '000000031': 'G23',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы галогеновые',
        code: 3929,
        description: 'Лампа галогенная капсульная HCS CL 220V 40W G9 FOTON',
        price: 32.82,
        measure: 'шт',
        spec: false,
        quantity: 87,
        sort: 'HCS CL 220V 40W G9 FOTON   (10шт/упак)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Пускорегулирующая аппаратура для люминесцентных ламп',
        code: 3735,
        description: 'Дроссель L 7/9/11W для PL-11',
        price: 144.48,
        measure: 'шт',
        spec: false,
        quantity: 299,
        sort: 'L 7/9/11W Дроссель для PL-11  (50шт./упак.) !!! предлагаем код 25601',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 18665,
        description: 'Лампа светодиодная 5Вт Е14 свеча 4000К',
        price: 53.52,
        measure: 'шт',
        spec: false,
        quantity: 242,
        sort: 'LED   5Вт Е14 4000К Лампа светодиодная свеча   (100шт/кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '5Вт',
        '000000034': 'Е14',
        '000000035': 'нейтральная',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 22976,
        description: 'Лампа светодиодная 5Вт Е27 A60 4000К',
        price: 53.52,
        measure: 'шт',
        spec: false,
        quantity: 895,
        sort: 'LED   5Вт Е27 4000К Лампа светодиодная A60',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '5Вт',
        '000000034': 'Е27',
        '000000035': 'нейтральная',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 19605,
        description: 'Лампа светодиодная 7Вт E14 свеча 2700К',
        price: 54.78,
        measure: 'шт',
        spec: false,
        quantity: 548,
        sort: 'LED   7Вт Е14 2700К Лампа светодиодная свеча  (100шт/кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '7Вт',
        '000000034': 'Е14',
        '000000035': 'тёплая',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 20682,
        description: 'Лампа светодиодная 7Вт Е14 шар 2700К',
        price: 54.78,
        measure: 'шт',
        spec: false,
        quantity: 3464,
        sort: 'LED   7Вт Е14 2700К Лампа светодиодная шар',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '7Вт',
        '000000034': 'Е14',
        '000000035': 'тёплая',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 19948,
        description: 'Лампа светодиодная 7Вт Е27 2700К',
        price: 54.78,
        measure: 'шт',
        spec: false,
        quantity: 16142,
        sort: 'LED   7Вт Е27 2700К Лампа светодиодная A60  (100шт/кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '7Вт',
        '000000034': 'Е27',
        '000000035': 'тёплая',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 13225,
        description: 'Лампа светодиодная 7Вт Е27 4000К',
        price: 54.78,
        measure: 'шт',
        spec: true,
        quantity: 19499,
        sort: 'LED   7Вт Е27 4000К Лампа светодиодная A60   (100шт/кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '7Вт',
        '000000034': 'Е27',
        '000000035': 'нейтральная',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 19950,
        description: 'Лампа светодиодная 8Вт E27 G45F (шар) 2700K',
        price: 56.2,
        measure: 'шт',
        spec: false,
        quantity: 162,
        sort: 'LED   8Вт Е27 2700K Лампа светодиодная G45F (шар)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '8Вт',
        '000000034': 'Е27',
        '000000035': 'тёплая',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 21529,
        description: 'Лампа светодиодная 8Вт E27 G45F (шар) 4500K',
        price: 56.2,
        measure: 'шт',
        spec: false,
        quantity: 158,
        sort: 'LED   8Вт Е27 4500K Лампа светодиодная G45F (шар)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '8Вт',
        '000000034': 'Е27',
        '000000035': 'нейтральная',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 16629,
        description: 'Лампа светодиодная 9Вт T8 G13 4500K',
        price: 78.84,
        measure: 'шт',
        spec: false,
        quantity: 7909,
        sort: 'LED   9Вт T8 G13 4500K l=600mm (аналог ЛБ-20) (не включать с ЭПРА и ЭмПРА) (30шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '9Вт',
        '000000034': 'G13',
        '000000035': 'нейтральная',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 22599,
        description: 'Лампа светодиодная 10Вт T8 G13 6500K',
        price: 78.84,
        measure: 'шт',
        spec: true,
        quantity: 7659,
        sort: 'LED   9Вт T8 G13 6500K l=600mm (аналог ЛД-20) (не включать с ЭПРА и ЭмПРА)  (25шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '9Вт',
        '000000034': 'G13',
        '000000035': 'холодная',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 19903,
        description: 'Лампа светодиодная 9Вт Е27 A60 2700К',
        price: 53.1,
        measure: 'шт',
        spec: false,
        quantity: 500,
        sort: 'LED   9Вт Е27 2700К Лампа светодиодная A60   (100шт./кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '9Вт',
        '000000034': 'Е27',
        '000000035': 'тёплая',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 20685,
        description: 'Лампа светодиодная 9Вт Е27 А60 4500К',
        price: 53.1,
        measure: 'шт',
        spec: false,
        quantity: 601,
        sort: 'LED   9Вт Е27 4500К Лампа светодиодная А60',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '9Вт',
        '000000034': 'Е27',
        '000000035': 'нейтральная',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 22979,
        description: 'Лампа светодиодная 10Вт E27 А60 3000К',
        price: 58.5,
        measure: 'шт',
        spec: false,
        quantity: 2210,
        sort: 'LED  10Вт E27 3000К Лампа светодиодная A60',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '10Вт',
        '000000034': 'Е27',
        '000000035': 'тёплая',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 13158,
        description: 'Лампа светодиодная 10Вт Е27 4000К',
        price: 58.5,
        measure: 'шт',
        spec: false,
        quantity: 1936,
        sort: 'LED  10Вт Е27 4000К Лампа светодиодная A60',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '10Вт',
        '000000034': 'Е27',
        '000000035': 'нейтральная',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 19606,
        description: 'Лампа светодиодная 11Вт E27 A60 4000К',
        price: 55.44,
        measure: 'шт',
        spec: true,
        quantity: 2703,
        sort: 'LED  11Вт Е27 4000К Лампа светодиодная A60  (100шт/кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '11Вт',
        '000000034': 'Е27',
        '000000035': 'нейтральная',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 17864,
        description: 'Лампа светодиодная 12Вт Е27 3000К',
        price: 63.36,
        measure: 'шт',
        spec: false,
        quantity: 269,
        sort: 'LED  12Вт Е27 3000К Лампа светодиодная А60  (100шт/кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '12Вт',
        '000000034': 'Е27',
        '000000035': 'тёплая',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 18319,
        description: 'Лампа светодиодная 12Вт Е27 4000К',
        price: 63.36,
        measure: 'шт',
        spec: false,
        quantity: 49,
        sort: 'LED  12Вт Е27 4000К Лампа светодиодная A60   (100шт./кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '12Вт',
        '000000034': 'Е27',
        '000000035': 'нейтральная',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 23562,
        description: 'Лампа светодиодная 15Вт Е27 4000К',
        price: 80.7,
        measure: 'шт',
        spec: false,
        quantity: 1334,
        sort: 'LED  15Вт Е27 4000К Лампа светодиодная A60',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '15Вт',
        '000000034': 'Е27',
        '000000035': 'нейтральная',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 16630,
        description: 'Лампа светодиодная 18Вт T8 G13 4500K',
        price: 94.32,
        measure: 'шт',
        spec: false,
        quantity: 7600,
        sort: 'LED  18Вт T8 G13 4500K l=1200mm лампа (аналог ЛБ-40) (не включать с ЭПРА и ЭмПРА)  (25шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '18Вт',
        '000000034': 'G13',
        '000000035': 'нейтральная',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 21505,
        description: 'Лампа светодиодная 18Вт T8 G13 6500K',
        price: 94.32,
        measure: 'шт',
        spec: true,
        quantity: 4885,
        sort: 'LED  18Вт T8 G13 6500K l=1200mm лампа (аналог ЛД-40) (не включать с ЭПРА и ЭмПРА)  (25шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '18Вт',
        '000000034': 'G13',
        '000000035': 'холодная',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 22791,
        description: 'Лампа светодиодная 20Вт Е27 4500K A60',
        price: 103.56,
        measure: 'шт',
        spec: false,
        quantity: 46,
        sort: 'LED  20Вт Е27 4500K A60 Лампа светодиодная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '20Вт',
        '000000034': 'Е27',
        '000000035': 'нейтральная',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 21608,
        description: 'Лампа светодиодная 30Вт 6500К E27',
        price: 208.02,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'LED  30Вт Лампа светодиодная 6500К E27',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '30Вт',
        '000000034': 'Е27',
        '000000035': 'холодная',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные лампы',
        code: 21991,
        description: 'Лампа светодиодная 40Вт 6400К Е27',
        price: 325.02,
        measure: 'шт',
        spec: true,
        quantity: 15,
        sort: 'LED  40Вт Лампа светодиодная 6400К Е27',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '40Вт',
        '000000034': 'Е27',
        '000000035': 'холодная',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 14411,
        description: 'Светильник светодиодный 18 Вт 600 мм IP40',
        price: 224.46,
        measure: 'шт',
        spec: false,
        quantity: 588,
        sort: 'LED 18Вт Cветильник (аналог ЛПО 2х18 узкий) IP40 600х22х75  (20шт./кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 20459,
        description: 'Светильник светодиодный 18 Вт 600 мм IP65',
        price: 351.96,
        measure: 'шт',
        spec: false,
        quantity: 44,
        sort: 'LED 18Вт Светильник (аналог ЛСП 2х18 узкий) IP65   (10шт.кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 20904,
        description: 'Светильник светодиодный 36 Вт 1200 мм IP40',
        price: 331.5,
        measure: 'шт',
        spec: true,
        quantity: 8255,
        sort: 'LED 36Вт Светильник (аналог ЛПО 2х36 узкий) IP40  (30шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 18916,
        description: 'Светильник светодиодный 36 Вт 1200 мм IP65',
        price: 549.84,
        measure: 'шт',
        spec: true,
        quantity: 1428,
        sort: 'LED 36Вт светильник (аналог ЛСП 2х36 узкий) IP65  (30шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 18915,
        description: 'Светильник светодиодный 36 Вт 1200 мм IP65',
        price: 920.7,
        measure: 'шт',
        spec: false,
        quantity: 2,
        sort: 'LED 36Вт Светильник (аналог ЛСП 2х36 широкий) IP65   (12шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 7119,
        description: 'Светильник светодиодный 595х595 4000К',
        price: 526.08,
        measure: 'шт',
        spec: true,
        quantity: 1250,
        sort: 'LED 36Вт Светильник 595х595 4000К  (2шт./упак./4шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 23128,
        description: 'Светильник светодиодный 595х595 6500К',
        price: 526.08,
        measure: 'шт',
        spec: true,
        quantity: 736,
        sort: 'LED 36Вт Светильник 595х595 6500К   (2шт.уп./4шт.упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Элементы питания и аккумуляторы',
        code: 6996,
        description: 'Элемент питания LR03 ААА',
        price: 12.54,
        measure: 'шт',
        spec: false,
        quantity: 228,
        sort: 'LR03 Элемент питания ААА (блист.4шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Элементы питания и аккумуляторы',
        code: 6995,
        description: 'Элемент питания LR6 АА',
        price: 15.6,
        measure: 'шт',
        spec: false,
        quantity: 545,
        sort: 'LR6 Элемент питания АА (блист.4шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Элементы питания и аккумуляторы',
        code: 4070,
        description: 'Элемент питания Duracell MN 1500 (блок 18 шт)',
        price: 48.6,
        measure: 'шт',
        spec: false,
        quantity: 266,
        sort: 'MN 1500 Элемент питания (АА) Duracell (бл.18 шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Элементы питания и аккумуляторы',
        code: 4071,
        description: 'Элемент питания Duracell MN 2400 (блок 18 шт)',
        price: 48.6,
        measure: 'шт',
        spec: false,
        quantity: 223,
        sort: 'MN 2400 Элемент питания (ААА) Duracell (бл.18 шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабель NYM',
        code: 1005,
        description: 'Кабель NYM 3х1,5 ',
        price: 32.76,
        measure: 'м',
        spec: false,
        quantity: 1418,
        sort: 'NYM 3х  1,5 Кабель (100м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабель NYM',
        code: 626,
        description: 'Кабель NYM 3х2,5',
        price: 50.1,
        measure: 'м',
        spec: false,
        quantity: 606,
        sort: 'NYM 3х  2,5 Кабель (100м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабель NYM',
        code: 1384,
        description: 'Кабель NYM 3х4',
        price: 76.02,
        measure: 'м',
        spec: false,
        quantity: 100,
        sort: 'NYM 3х  4 Кабель',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Компактные люминесцентные лампы',
        code: 11245,
        description: 'Лампа люминесцентная компактная PL-11 11W G23 4000К',
        price: 57.84,
        measure: 'шт',
        spec: false,
        quantity: 642,
        sort: 'PL-11 11W G23 4000К 50шт',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Компактные люминесцентные лампы',
        code: 11422,
        description: 'Лампа люминесцентная компактная PL-11 11W G23 6500К',
        price: 54,
        measure: 'шт',
        spec: true,
        quantity: 84935,
        sort: 'PL-11 11W G23 6500К   (200шт./кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Компактные люминесцентные лампы',
        code: 11423,
        description: 'Лампа люминесцентная компактная PL-9 9W G23 6500К',
        price: 47.64,
        measure: 'шт',
        spec: true,
        quantity: 17880,
        sort: 'PL-9 9W G23 6500К  (200шт./кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Элементы питания и аккумуляторы',
        code: 622,
        description: 'Элемент питания R20',
        price: 69.9,
        measure: 'шт',
        spec: false,
        quantity: 25,
        sort: 'R20 BL-2 Элемент питания (2шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы накаливания',
        code: 9006,
        description: 'Лампа накаливания зеркальная R50 40W E14',
        price: 28.2,
        measure: 'шт',
        spec: false,
        quantity: 224,
        sort: 'R50 40W E14 Лампа зеркальная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Зеркальные'
    },
    {
        folder: 'Лампы накаливания',
        code: 8132,
        description: 'Лампа накаливания зеркальная R50 60W E14',
        price: 28.2,
        measure: 'шт',
        spec: false,
        quantity: 317,
        sort: 'R50 60W E14 Лампа зеркальная   (50шт./кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Зеркальные'
    },
    {
        folder: 'Лампы накаливания',
        code: 3038,
        description: 'Лампа накаливания зеркальная R63 40W E27',
        price: 30.6,
        measure: 'шт',
        spec: false,
        quantity: 120,
        sort: 'R63 40W E27 Лампа зеркальная  (25шт./кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Зеркальные'
    },
    {
        folder: 'Лампы накаливания',
        code: 5822,
        description: 'Лампа накаливания зеркальная R63 60W E27',
        price: 30.6,
        measure: 'шт',
        spec: false,
        quantity: 144,
        sort: 'R63 60W E27 Лампа зеркальная   (50шт./кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Зеркальные'
    },
    {
        folder: 'Пускорегулирующая аппаратура для люминесцентных ламп',
        code: 126,
        description: 'Стартер Philips S10 4-65W 220-240V',
        price: 21.3,
        measure: 'шт',
        spec: false,
        quantity: 714,
        sort: 'S10 Стартер 4-65W 220-240V Philips 25 шт.уп.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Пускорегулирующая аппаратура для люминесцентных ламп',
        code: 127,
        description: 'Стартер Philips S2 4-22W 127-240V',
        price: 21.3,
        measure: 'шт',
        spec: false,
        quantity: 1379,
        sort: 'S2 Стартер 4-22W 127-240V Philips 25 шт.уп.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 537,
        description: '2CDS251001R0064 ABB Автоматический выключатель 1п S201 C6 6А 6кА',
        price: 416.34,
        measure: 'шт',
        spec: false,
        quantity: 6,
        sort: 'S201   C6 Автоматический выключатель ABB 1п 6A 6кА  (10шт./упак.)',
        '000000024': '6кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '1',
        '000000037': 'S201',
        '000000038': '6А',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 532,
        description: '2CDS251001R0104 ABB Автоматический выключатель 1п S201 C10 10А 6кА',
        price: 331.86,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'S201  C10 Автоматический выключатель ABB 1п 10А 6кА   (10шт./упак.)',
        '000000024': '6кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 533,
        description: '2CDS251001R0164 ABB Автоматический выключатель 1п S201 C16 16А 6кА',
        price: 302.94,
        measure: 'шт',
        spec: false,
        quantity: 154,
        sort: 'S201  C16 Автоматический выключатель ABB 1п 16А 6кА  (10шт./упак.)',
        '000000024': '6кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 534,
        description: '2CDS251001R0204 ABB Автоматический выключатель 1п S201 C20 20А 6кА',
        price: 375.12,
        measure: 'шт',
        spec: false,
        quantity: 17,
        sort: 'S201  C20 Автоматический выключатель ABB 1п 20А 6кА',
        '000000024': '6кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 535,
        description: '2CDS251001R0254 ABB Автоматический выключатель 1п S201 C25 25А 6кА',
        price: 362.76,
        measure: 'шт',
        spec: false,
        quantity: 104,
        sort: 'S201  C25 Автоматический выключатель ABB 1п 25А 6кА   (10шт./упак.)',
        '000000024': '6кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 536,
        description: '2CDS251001R0324 ABB Автоматический выключатель 1п S201 C32 32А 6кА',
        price: 418.38,
        measure: 'шт',
        spec: false,
        quantity: 20,
        sort: 'S201  C32 Автоматический выключатель ABB 1п 32А 6кА   (10шт./упак.)',
        '000000024': '6кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 1649,
        description: '2CDS251001R0404 ABB Автоматический выключатель 1п S201 C40 40А 6кА',
        price: 452.4,
        measure: 'шт',
        spec: false,
        quantity: 13,
        sort: 'S201  C40 Автоматический выключатель ABB 1п 40А 6кА',
        '000000024': '6кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 2826,
        description: '2CDS251001R0504 ABB Автоматический выключатель 1п S201 C50 50А 6кА',
        price: 690.42,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'S201  C50 Автоматический выключатель ABB 1п 50А 6кА',
        '000000024': '6кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 1650,
        description: '2CDS251001R0634 ABB Автоматический выключатель 1п S201 C63 63А 6кА',
        price: 690.42,
        measure: 'шт',
        spec: false,
        quantity: 3,
        sort: 'S201  C63 Автоматический выключатель ABB 1п 63А 6кА',
        '000000024': '6кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 539,
        description: '2CDS253001R0104 ABB Автоматический выключатель 3п S203 C10 10А 6кА',
        price: 1205.7,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'S203 C10 Автоматический выключатель ABB 3п 10А 6кА',
        '000000024': '6кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 540,
        description: '2CDS253001R0164 ABB Автоматический выключатель 3п S203 C16 16А 6кА',
        price: 1123.26,
        measure: 'шт',
        spec: false,
        quantity: 4,
        sort: 'S203 C16 Автоматический выключатель ABB 3п 16А 6кА',
        '000000024': '6кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 541,
        description: '2CDS253001R0254 ABB Автоматический выключатель 3п S203 C25 25А 6кА',
        price: 1360.26,
        measure: 'шт',
        spec: false,
        quantity: 3,
        sort: 'S203 C25 Автоматический выключатель ABB 3п 25А 6кА',
        '000000024': '6кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 542,
        description: '2CDS253001R0324 ABB Автоматический выключатель 3п S203 C32 32А 6кА',
        price: 1483.92,
        measure: 'шт',
        spec: false,
        quantity: 21,
        sort: 'S203 C32 Автоматический выключатель ABB 3п 32А 6кА',
        '000000024': '6кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 543,
        description: '2CDS253001R0404 ABB Автоматический выключатель 3п S203 C40 40А 6кА',
        price: 1607.58,
        measure: 'шт',
        spec: false,
        quantity: 4,
        sort: 'S203 C40 Автоматический выключатель ABB 3п 40А 6кА',
        '000000024': '6кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 1113,
        description: '2CDS253001R0504 ABB Автоматический выключатель 3п S203 C50 50А 6кА',
        price: 2081.58,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'S203 C50 Автоматический выключатель ABB 3п 50А 6кА',
        '000000024': '6кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 545,
        description: '2CDS253001R0634 ABB Автоматический выключатель 3п S203 C63 63А 6кА',
        price: 2174.34,
        measure: 'шт',
        spec: false,
        quantity: 8,
        sort: 'S203 C63 Автоматический выключатель ABB 3п 63А 6кА',
        '000000024': '6кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 1529,
        description: '2CDS241001R0104 ABB Автоматический выключатель 1п SH201L C10 10А 4,5кА',
        price: 181.68,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'SH201L C10 Автоматический выключатель ABB 1п 10А 4,5кА   (10шт./упак.)',
        '000000024': '4,5кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 129,
        description: '2CDS241001R0164 ABB Автоматический выключатель 1п SH201L C16 16А 4,5кА ',
        price: 181.68,
        measure: 'шт',
        spec: false,
        quantity: 44,
        sort: 'SH201L C16 Автоматический выключатель ABB 1п 16А 4,5кА   (10шт./упак.)',
        '000000024': '4,5кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 1525,
        description: '2CDS241001R0254 ABB Автоматический выключатель 1п SH201L C25 25А 4,5кА',
        price: 181.68,
        measure: 'шт',
        spec: false,
        quantity: 13,
        sort: 'SH201L C25 Автоматический выключатель ABB 1п 25А 4,5кА   (10шт./упак.)',
        '000000024': '4,5кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели ABB на DIN-рейку',
        code: 132,
        description: '2CDS243001R0254 ABB Автоматический выключатель 3п SH203L C25 25А 4,5кА',
        price: 622.62,
        measure: 'шт',
        spec: false,
        quantity: 23,
        sort: 'SH203L C25 Автоматический выключатель ABB 3п 25А 4,5кА',
        '000000024': '4,5кА',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 11379,
        description: 'Изолятор SM25K с крепежом',
        price: 25.2,
        measure: 'шт',
        spec: false,
        quantity: 31,
        sort: 'SM25K Изолятор с крепежом',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 11381,
        description: 'Изолятор SM35K с крепежом',
        price: 40.68,
        measure: 'шт',
        spec: false,
        quantity: 10,
        sort: 'SM35K Изолятор с крепежом',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 11378,
        description: 'Изолятор SM40K с крепежом',
        price: 43.38,
        measure: 'шт',
        spec: false,
        quantity: 20,
        sort: 'SM40K Изолятор с крепежом',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Компактные люминесцентные лампы',
        code: 728,
        description: 'Лампа люминесцентная компактная SPC 11W E14 2700K',
        price: 72.24,
        measure: 'шт',
        spec: false,
        quantity: 127,
        sort: 'SPC 11W E14 2700K  (100шт./кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Компактные люминесцентные лампы',
        code: 10355,
        description: 'Лампа люминесцентная компактная SPC 11W E14 4200K',
        price: 72.24,
        measure: 'шт',
        spec: false,
        quantity: 252,
        sort: 'SPC 11W E14 4200K  (100шт./кор)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Компактные люминесцентные лампы',
        code: 2018,
        description: 'Лампа люминесцентная компактная SPC 11W E27 2700K',
        price: 72.24,
        measure: 'шт',
        spec: true,
        quantity: 4001,
        sort: 'SPC 11W E27 2700K  (50шт./кор) важно',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Компактные люминесцентные лампы',
        code: 12085,
        description: 'Лампа люминесцентная компактная SPC 11W E27 4200K',
        price: 72.24,
        measure: 'шт',
        spec: false,
        quantity: 9001,
        sort: 'SPC 11W E27 4200K   (50шт./кор.) важно',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Компактные люминесцентные лампы',
        code: 1272,
        description: 'Лампа люминесцентная компактная SPC 15W Е27 2700К',
        price: 78,
        measure: 'шт',
        spec: true,
        quantity: 10827,
        sort: 'SPC 15W Е27 2700К   (50шт.кор) важно',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Компактные люминесцентные лампы',
        code: 9549,
        description: 'Лампа люминесцентная компактная SPC 15W Е27 4200К ',
        price: 78,
        measure: 'шт',
        spec: false,
        quantity: 2120,
        sort: 'SPC 15W Е27 4200К (50шт./кор.) важно',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Компактные люминесцентные лампы',
        code: 3840,
        description: 'Лампа люминесцентная компактная SPC 20W E27 2700K',
        price: 83.16,
        measure: 'шт',
        spec: true,
        quantity: 6967,
        sort: 'SPC 20W E27 2700K   (50шт./кор.) важно',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Пускорегулирующая аппаратура для люминесцентных ламп',
        code: 699,
        description: '4008321364876 Стартер OSRAM ST-111 4-65W',
        price: 17.1,
        measure: 'шт',
        spec: false,
        quantity: 935,
        sort: 'ST-111 Стартер 4-65W 220V OSRAM   (25шт.уп)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Пускорегулирующая аппаратура для люминесцентных ламп',
        code: 700,
        description: '4008321364920 Стартер OSRAM ST-151 4-22W',
        price: 17.94,
        measure: 'шт',
        spec: false,
        quantity: 959,
        sort: 'ST-151 Стартер 4-22W 127V OSRAM   (25шт.уп)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 27008,
        description: 'Светильник IP65 120х2 под LED лампу T8 G13 ',
        price: 434.34,
        measure: 'шт',
        spec: true,
        quantity: 116,
        sort: 'SWL-V3-2T8-120-230-АС Светильник IP65 под светодиодную лампу Sweko ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Линейные люминесцентные лампы',
        code: 6184,
        description: 'Лампа люминесцентная Philips TL-D 18W/33-640 G13 ',
        price: 47.22,
        measure: 'шт',
        spec: false,
        quantity: 1116,
        sort: 'TL-D 18W/33-640 G13 Philips (25шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Линейные люминесцентные лампы',
        code: 702,
        description: 'Лампа люминесцентная Philips TL-D 18W/54-765 G13 ',
        price: 47.22,
        measure: 'шт',
        spec: false,
        quantity: 604,
        sort: 'TL-D 18W/54-765 G13 Philips (25шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Линейные люминесцентные лампы',
        code: 6183,
        description: 'Лампа люминесцентная Philips TL-D 36W/33-640 G13',
        price: 59.28,
        measure: 'шт',
        spec: false,
        quantity: 469,
        sort: 'TL-D 36W/33-640 G13 Philips (25шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Линейные люминесцентные лампы',
        code: 703,
        description: 'Лампа люминесцентная Philips TL-D 36W/54-765 G13 ',
        price: 59.28,
        measure: 'шт',
        spec: false,
        quantity: 503,
        sort: 'TL-D 36W/54-765 G13 Philips (25шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода и кабели связи',
        code: 974,
        description: 'Кабель информационный UTP 4х2х0,52 Категория 5',
        price: 8.1,
        measure: 'м',
        spec: false,
        quantity: 2195,
        sort: 'UTP 4х  2х0,52 кат.5   (305м./бухта)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Расходные материалы',
        code: 3761,
        description: 'Универсальная смазка WD-40, 200 мл',
        price: 250,
        measure: 'шт',
        spec: false,
        quantity: 17,
        sort: 'WD-40, 200 мл Универсальная смазка',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода и кабели с алюминиевой жилой',
        code: 2219,
        description: 'Кабель АВВГ 2х2,5',
        price: 8.46,
        measure: 'м',
        spec: false,
        quantity: 3655,
        sort: 'АВВГ 2х  2,5 Кабель   (200м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели дифференциального тока (дифференциальные автоматы) 2п',
        code: 1433,
        description: 'Автоматический выключатель дифференциального тока АВДТ-32 2P C 16А/30мА',
        price: 595.32,
        measure: 'шт',
        spec: true,
        quantity: 184,
        sort: 'АВДТ-32 2P 16А 30мА диф.авт.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели дифференциального тока (дифференциальные автоматы) 2п',
        code: 592,
        description: 'Автоматический выключатель дифференциального тока АВДТ-32 2P C 25А/30мА',
        price: 595.32,
        measure: 'шт',
        spec: true,
        quantity: 332,
        sort: 'АВДТ-32 2P 25А/30мА диф.авт.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели дифференциального тока (дифференциальные автоматы) 2п',
        code: 2846,
        description: 'Автоматический выключатель дифференциального тока АВДТ-32 2P C 32А/30мА',
        price: 595.32,
        measure: 'шт',
        spec: false,
        quantity: 64,
        sort: 'АВДТ-32 2P 32А/30мА диф.авт.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на монтажную панель',
        code: 737,
        description: 'Автоматический выключатель АЕ2056М-100 80А',
        price: 1878.84,
        measure: 'шт',
        spec: false,
        quantity: 35,
        sort: 'АЕ2056М-100  80А Автоматический выключатель',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на монтажную панель',
        code: 736,
        description: 'Автоматический выключатель АЕ2056М-100 100А',
        price: 1878.84,
        measure: 'шт',
        spec: false,
        quantity: 66,
        sort: 'АЕ2056М-100 100А Автоматический выключатель',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на монтажную панель',
        code: 3995,
        description: 'Автоматический выключатель АЕ2066-100 160А',
        price: 4060.86,
        measure: 'шт',
        spec: false,
        quantity: 3,
        sort: 'АЕ2066-100 160А Автоматический выключатель',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода и кабели с алюминиевой жилой',
        code: 2267,
        description: 'Провод АПВ 2,5 белый',
        price: 3.06,
        measure: 'м',
        spec: false,
        quantity: 3600,
        sort: 'АПВ   2,5 Белый   (500м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода и кабели с алюминиевой жилой',
        code: 2848,
        description: 'Провод АПВ 4 белый',
        price: 4.5,
        measure: 'м',
        spec: false,
        quantity: 865,
        sort: 'АПВ   4 Белый  (400м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода и кабели с алюминиевой жилой',
        code: 1938,
        description: 'Провод АПВ 6 белый',
        price: 6.18,
        measure: 'м',
        spec: false,
        quantity: 1180,
        sort: 'АПВ   6 Белый  (300м.) ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода и кабели с алюминиевой жилой',
        code: 1022,
        description: 'Провод АПВ 10 белый',
        price: 10.2,
        measure: 'м',
        spec: false,
        quantity: 400,
        sort: 'АПВ  10 Белый    (200м./300м. бухта) ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода и кабели с алюминиевой жилой',
        code: 1182,
        description: 'Провод АППВ 2х2,5',
        price: 6.96,
        measure: 'м',
        spec: false,
        quantity: 4235,
        sort: 'АППВ 2х  2,5     (200м. бухта)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Инструмент ручной',
        code: 6758,
        description: 'Бокорезы диэлектрические 160мм',
        price: 312,
        measure: 'шт',
        spec: false,
        quantity: 11,
        sort: 'Бокорезы 160мм диэлектрические',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Средства защиты',
        code: 3342,
        description: 'Боты диэлектрические  ',
        price: 722.16,
        measure: 'пар',
        spec: false,
        quantity: 28,
        sort: 'Боты диэлектрические (4 пары/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 20755,
        description: 'Автоматический выключатель ВА 4729 1P 10А (С) 6кА',
        price: 87.12,
        measure: 'шт',
        spec: false,
        quantity: 24,
        sort: 'ВА 4729 1P (ВА 47-60 1Р) 10А (С) 6кА',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-29 6кА',
        '000000041': '10А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 20756,
        description: 'Автоматический выключатель ВА 4729 1P 16А (С) 6кА',
        price: 78.54,
        measure: 'шт',
        spec: false,
        quantity: 228,
        sort: 'ВА 4729 1P (ВА 47-60 1Р) 16А (С) 6кА',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-29 6кА',
        '000000041': '16А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 21182,
        description: 'Автоматический выключатель ВА 4729 1P 25А (С) 6кА',
        price: 83.76,
        measure: 'шт',
        spec: false,
        quantity: 252,
        sort: 'ВА 4729 1P (ВА 47-60 1Р) 25А (С) 6кА',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-29 6кА',
        '000000041': '25А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 19957,
        description: 'Автоматический выключатель ВА 4729 1P 32А (C) 6кА',
        price: 83.76,
        measure: 'шт',
        spec: false,
        quantity: 46,
        sort: 'ВА 4729 1P (ВА 47-60 1Р) 32А (С) 6кА',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-29 6кА',
        '000000041': '32А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 21415,
        description: 'Автоматический выключатель ВА 4729 1P 40А (С) 6кА',
        price: 86.58,
        measure: 'шт',
        spec: false,
        quantity: 140,
        sort: 'ВА 4729 1P (ВА 47-60 1Р) 40А (С) 6кА',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-29 6кА',
        '000000041': '40А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 24180,
        description: 'Автоматический выключатель ВА 4729 2P 25А (С) 6кА',
        price: 164.76,
        measure: 'шт',
        spec: false,
        quantity: 4,
        sort: 'ВА 4729 2Р (ВА 47-60 2Р) 25А (С) 6кА',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '2',
        '000000040': 'ВА 47-29 6кА',
        '000000041': '25А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 21414,
        description: 'Автоматический выключатель ВА 4729 2Р 32А 6кА',
        price: 164.76,
        measure: 'шт',
        spec: false,
        quantity: 23,
        sort: 'ВА 4729 2Р (ВА 47-60 2Р) 32А (С) 6кА',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '2',
        '000000040': 'ВА 47-29 6кА',
        '000000041': '32А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 21416,
        description: 'Автоматический выключатель ВА 4729 2P 40А (С) 6кА',
        price: 164.76,
        measure: 'шт',
        spec: false,
        quantity: 62,
        sort: 'ВА 4729 2Р (ВА 47-60 2Р) 40А (С) 6кА',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '2',
        '000000040': 'ВА 47-29 6кА',
        '000000041': '40А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 20758,
        description: 'Автоматический выключатель ВА 4729 3P 16А (С) 6кА',
        price: 256.62,
        measure: 'шт',
        spec: false,
        quantity: 22,
        sort: 'ВА 4729 3P (ВА 47-60 3Р) 16А (С) 6кА',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-29 6кА',
        '000000041': '16А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 21909,
        description: 'Автоматический выключатель ВА 4729 3P 25А (С) 6кА',
        price: 256.62,
        measure: 'шт',
        spec: false,
        quantity: 52,
        sort: 'ВА 4729 3P (ВА 47-60 3Р) 25А (С) 6кА',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-29 6кА',
        '000000041': '25А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 19956,
        description: 'Автоматический выключатель ВА 4729 3P 32А (С) 6кА',
        price: 256.62,
        measure: 'шт',
        spec: false,
        quantity: 13,
        sort: 'ВА 4729 3P (ВА 47-60 3Р) 32А (С) 6кА',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-29 6кА',
        '000000041': '32А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 20759,
        description: 'Автоматический выключатель ВА 4729 3P 40А (С) 6кА',
        price: 256.62,
        measure: 'шт',
        spec: false,
        quantity: 13,
        sort: 'ВА 4729 3P (ВА 47-60 3Р) 40А (С) 6кА',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-29 6кА',
        '000000041': '40А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 20111,
        description: 'Автоматический выключатель ВА 4729 3P 50А (С) 6кА',
        price: 348.9,
        measure: 'шт',
        spec: false,
        quantity: 40,
        sort: 'ВА 4729 3P (ВА 47-60 3Р) 50А (С) 6кА',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-29 6кА',
        '000000041': '50А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 22625,
        description: 'Автоматический выключатель ВА 4729 3P 63А (C) 6кА',
        price: 279,
        measure: 'шт',
        spec: false,
        quantity: 33,
        sort: 'ВА 4729 3P (ВА 47-60 3Р) 63А (C) 6кА',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-29 6кА',
        '000000041': '63А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 14569,
        description: 'Автоматический выключатель модульный ВА47-100 1п C 10А 10кА',
        price: 393.3,
        measure: 'шт',
        spec: false,
        quantity: 42,
        sort: 'ВА47-100 1п  C  10А 10кА Автоматический выключатель',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-100 10кА',
        '000000041': '10А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 13296,
        description: 'Автоматический выключатель модульный ВА47-100 1п C 16А 10кА',
        price: 393.3,
        measure: 'шт',
        spec: false,
        quantity: 4,
        sort: 'ВА47-100 1п  C  16А 10кА Автоматический выключатель   (12шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-100 10кА',
        '000000041': '16А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 13297,
        description: 'Автоматический выключатель модульный ВА47-100 1п C 25А 10кА',
        price: 393.3,
        measure: 'шт',
        spec: false,
        quantity: 6,
        sort: 'ВА47-100 1п  C  25А 10кА Автоматический выключатель   (12шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-100 10кА',
        '000000041': '25А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 1565,
        description: 'Автоматический выключатель модульный ВА47-100 1п C 100А 10кА',
        price: 396,
        measure: 'шт',
        spec: false,
        quantity: 92,
        sort: 'ВА47-100 1п  C 100А 10кА Автоматический выключатель',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-100 10кА',
        '000000041': '100А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 13295,
        description: 'Автоматический выключатель модульный ВА47-100 3п C 25А 10кА',
        price: 1158.18,
        measure: 'шт',
        spec: false,
        quantity: 3,
        sort: 'ВА47-100 3п  C  25А 10кА Автоматический выключатель  (4шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-100 10кА',
        '000000041': '25А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 5196,
        description: 'Автоматический выключатель модульный ВА47-100 3п C 32А 10кА ',
        price: 1158.18,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'ВА47-100 3п  C  32А 10кА Автоматический выключатель   (4шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-100 10кА',
        '000000041': '32А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 5197,
        description: 'Автоматический выключатель модульный ВА47-100 3п C 40А 10кА',
        price: 1158.18,
        measure: 'шт',
        spec: false,
        quantity: 4,
        sort: 'ВА47-100 3п  C  40А 10кА Автоматический выключатель   (4шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-100 10кА',
        '000000041': '40А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 16875,
        description: 'Автоматический выключатель модульный ВА47-100 3п C 50А 10кА',
        price: 1158.18,
        measure: 'шт',
        spec: false,
        quantity: 13,
        sort: 'ВА47-100 3п  C  50А 10кА Автоматический выключатель  (4шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-100 10кА',
        '000000041': '50А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 1584,
        description: 'Автоматический выключатель модульный ВА47-100 3п C 63А 10кА',
        price: 1102.8,
        measure: 'шт',
        spec: false,
        quantity: 8,
        sort: 'ВА47-100 3п  C  63А 10кА Автоматический выключатель (4 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-100 10кА',
        '000000041': '63А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 1585,
        description: 'Автоматический выключатель модульный ВА47-100 3п С 80А 10кА',
        price: 1063.08,
        measure: 'шт',
        spec: true,
        quantity: 41,
        sort: 'ВА47-100 3п  C  80А 10кА Автоматический выключатель (4 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-100 10кА',
        '000000041': '80А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 1916,
        description: 'Автоматический выключатель модульный ВА47-100 3п C 100А 10кА',
        price: 1035.06,
        measure: 'шт',
        spec: true,
        quantity: 53,
        sort: 'ВА47-100 3п  C 100А 10кА Автоматический выключатель (4 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-100 10кА',
        '000000041': '100А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 1898,
        description: 'Автоматический выключатель ВА47-63 1п C 6А 4,5кА',
        price: 64.5,
        measure: 'шт',
        spec: false,
        quantity: 103,
        sort: 'ВА47-63 1п C  6А 4,5кА (12 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '6А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 1461,
        description: 'Автоматический выключатель ВА47-63 1п C 10А 4,5кА',
        price: 64.5,
        measure: 'шт',
        spec: false,
        quantity: 245,
        sort: 'ВА47-63 1п C 10А 4,5кА (12 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '10А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 583,
        description: 'Автоматический выключатель ВА47-63 1п C 16А 4,5кА',
        price: 60.48,
        measure: 'шт',
        spec: false,
        quantity: 2065,
        sort: 'ВА47-63 1п C 16А 4,5кА (12 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '16А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 9994,
        description: 'Автоматический выключатель ВА47-63 1п C 20А 4,5кА',
        price: 64.5,
        measure: 'шт',
        spec: false,
        quantity: 112,
        sort: 'ВА47-63 1п C 20А 4,5кА (12 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '20А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 584,
        description: 'Автоматический выключатель ВА47-63 1п C 25А 4,5кА',
        price: 61.08,
        measure: 'шт',
        spec: false,
        quantity: 1043,
        sort: 'ВА47-63 1п C 25А 4,5кА (12 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '25А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 1462,
        description: 'Автоматический выключатель ВА47-63 1п C 32А 4,5кА',
        price: 64.5,
        measure: 'шт',
        spec: false,
        quantity: 464,
        sort: 'ВА47-63 1п C 32А 4,5кА (!!! продаём 47-29) (12 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '32А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 1044,
        description: 'Автоматический выключатель ВА47-63 1п C 40А 4,5кА',
        price: 65.7,
        measure: 'шт',
        spec: false,
        quantity: 313,
        sort: 'ВА47-63 1п C 40А 4,5кА (!!! продаём 47-29) (12 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '40А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 9995,
        description: 'Автоматический выключатель ВА47-63 1п C 50А 4,5кА',
        price: 80.88,
        measure: 'шт',
        spec: false,
        quantity: 540,
        sort: 'ВА47-63 1п C 50А 4,5кА (12 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '50А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 13731,
        description: 'Автоматический выключатель ВА47-63 1п C 63А 4,5кА ',
        price: 80.88,
        measure: 'шт',
        spec: false,
        quantity: 107,
        sort: 'ВА47-63 1п C 63А 4,5кА (12 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '1',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '63А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 9996,
        description: 'Автоматический выключатель ВА47-63 2п C 10А 4,5кА',
        price: 135,
        measure: 'шт',
        spec: false,
        quantity: 9,
        sort: 'ВА47-63 2п C 10А 4,5кА (6 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '2',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '10А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 1045,
        description: 'Автоматический выключатель ВА47-63 2п C 16А 4,5кА',
        price: 129,
        measure: 'шт',
        spec: false,
        quantity: 1479,
        sort: 'ВА47-63 2п C 16А 4,5кА (6 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '2',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '16А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 1046,
        description: 'Автоматический выключатель ВА47-63 2п C 25А 4,5кА',
        price: 129,
        measure: 'шт',
        spec: false,
        quantity: 675,
        sort: 'ВА47-63 2п C 25А 4,5кА (6 шт.) (!!! продаём 14810)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '2',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '25А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 4061,
        description: 'Автоматический выключатель ВА47-63 2п C 32А 4,5кА',
        price: 129,
        measure: 'шт',
        spec: false,
        quantity: 242,
        sort: 'ВА47-63 2п C 32А 4,5кА (!!! продаём 47-29) (6 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '2',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '32А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 585,
        description: 'Автоматический выключатель ВА47-63 2п C 40А 4,5кА',
        price: 134.82,
        measure: 'шт',
        spec: false,
        quantity: 265,
        sort: 'ВА47-63 2п C 40А 4,5кА (6 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '2',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '40А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 9605,
        description: 'Автоматический выключатель ВА47-63 2п C 50А 4,5кА',
        price: 171.06,
        measure: 'шт',
        spec: false,
        quantity: 52,
        sort: 'ВА47-63 2п C 50А 4,5кА (6 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '2',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '50А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 9556,
        description: 'Автоматический выключатель ВА47-63 2п C 63А 4,5кА',
        price: 171.06,
        measure: 'шт',
        spec: false,
        quantity: 56,
        sort: 'ВА47-63 2п C 63А 4,5кА (6шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '2',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '63А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 9997,
        description: 'Автоматический выключатель ВА47-63 3п C 6А 4,5кА',
        price: 209.46,
        measure: 'шт',
        spec: false,
        quantity: 2,
        sort: 'ВА47-63 3п C  6А 4,5кА (4 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '6А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 14382,
        description: 'Автоматический выключатель ВА47-63 3п C 10А 4,5кА',
        price: 200.7,
        measure: 'шт',
        spec: false,
        quantity: 12,
        sort: 'ВА47-63 3п C 10А 4,5кА (4шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '10А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 9998,
        description: 'Автоматический выключатель ВА47-63 3п C 16А 4,5кА',
        price: 200.7,
        measure: 'шт',
        spec: false,
        quantity: 663,
        sort: 'ВА47-63 3п C 16А 4,5кА (4шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '16А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 9999,
        description: 'Автоматический выключатель ВА47-63 3п C 20А 4,5кА',
        price: 200.7,
        measure: 'шт',
        spec: false,
        quantity: 81,
        sort: 'ВА47-63 3п C 20А 4,5кА (4шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '20А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 5889,
        description: 'Автоматический выключатель ВА47-63 3п C 25А 4,5кА',
        price: 200.7,
        measure: 'шт',
        spec: false,
        quantity: 159,
        sort: 'ВА47-63 3п C 25А 4,5кА  (4шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '25А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 10000,
        description: 'Автоматический выключатель ВА47-63 3п C 32А 4,5кА',
        price: 200.7,
        measure: 'шт',
        spec: false,
        quantity: 242,
        sort: 'ВА47-63 3п C 32А 4,5кА  (4шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '32А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 10001,
        description: 'Автоматический выключатель ВА47-63 3п C 40А 4,5кА',
        price: 210.9,
        measure: 'шт',
        spec: false,
        quantity: 119,
        sort: 'ВА47-63 3п C 40А 4,5кА (4шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '40А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 11698,
        description: 'Автоматический выключатель ВА47-63 3п C 50А 4,5кА',
        price: 250.2,
        measure: 'шт',
        spec: false,
        quantity: 35,
        sort: 'ВА47-63 3п C 50А 4,5кА  (4шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '50А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на DIN-рейку',
        code: 10002,
        description: 'Автоматический выключатель ВА47-63 3п C 63А 4,5кА',
        price: 250.2,
        measure: 'шт',
        spec: false,
        quantity: 21,
        sort: 'ВА47-63 3п C 63А 4,5кА  (4шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '3',
        '000000040': 'ВА 47-63 4,5кА',
        '000000041': '63А',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на монтажную панель',
        code: 24265,
        description: 'Автоматический выключатель ВА87-33 3п 80А 18кА',
        price: 1683.48,
        measure: 'шт',
        spec: false,
        quantity: 8,
        sort: 'ВА87-33 3п  80А 18кА Автоматический выключатель',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на монтажную панель',
        code: 24562,
        description: 'Автоматический выключатель ВА87-33 3п 100А 18кА',
        price: 1667.46,
        measure: 'шт',
        spec: false,
        quantity: 13,
        sort: 'ВА87-33 3п 100А 18кА Автоматический выключатель',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на монтажную панель',
        code: 25650,
        description: 'Автоматический выключатель ВА87-33 3п 125А 18кА',
        price: 1683.48,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'ВА87-33 3п 125А 18кА Автоматический выключатель',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на монтажную панель',
        code: 25061,
        description: 'Автоматический выключатель ВА87-33 3п 160А 18кА',
        price: 1750.44,
        measure: 'шт',
        spec: false,
        quantity: 8,
        sort: 'ВА87-33 3п 160А 18кА Автоматический выключатель',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на монтажную панель',
        code: 24986,
        description: 'Автоматический выключатель ВА87-35 3п 200А 25кА',
        price: 2620.86,
        measure: 'шт',
        spec: false,
        quantity: 9,
        sort: 'ВА87-35 3п 200А 25кА Автоматический выключатель',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Автоматические выключатели на монтажную панель',
        code: 24483,
        description: 'Автоматический выключатель ВА87-35 3п 250А 25кА ',
        price: 2620.86,
        measure: 'шт',
        spec: false,
        quantity: 6,
        sort: 'ВА87-35 3п 250А 25кА Автоматический выключатель  (8шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 7165,
        description: 'Кабель ВВГнг 3x6',
        price: 86.16,
        measure: 'м',
        spec: false,
        quantity: 135,
        sort: 'ВВГнг 3x  6 Кабель (когда закончится продаём LS)   (100м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '3',
        '000000029': '6',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 10923,
        description: 'Кабель ВВГнг-FRLS 3х1,5',
        price: 45.48,
        measure: 'м',
        spec: false,
        quantity: 2850,
        sort: 'ВВГнг-FRLS 3х  1,5 Кабель  ГОСТ 100м.бухта',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '3',
        '000000029': '1,5',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 8203,
        description: 'Кабель ВВГнг-FRLS 3х2,5',
        price: 65.64,
        measure: 'м',
        spec: false,
        quantity: 4500,
        sort: 'ВВГнг-FRLS 3х  2,5 Кабель   (100м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '3',
        '000000029': '2,5',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 8322,
        description: 'Кабель ВВГнг-FRLS 5х4',
        price: 160.8,
        measure: 'м',
        spec: false,
        quantity: 570,
        sort: 'ВВГнг-FRLS 5х  4 Кабель',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '5',
        '000000029': '4',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 2854,
        description: 'Кабель ВВГнг-FRLS 5х6 ',
        price: 228.06,
        measure: 'м',
        spec: false,
        quantity: 335,
        sort: 'ВВГнг-FRLS 5х  6 Кабель   (200м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '5',
        '000000029': '6',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 4317,
        description: 'Кабель ВВГнг-LS 2х1,5',
        price: 18.54,
        measure: 'м',
        spec: false,
        quantity: 34,
        sort: 'ВВГнг-LS 2x  1,5 Кабель   (100м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '2',
        '000000029': '1,5',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 8310,
        description: 'Кабель ВВГнг-LS 2х2,5',
        price: 28.98,
        measure: 'м',
        spec: false,
        quantity: 3058,
        sort: 'ВВГнг-LS 2x  2,5 Кабель   (100м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '2',
        '000000029': '2,5',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 641,
        description: 'Кабель ВВГнг-LS 3х1,5',
        price: 31.98,
        measure: 'м',
        spec: true,
        quantity: 6754,
        sort: 'ВВГнг-LS 3х  1,5 ГОСТ Кабель   (бухта 100м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '3',
        '000000029': '1,5',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 15856,
        description: 'Кабель ВВГнг-LS 3х1,5',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 13042,
        sort: 'ВВГнг-LS 3х  1,5 Кабель   (бухта 200м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '3',
        '000000029': '1,5',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 642,
        description: 'Кабель ВВГнг-LS 3х2,5',
        price: 49.56,
        measure: 'м',
        spec: true,
        quantity: 8140,
        sort: 'ВВГнг-LS 3х  2,5 ГОСТ Кабель   (бухта 100м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '3',
        '000000029': '2,5',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 15857,
        description: 'Кабель ВВГнг-LS 3х2,5',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 1804,
        sort: 'ВВГнг-LS 3х  2,5 Кабель   (бухта 200м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '3',
        '000000029': '2,5',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 1004,
        description: 'Кабель ВВГнг-LS 3x4',
        price: 71.64,
        measure: 'м',
        spec: false,
        quantity: 714,
        sort: 'ВВГнг-LS 3х  4 Кабель   100м./бухта',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '3',
        '000000029': '4',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 8897,
        description: 'Кабель ВВГнг-LS 3x6',
        price: 104.94,
        measure: 'м',
        spec: false,
        quantity: 20,
        sort: 'ВВГнг-LS 3х  6 Кабель (!!! продаём -нг без LS)   (100м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '3',
        '000000029': '6',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 8202,
        description: 'Кабель ВВГнг-LS 4х1,5',
        price: 40.32,
        measure: 'м',
        spec: false,
        quantity: 561,
        sort: 'ВВГнг-LS 4х  1,5 Кабель   (100м./бухта)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '4',
        '000000029': '1,5',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 1168,
        description: 'Кабель ВВГнг-LS 4х2,5',
        price: 62.28,
        measure: 'м',
        spec: false,
        quantity: 26,
        sort: 'ВВГнг-LS 4х  2,5 Кабель   (100м./бухта)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '4',
        '000000029': '2,5',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 2121,
        description: 'Кабель ВВГнг-LS 4х4',
        price: 99.54,
        measure: 'м',
        spec: false,
        quantity: 727,
        sort: 'ВВГнг-LS 4х  4 Кабель   (бухта 100м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '4',
        '000000029': '4',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 1864,
        description: 'Кабель ВВГнг-LS 4х6',
        price: 147.6,
        measure: 'м',
        spec: false,
        quantity: 30,
        sort: 'ВВГнг-LS 4х  6 Кабель',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '4',
        '000000029': '6',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 13911,
        description: 'Кабель ВВГнг-LS 4х10',
        price: 244.5,
        measure: 'м',
        spec: false,
        quantity: 385,
        sort: 'ВВГнг-LS 4х 10 Кабель ГОСТ   БАРАБАН',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '4',
        '000000029': '10',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 3841,
        description: 'Кабель ВВГнг-LS 5х1,5',
        price: 51.3,
        measure: 'м',
        spec: false,
        quantity: 234,
        sort: 'ВВГнг-LS 5х  1,5 Кабель   (200м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '5',
        '000000029': '1,5',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 1636,
        description: 'Кабель ВВГнг-LS 5х2,5',
        price: 80.16,
        measure: 'м',
        spec: false,
        quantity: 965,
        sort: 'ВВГнг-LS 5х  2,5 Кабель   (бухта 100м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '5',
        '000000029': '2,5',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 644,
        description: 'Кабель ВВГнг-LS 5х4',
        price: 125.16,
        measure: 'м',
        spec: false,
        quantity: 752,
        sort: 'ВВГнг-LS 5х  4 Кабель ГОСТ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '5',
        '000000029': '4',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 160,
        description: 'Кабель ВВГнг-LS 5х6',
        price: 183.12,
        measure: 'м',
        spec: false,
        quantity: 942,
        sort: 'ВВГнг-LS 5х  6 Кабель ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '5',
        '000000029': '6',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 159,
        description: 'Кабель ВВГнг-LS 5х10',
        price: 305.52,
        measure: 'м',
        spec: false,
        quantity: 19,
        sort: 'ВВГнг-LS 5х 10 Кабель ГОСТ   (100м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '5',
        '000000029': '10',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 643,
        description: 'Кабель ВВГнг-LS 5х16',
        price: 499.62,
        measure: 'м',
        spec: false,
        quantity: 1481,
        sort: 'ВВГнг-LS 5х 16 Кабель ГОСТ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '5',
        '000000029': '16',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабели ВВГнг, ВВГнг-LS и -FRLS',
        code: 161,
        description: 'Кабель ВВГнг-LS 5х25',
        price: 785.7,
        measure: 'м',
        spec: false,
        quantity: 130,
        sort: 'ВВГнг-LS 5х 25 Кабель ГОСТ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '5',
        '000000029': '25',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели дифференциального тока (УЗО) 2п',
        code: 12127,
        description: 'Выключатель дифференциального тока (УЗО) ВД1-63 2р 16А/30мА',
        price: 583.86,
        measure: 'шт',
        spec: false,
        quantity: 177,
        sort: 'ВД1-63 2P  16А/30мА УЗО (под склад берём только ВД63 электронные)  (6шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели дифференциального тока (УЗО) 2п',
        code: 9984,
        description: 'Выключатель дифференциального тока (УЗО) ВД1-63 2р 25А/30мА',
        price: 583.86,
        measure: 'шт',
        spec: true,
        quantity: 1015,
        sort: 'ВД1-63 2P  25А/30мА УЗО (ТДМ) (под склад берём только ВД63 электронные)  (6шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели дифференциального тока (УЗО) 2п',
        code: 8926,
        description: 'Выключатель дифференциального тока (УЗО) ВД1-63 2р 32А/30мА',
        price: 583.86,
        measure: 'шт',
        spec: false,
        quantity: 521,
        sort: 'ВД1-63 2P  32А/30мА УЗО (ТДМ) (под склад берём только ВД63 электронные)   (6шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели дифференциального тока (УЗО) 2п',
        code: 8927,
        description: 'Выключатель дифференциального тока (УЗО) ВД1-63 2р 40А/30мА ',
        price: 583.86,
        measure: 'шт',
        spec: false,
        quantity: 95,
        sort: 'ВД1-63 2P  40А/30мА УЗО (ТДМ) (под склад берём только ВД63 электронные)  (6.шт.уп)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 3083,
        description: 'Вилка без заземления',
        price: 20.52,
        measure: 'шт',
        spec: false,
        quantity: 69,
        sort: 'Вилка б/з  (20шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 9652,
        description: 'Вилка евро боковая',
        price: 41.58,
        measure: 'шт',
        spec: false,
        quantity: 164,
        sort: 'Вилка евро боковая  (50шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 853,
        description: '10027 Вилка MAKEL евро боковая белая',
        price: 41.22,
        measure: 'шт',
        spec: false,
        quantity: 126,
        sort: 'Вилка евро боковая бел. MAKEL   (125шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 17798,
        description: 'Вилка евро боковая с ушком',
        price: 36.36,
        measure: 'шт',
        spec: false,
        quantity: 37,
        sort: 'Вилка евро боковая с ушком   (35шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 17229,
        description: 'Вилка евро боковая с ушком черная',
        price: 36.36,
        measure: 'шт',
        spec: false,
        quantity: 21,
        sort: 'Вилка евро боковая с ушком черная   (35шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Электроустановочные изделия из каучука',
        code: 866,
        description: 'Вилка евро каучуковая',
        price: 60.6,
        measure: 'шт',
        spec: true,
        quantity: 642,
        sort: 'Вилка евро каучук   (24шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 163,
        description: 'Вилка евро прямая',
        price: 35.4,
        measure: 'шт',
        spec: false,
        quantity: 454,
        sort: 'Вилка евро прямая   (25шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 854,
        description: '10002 Вилка MAKEL евро прямая белая',
        price: 46.38,
        measure: 'шт',
        spec: false,
        quantity: 99,
        sort: 'Вилка евро прямая бел. MAKEL  (100шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели нагрузки (рубильники) и переключатели',
        code: 18664,
        description: 'Выключатель нагрузки ВН-32 2P 25A',
        price: 173.58,
        measure: 'шт',
        spec: false,
        quantity: 21,
        sort: 'ВН-32 2P  25A Выключатель нагрузки ТДМ   (6шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели нагрузки (рубильники) и переключатели',
        code: 16774,
        description: 'Рубильник ВР32-35А70220-00 250А УХЛ3',
        price: 1729.32,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'ВР32-35А70220-00 Рубильник 250А УХЛ3',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели нагрузки (рубильники) и переключатели',
        code: 3835,
        description: 'Рубильник ВР32-35 В31250 250А УХЛ3',
        price: 1232.88,
        measure: 'шт',
        spec: false,
        quantity: 3,
        sort: 'ВР32-35В31250 Рубильник 250А УХЛ3',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели нагрузки (рубильники) и переключатели',
        code: 5351,
        description: 'Рубильник ВР32-35 В71250 250А УХЛ3',
        price: 1976.22,
        measure: 'шт',
        spec: false,
        quantity: 9,
        sort: 'ВР32-35В71250 Рубильник 250А УХЛ3',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели нагрузки (рубильники) и переключатели',
        code: 15976,
        description: 'Рубильник ВР32-37А70220-00 УХЛ3 400А',
        price: 2344.44,
        measure: 'шт',
        spec: false,
        quantity: 2,
        sort: 'ВР32-37А70220-00 Рубильник УХЛ3 400А',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели нагрузки (рубильники) и переключатели',
        code: 8520,
        description: 'Рубильник ВР32-37В31250 400А',
        price: 1708.02,
        measure: 'шт',
        spec: false,
        quantity: 7,
        sort: 'ВР32-37В31250 Рубильник 400А',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели нагрузки (рубильники) и переключатели',
        code: 4308,
        description: 'Переключатель ВР32-37В71250 400А',
        price: 2579.76,
        measure: 'шт',
        spec: false,
        quantity: 8,
        sort: 'ВР32-37В71250 Переключатель 400А',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели',
        code: 867,
        description: 'Выключатель 1-клавишный открытой проводки',
        price: 34.32,
        measure: 'шт',
        spec: true,
        quantity: 1462,
        sort: 'Выкл.1ОП     120шт/кор.',
        '000000024': '',
        '000000025': '1',
        '000000026': 'открытая',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели',
        code: 1225,
        description: 'Выключатель 1-клавишный открытой проводки IP44',
        price: 71.4,
        measure: 'шт',
        spec: false,
        quantity: 174,
        sort: 'Выкл.1ОП IP44    50шт/кор.',
        '000000024': '',
        '000000025': '1',
        '000000026': 'открытая',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели',
        code: 5042,
        description: 'Выключатель 1-клавишный открытой проводки IP54',
        price: 117.3,
        measure: 'шт',
        spec: false,
        quantity: 252,
        sort: 'Выкл.1ОП IP54   30шт/кор.',
        '000000024': '',
        '000000025': '1',
        '000000026': 'открытая',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели',
        code: 6956,
        description: 'Выключатель 1-клавишный открытой проводки, проходной (переключатель)',
        price: 46.38,
        measure: 'шт',
        spec: false,
        quantity: 6,
        sort: 'Выкл.1ОП ПРОХОДНОЙ (переключатель)  (10шт./упак.)',
        '000000024': '',
        '000000025': '1',
        '000000026': 'открытая',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели',
        code: 4789,
        description: 'Выключатель 1-клавишный открытой проводки с подсветкой',
        price: 39.6,
        measure: 'шт',
        spec: false,
        quantity: 20,
        sort: 'Выкл.1ОП с подсв.  (10шт./упак.)',
        '000000024': '',
        '000000025': '1',
        '000000026': 'открытая',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели',
        code: 2564,
        description: 'Выключатель 1-клавишный скрытой проводки',
        price: 45.78,
        measure: 'шт',
        spec: false,
        quantity: 471,
        sort: 'Выкл.1СП    70шт/кор.',
        '000000024': '',
        '000000025': '1',
        '000000026': 'скрытая',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели',
        code: 868,
        description: 'Выключатель 2-клавишный открытой проводки',
        price: 35.94,
        measure: 'шт',
        spec: false,
        quantity: 882,
        sort: 'Выкл.2ОП    80шт/кор.',
        '000000024': '',
        '000000025': '2',
        '000000026': 'открытая',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Выключатели',
        code: 2714,
        description: 'Выключатель 2-клавишный скрытой проводки',
        price: 51.36,
        measure: 'шт',
        spec: false,
        quantity: 264,
        sort: 'Выкл.2СП     ',
        '000000024': '',
        '000000025': '2',
        '000000026': 'скрытая',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Гильзы медные луженые',
        code: 20638,
        description: 'Гильза медная ГМЛ 4-3 луженая',
        price: 6.96,
        measure: 'шт',
        spec: false,
        quantity: 98,
        sort: 'ГМЛ   4-3 Гильза медная луженая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Гильзы медные луженые',
        code: 3405,
        description: 'Гильза медная ГМЛ 6-4 луженая',
        price: 8.04,
        measure: 'шт',
        spec: false,
        quantity: 81,
        sort: 'ГМЛ   6-4 Гильза медная луженая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Гильзы медные луженые',
        code: 3404,
        description: 'Гильза медная  ГМЛ 10-5 луженая',
        price: 13.44,
        measure: 'шт',
        spec: false,
        quantity: 194,
        sort: 'ГМЛ  10-5 Гильза медная луженая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Гильзы медные луженые',
        code: 3403,
        description: 'Гильза медная  ГМЛ 16-6 луженая',
        price: 15.6,
        measure: 'шт',
        spec: false,
        quantity: 270,
        sort: 'ГМЛ  16-6 Гильза медная луженая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Гильзы медные луженые',
        code: 3402,
        description: 'Гильза медная  ГМЛ 25-8 луженая',
        price: 25.32,
        measure: 'шт',
        spec: false,
        quantity: 82,
        sort: 'ГМЛ  25-8 Гильза медная луженая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Гильзы медные луженые',
        code: 3401,
        description: 'Гильза медная ГМЛ 35-9 луженая',
        price: 35.04,
        measure: 'шт',
        spec: false,
        quantity: 84,
        sort: 'ГМЛ  35-9 Гильза медная луженая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Гильзы медные луженые',
        code: 7824,
        description: 'Гильза медная ГМЛ 50-11 луженая',
        price: 42.24,
        measure: 'шт',
        spec: false,
        quantity: 95,
        sort: 'ГМЛ  50-11 Гильза медная луженая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Управление освещением',
        code: 12361,
        description: 'Датчик движения ДДП-03 1200Вт, 10-420с, 6м, 3+Лк, 120(сбоку)+360(сверху)гр IP33',
        price: 329.88,
        measure: 'шт',
        spec: false,
        quantity: 21,
        sort: 'ДДП-03 Датчик движения 1200Вт, 10-420с, 6м, 3+Лк, 120(сбоку)+360(сверху)гр IP33',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 25867,
        description: 'Светильник светодиодный уличный ДКУ29-80-501 80 Вт',
        price: 4255.08,
        measure: 'шт',
        spec: false,
        quantity: 13,
        sort: 'ДКУ29-80-501 Светильник светодиодный уличный 80 Вт',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы газоразрядные',
        code: 4257,
        description: 'Лампа натриевая ДНаТ-150 150Вт Е40',
        price: 256.38,
        measure: 'шт',
        spec: false,
        quantity: 76,
        sort: 'ДНаТ-150 Е40',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы газоразрядные',
        code: 713,
        description: 'Лампа натриевая ДНаТ-250 250Вт Е40',
        price: 258.42,
        measure: 'шт',
        spec: true,
        quantity: 56,
        sort: 'ДНаТ-250 Е40   (30шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 19792,
        description: 'Светильник ДПБ 12Вт светодиодный Led',
        price: 465,
        measure: 'шт',
        spec: true,
        quantity: 2110,
        sort: 'ДПБ 01-2х6-001 Светильник 12Вт светодиодный Led',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы газоразрядные',
        code: 12112,
        description: 'Лампа ртутная бездроссельная ДРВ-160 Е27',
        price: 111.12,
        measure: 'шт',
        spec: false,
        quantity: 45,
        sort: 'ДРВ-160 Е27 Лампа ртутная бездроссельная   (40шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы газоразрядные',
        code: 1107,
        description: 'Лампа ртутная бездроссельная ДРВ-250 Е40',
        price: 193.98,
        measure: 'шт',
        spec: false,
        quantity: 101,
        sort: 'ДРВ-250 Е40 Лампа ртутная бездроссельная   (20шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы газоразрядные',
        code: 6263,
        description: 'Лампа металлогалогеновая ДРИ 400 E40',
        price: 389.64,
        measure: 'шт',
        spec: false,
        quantity: 50,
        sort: 'ДРИ 400 E40',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы газоразрядные',
        code: 714,
        description: 'Лампа ртутная ДРЛ-125 125Вт Е27',
        price: 125.1,
        measure: 'шт',
        spec: false,
        quantity: 585,
        sort: 'ДРЛ-125 Е27 (25шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы газоразрядные',
        code: 715,
        description: 'Лампа ртутная ДРЛ-250 250Вт Е40',
        price: 158.4,
        measure: 'шт',
        spec: true,
        quantity: 1519,
        sort: 'ДРЛ-250 Е40   (20шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы газоразрядные',
        code: 716,
        description: 'Лампа ртутная ДРЛ-400 400Вт Е40',
        price: 214.5,
        measure: 'шт',
        spec: false,
        quantity: 92,
        sort: 'ДРЛ-400 Е40   (15шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы накаливания',
        code: 6264,
        description: 'Лампа накаливания ДС-40 40Вт 220В Е14',
        price: 12.3,
        measure: 'шт',
        spec: false,
        quantity: 305,
        sort: 'ДС-40 220В Е14   (100 шт./кор)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Декоративные'
    },
    {
        folder: 'Лампы накаливания',
        code: 717,
        description: 'Лампа накаливания ДС-60 60Вт 220В Е14',
        price: 12.3,
        measure: 'шт',
        spec: false,
        quantity: 100,
        sort: 'ДС-60 220В Е14',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Декоративные'
    },
    {
        folder: 'Расходные материалы',
        code: 1162,
        description: 'Дюбель 6х40 ',
        price: 0.3,
        measure: 'шт',
        spec: false,
        quantity: 23500,
        sort: 'Дюбель 6х40   (1000шт/уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Расходные материалы',
        code: 2874,
        description: 'Дюбель-гвоздь 6х40   (200шт/уп.)',
        price: 0.74,
        measure: 'шт',
        spec: false,
        quantity: 46528,
        sort: 'Дюбель-гвоздь 6х40   (200шт/уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Патроны',
        code: 16701,
        description: 'Патрон E14 пластиковый с кольцом белый',
        price: 12.54,
        measure: 'шт',
        spec: false,
        quantity: 430,
        sort: 'Е14 Патрон пластиковый с кольцом белый  (50шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': 'пластик',
        '000000031': 'Е14',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Патроны',
        code: 6130,
        description: 'Патрон E14 подвесной керамический',
        price: 9.6,
        measure: 'шт',
        spec: false,
        quantity: 699,
        sort: 'Е14 Патрон подвесной керамический   (400шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': 'керамика',
        '000000031': 'Е14',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Патроны',
        code: 11060,
        description: 'Патрон Е27 настенный карболитовый',
        price: 22.98,
        measure: 'шт',
        spec: false,
        quantity: 78,
        sort: 'Е27 Патрон настенный карболитовый',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': 'карболит',
        '000000031': 'Е27',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Патроны',
        code: 863,
        description: 'Патрон Е27 подвесной карболитовый',
        price: 15.9,
        measure: 'шт',
        spec: true,
        quantity: 20551,
        sort: 'Е27 Патрон подвесной карболитовый   (200шт./кор./20шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': 'карболит',
        '000000031': 'Е27',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Патроны',
        code: 864,
        description: 'Патрон Е27 подвесной керамический ',
        price: 8.34,
        measure: 'шт',
        spec: false,
        quantity: 1753,
        sort: 'Е27 Патрон подвесной керамический   (400шт/.упак.200шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': 'керамика',
        '000000031': 'Е27',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Патроны',
        code: 17066,
        description: 'Патрон Е27 потолочный карболитовый прямой',
        price: 22.98,
        measure: 'шт',
        spec: false,
        quantity: 162,
        sort: 'Е27 Патрон потолочный карболитовый прямой   (50шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': 'карболит',
        '000000031': 'Е27',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Патроны',
        code: 3555,
        description: 'Патрон Е40 подвесной керамический ',
        price: 47.22,
        measure: 'шт',
        spec: false,
        quantity: 106,
        sort: 'Е40 Патрон подвесной керамический',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': 'керамика',
        '000000031': 'Е40',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода СИП и арматура к ним',
        code: 17271,
        description: 'Зажим анкерный ЗАБ 16-25 М',
        price: 59.04,
        measure: 'шт',
        spec: false,
        quantity: 173,
        sort: 'ЗАБ 16-25 М Зажим анкерный',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода СИП и арматура к ним',
        code: 17307,
        description: 'Зажим анкерный клиновой ЗАК 50-70/1500',
        price: 272.7,
        measure: 'шт',
        spec: false,
        quantity: 97,
        sort: 'ЗАК 50-70/1500 Зажим анкерный клиновой',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Зажимы винтовые ЗВИ (клеммные колодки)',
        code: 6554,
        description: 'Зажим винтовой ЗВИ-3 4кв.мм 12пар',
        price: 18.9,
        measure: 'шт',
        spec: false,
        quantity: 488,
        sort: 'ЗВИ-  3 зажим винтовой 4 кв.мм 12 пар.(10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Зажимы винтовые ЗВИ (клеммные колодки)',
        code: 1628,
        description: 'Зажим винтовой ЗВИ-5 6 кв.мм 12пар',
        price: 18.9,
        measure: 'шт',
        spec: false,
        quantity: 417,
        sort: 'ЗВИ-  5 зажим винтовой 6 кв.мм 12 пар(10шт./упак)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Зажимы винтовые ЗВИ (клеммные колодки)',
        code: 14396,
        description: 'Зажим винтовой ЗВИ-10 10 кв.мм 12пар',
        price: 31.14,
        measure: 'шт',
        spec: false,
        quantity: 122,
        sort: 'ЗВИ- 10 зажим винтовой 10 кв.мм 12 пар  (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Зажимы винтовые ЗВИ (клеммные колодки)',
        code: 11602,
        description: 'Зажим винтовой ЗВИ-15 12 кв.мм 12пар',
        price: 32.88,
        measure: 'шт',
        spec: false,
        quantity: 50,
        sort: 'ЗВИ- 15 зажим винтовой 12 кв.мм 12 пар   (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Зажимы винтовые ЗВИ (клеммные колодки)',
        code: 1629,
        description: 'Зажим винтовой ЗВИ-20 14 кв.мм 12пар',
        price: 41.22,
        measure: 'шт',
        spec: false,
        quantity: 235,
        sort: 'ЗВИ- 20 зажим винтовой 14 кв.мм 12 пар   (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Зажимы винтовые ЗВИ (клеммные колодки)',
        code: 2715,
        description: 'Зажим винтовой ЗВИ-30 16 кв.мм 12пар',
        price: 50.7,
        measure: 'шт',
        spec: false,
        quantity: 350,
        sort: 'ЗВИ- 30 зажим винтовой 16 кв.мм 12 пар   (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Зажимы винтовые ЗВИ (клеммные колодки)',
        code: 825,
        description: 'Зажим винтовой ЗВИ-60 25 кв.мм 12пар',
        price: 85.98,
        measure: 'шт',
        spec: false,
        quantity: 50,
        sort: 'ЗВИ- 60 зажим винтовой 25 кв.мм 12 пар',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода СИП и арматура к ним',
        code: 18525,
        description: 'Зажим герметичный ответвительный прокалывающий ЗГОП 16-95/1,5-10',
        price: 78.18,
        measure: 'шт',
        spec: false,
        quantity: 389,
        sort: 'ЗГОП 16- 95/1,5-10 Зажим герметичный ответвительный прокалывающий   (20шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода СИП и арматура к ним',
        code: 17062,
        description: 'Зажим герметичный ответвительный прокалывающий ЗГОП 16-95/2,5-35',
        price: 115.02,
        measure: 'шт',
        spec: false,
        quantity: 298,
        sort: 'ЗГОП 16- 95/2,5-35 Зажим герметичный ответвительный прокалывающий   (8шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода СИП и арматура к ним',
        code: 19548,
        description: 'Зажим герметичный ответвительный прокалывающий ЗГОП 25-150/25-95',
        price: 140.34,
        measure: 'шт',
        spec: false,
        quantity: 54,
        sort: 'ЗГОП 25-150/25-95 Зажим герметичный ответвительный прокалывающий',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Знаки электробезопасности',
        code: 5763,
        description: 'Знак "220В" 15х50мм',
        price: 1.98,
        measure: 'шт',
        spec: false,
        quantity: 135,
        sort: 'Знак "220В" 15х50мм ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Знаки электробезопасности',
        code: 5764,
        description: 'Знак "380В" 15х50мм',
        price: 1.98,
        measure: 'шт',
        spec: false,
        quantity: 55,
        sort: 'Знак "380В" 15х50мм ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Знаки электробезопасности',
        code: 10609,
        description: 'Знак "Заземление" 30х30мм',
        price: 1.8,
        measure: 'шт',
        spec: false,
        quantity: 96,
        sort: 'Знак "Заземление" 30х30мм ИЭК',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Знаки электробезопасности',
        code: 5765,
        description: 'Знак "Молния" (треугольник) 50х50х50мм',
        price: 3.42,
        measure: 'шт',
        spec: false,
        quantity: 3053,
        sort: 'Знак "Молния" (треугольник)  50х50х50мм ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Знаки электробезопасности',
        code: 15762,
        description: 'Знак "молния" (треугольник) 160х160х160мм',
        price: 39.48,
        measure: 'шт',
        spec: false,
        quantity: 225,
        sort: 'Знак "молния" (треугольник) 160х160х160мм',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Знаки электробезопасности',
        code: 3379,
        description: 'Знак треугольник "молния" 100мм самоклеящаяся пленка',
        price: 10.86,
        measure: 'шт',
        spec: false,
        quantity: 1535,
        sort: 'Знак треугольник "молния" 100мм самоклеящаяся пленка',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 15509,
        description: 'Зажим наборный ЗНИ-2,5мм2 (JXB25А) серый',
        price: 10.92,
        measure: 'шт',
        spec: false,
        quantity: 76,
        sort: 'ЗНИ-2,5мм2 Зажим наборный (JXB25А) серый  (50шт.уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 10824,
        description: 'Зажим наборный ЗНИ-4мм2 (JXB35А) серый',
        price: 12.18,
        measure: 'шт',
        spec: false,
        quantity: 54,
        sort: 'ЗНИ-4мм2 Зажим наборный (JXB35А) серый ИЭК  (50шт.уп)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Изолента',
        code: 1754,
        description: 'Изолента ПВХ белая',
        price: 30.06,
        measure: 'шт',
        spec: false,
        quantity: 555,
        sort: 'Изолента белая ПВХ  (200шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': 'ПВХ',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Изолента',
        code: 1755,
        description: 'Изолента ПВХ желтая',
        price: 30.06,
        measure: 'шт',
        spec: false,
        quantity: 2747,
        sort: 'Изолента желтая ПВХ  (200шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': 'ПВХ',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Изолента',
        code: 3049,
        description: 'Изолента ПВХ желто-зеленая',
        price: 34.38,
        measure: 'шт',
        spec: false,
        quantity: 1891,
        sort: 'Изолента желто-зеленая ПВХ  (200шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': 'ПВХ',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Изолента',
        code: 1753,
        description: 'Изолента ПВХ зеленая',
        price: 30.06,
        measure: 'шт',
        spec: false,
        quantity: 3453,
        sort: 'Изолента зеленая ПВХ  (200шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': 'ПВХ',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Изолента',
        code: 1752,
        description: 'Изолента ПВХ красная',
        price: 30.06,
        measure: 'шт',
        spec: false,
        quantity: 3436,
        sort: 'Изолента красная ПВХ   (200шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': 'ПВХ',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Изолента',
        code: 2409,
        description: 'Изолента ПВХ синяя',
        price: 30.06,
        measure: 'шт',
        spec: true,
        quantity: 16417,
        sort: 'Изолента синяя ПВХ  (200шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': 'ПВХ',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Изолента',
        code: 17144,
        description: 'Изолента ХБ 19мм х  7м 100г',
        price: 29.28,
        measure: 'шт',
        spec: false,
        quantity: 10,
        sort: 'Изолента ХБ 19мм х  7м 100г',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': 'ХБ',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Изолента',
        code: 822,
        description: 'Изолента ХБ 19мм х 30м 300г',
        price: 97.56,
        measure: 'шт',
        spec: false,
        quantity: 467,
        sort: 'Изолента ХБ 19мм х 30м 300г (50шт/уп)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': 'ХБ',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Изолента',
        code: 3090,
        description: 'Изолента ХБ 600г  (25шт.уп)',
        price: 192,
        measure: 'шт',
        spec: false,
        quantity: 47,
        sort: 'Изолента ХБ 600г  (25шт.уп)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': 'ХБ',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Изолента',
        code: 1751,
        description: 'Изолента ПВХ черная',
        price: 30.06,
        measure: 'шт',
        spec: true,
        quantity: 1876,
        sort: 'Изолента черная ПВХ  (200шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': 'ПВХ',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 9309,
        description: 'Изолятор угловой для нулевой шины синий  (100шт.уп.)',
        price: 2.04,
        measure: 'шт',
        spec: false,
        quantity: 68,
        sort: 'Изолятор угловой для нулевой шины синий  (100шт.уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода СИП и арматура к ним',
        code: 20263,
        description: 'Кронштейн анкерный КАМ-1500',
        price: 171.84,
        measure: 'шт',
        spec: false,
        quantity: 93,
        sort: 'КАМ-1500 Кронштейн анкерный  (8шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Коробки установочные (подрозетники)',
        code: 15456,
        description: 'Канал соединительный для установочных коробок ',
        price: 1.38,
        measure: 'шт',
        spec: false,
        quantity: 1112,
        sort: 'Канал соединительный для установочных коробок (код 4319)  (100шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабель КГ',
        code: 647,
        description: 'Кабель КГ 3х1,5',
        price: 32.94,
        measure: 'м',
        spec: false,
        quantity: 368,
        sort: 'КГ 3х  1,5 Кабель  (100м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабель КГ',
        code: 648,
        description: 'Кабель КГ 3х2,5',
        price: 50.4,
        measure: 'м',
        spec: false,
        quantity: 865,
        sort: 'КГ 3х  2,5 Кабель  (на барабане)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабель КГ',
        code: 1560,
        description: 'Кабель КГ 4х2,5',
        price: 66.42,
        measure: 'м',
        spec: false,
        quantity: 505,
        sort: 'КГ 4х  2,5 Кабель',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабель КГ',
        code: 649,
        description: 'Кабель КГ 4х4',
        price: 99.9,
        measure: 'м',
        spec: false,
        quantity: 180,
        sort: 'КГ 4х  4 Кабель  ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабель КГ',
        code: 1102,
        description: 'Кабель КГ 4х6',
        price: 150.3,
        measure: 'м',
        spec: false,
        quantity: 274,
        sort: 'КГ 4х  6 Кабель',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабель КГ',
        code: 3317,
        description: 'Кабель КГ 4х10',
        price: 246.66,
        measure: 'м',
        spec: false,
        quantity: 110,
        sort: 'КГ 4х 10 Кабель',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы галогеновые',
        code: 8439,
        description: 'Лампа кварцевая галогеновая КГ-1000 R7s L=189мм',
        price: 42.9,
        measure: 'шт',
        spec: false,
        quantity: 59,
        sort: 'КГ-1000 R7s L=189мм Китай (лампа галогеновая)  (50шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы галогеновые',
        code: 180,
        description: 'Лампа кварцевая галогеновая КГ-1000 R7s L=189мм',
        price: 200.52,
        measure: 'шт',
        spec: false,
        quantity: 74,
        sort: 'КГ-1000 R7s L=189мм Россия (лампа галогеновая)  (20шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы галогеновые',
        code: 8440,
        description: 'Лампа кварцевая галогеновая КГ-150 R7s L=78мм',
        price: 24.36,
        measure: 'шт',
        spec: false,
        quantity: 113,
        sort: 'КГ-150 R7s L= 78мм Китай (лампа галогеновая)  (50шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы галогеновые',
        code: 2391,
        description: 'Лампа кварцевая галогеновая КГ-500 R7s L=117мм',
        price: 29.94,
        measure: 'шт',
        spec: false,
        quantity: 48,
        sort: 'КГ-500 R7s L=117мм Китай (лампа галогеновая)  (50шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клипсы для труб ПВХ и ПНД',
        code: 184,
        description: 'Клипса 16 для трубы',
        price: 0.78,
        measure: 'шт',
        spec: true,
        quantity: 9650,
        sort: 'Клипса 16 д/трубы (№50)   (50шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клипсы для труб ПВХ и ПНД',
        code: 2664,
        description: 'Клипса 16 для трубы черная',
        price: 1.38,
        measure: 'шт',
        spec: false,
        quantity: 850,
        sort: 'Клипса 16 д/трубы Черная   (50шт./уп)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клипсы для труб ПВХ и ПНД',
        code: 185,
        description: 'Клипса 20 для трубы',
        price: 0.96,
        measure: 'шт',
        spec: true,
        quantity: 10752,
        sort: 'Клипса 20 д/трубы (№50)   (50шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клипсы для труб ПВХ и ПНД',
        code: 16999,
        description: 'Клипса 20 для трубы черная',
        price: 1.62,
        measure: 'шт',
        spec: false,
        quantity: 2000,
        sort: 'Клипса 20 д/трубы Черная   (50шт./уп)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клипсы для труб ПВХ и ПНД',
        code: 1088,
        description: 'Клипса 25 для трубы',
        price: 1.2,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'Клипса 25 д/трубы   (№50)   (50шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клипсы для труб ПВХ и ПНД',
        code: 17000,
        description: 'Клипса 25 для трубы черная',
        price: 2.52,
        measure: 'шт',
        spec: false,
        quantity: 1450,
        sort: 'Клипса 25 д/трубы Черная   (50шт./уп)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клипсы для труб ПВХ и ПНД',
        code: 1235,
        description: 'Клипса 32 для трубы',
        price: 2.1,
        measure: 'шт',
        spec: false,
        quantity: 562,
        sort: 'Клипса 32 д/трубы   (№50)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клипсы для труб ПВХ и ПНД',
        code: 4530,
        description: 'Клипса 40 для трубы',
        price: 3.48,
        measure: 'шт',
        spec: false,
        quantity: 335,
        sort: 'Клипса 40 д/трубы   (25шт./уп и 30шт.упак',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клипсы для труб ПВХ и ПНД',
        code: 3389,
        description: 'Клипса 50 для трубы',
        price: 9.84,
        measure: 'шт',
        spec: false,
        quantity: 410,
        sort: 'Клипса 50 д/трубы   (25шт./уп)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Контакторы',
        code: 4824,
        description: 'Контактор КМН-10910 9А 230В/АС3 1НО ТДМ',
        price: 294.18,
        measure: 'шт',
        spec: false,
        quantity: 5,
        sort: 'КМН-10910 Контактор 9А 230В/АС3 1НО ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Контакторы',
        code: 12898,
        description: 'Контактор КМН-11210 12А 230В/АС3 1НО ТДМ',
        price: 303.06,
        measure: 'шт',
        spec: false,
        quantity: 3,
        sort: 'КМН-11210 Контактор 12А 230В/АС3 1НО ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Контакторы',
        code: 12899,
        description: 'Контактор КМН-11810 18А 230В/АС3 1НО ТДМ',
        price: 345.78,
        measure: 'шт',
        spec: false,
        quantity: 6,
        sort: 'КМН-11810 Контактор 18А 230В/АС3 1НО ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Контакторы',
        code: 12900,
        description: 'Контактор КМН-22510 25А 230В/АС3 1НО ТДМ',
        price: 466.68,
        measure: 'шт',
        spec: false,
        quantity: 6,
        sort: 'КМН-22510 Контактор 25А 230В/АС3 1НО ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Контакторы',
        code: 17857,
        description: 'Контактор КМН-22510 25А 400В/АС3 1НО ТДМ',
        price: 518.4,
        measure: 'шт',
        spec: false,
        quantity: 5,
        sort: 'КМН-22510 Контактор 25А 400В/АС3 1НО ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Контакторы',
        code: 12829,
        description: 'Контактор КМН-22511 25А 230В/АС3 1НЗ ТДМ',
        price: 511.92,
        measure: 'шт',
        spec: false,
        quantity: 4,
        sort: 'КМН-22511 Контактор 25А 230В/АС3 1НЗ ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Контакторы',
        code: 12901,
        description: 'Контактор КМН-23210 32А 230В/АС3 1НО ТДМ',
        price: 532.56,
        measure: 'шт',
        spec: false,
        quantity: 4,
        sort: 'КМН-23210 Контактор 32А 230В/АС3 1НО ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Контакторы',
        code: 12902,
        description: 'Контактор КМН-34012 40А 230В/АС3 1НО 1НЗ ТДМ',
        price: 1183.44,
        measure: 'шт',
        spec: false,
        quantity: 3,
        sort: 'КМН-34012 Контактор 40А 230В/АС3 1НО 1НЗ ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Контакторы',
        code: 20537,
        description: 'Контактор КМН-34012 40А 400В/АС3 1НО 1НЗ ТДМ',
        price: 1201.32,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'КМН-34012 Контактор 40А 400В/АС3 1НО 1НЗ ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Контакторы',
        code: 12904,
        description: 'Контактор КМН-46512 65А 230В/АС3 1НО 1НЗ ТДМ',
        price: 1253.04,
        measure: 'шт',
        spec: false,
        quantity: 5,
        sort: 'КМН-46512 Контактор 65А 230В/АС3 1НО 1НЗ ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 4881,
        description: 'Бокс для наружной установки КМПн 1/2 1 р / 2 мод.',
        price: 19.26,
        measure: 'шт',
        spec: false,
        quantity: 140,
        sort: 'КМПн 1/2 Бокс для нар.установки 1 р / 2 мод. ИЭК',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'КМПн',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 17048,
        description: 'Бокс для наружной установки КМПн 1/2 с крышкой',
        price: 144.6,
        measure: 'шт',
        spec: false,
        quantity: 10,
        sort: 'КМПн 1/2 Бокс для нар.установки с крышкой',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'КМПн',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 12589,
        description: 'Бокс пластиковый КМПн 1/4 4 модуля IP30',
        price: 26.4,
        measure: 'шт',
        spec: false,
        quantity: 17,
        sort: 'КМПн 1/4 Бокс пластик IP30 ИЭК',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'КМПн',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 17050,
        description: 'Бокс для наружной установки КМПн 2/4 с крышкой',
        price: 181.02,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'КМПн 2/4 Бокс для нар.установки с крышкой',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'КМПн',
        '000000043': ''
    },
    {
        folder: 'Средства защиты',
        code: 1127,
        description: 'Коврик диэлектрический 500х500',
        price: 259.2,
        measure: 'шт',
        spec: false,
        quantity: 58,
        sort: 'Коврик диэлектрический  500х500',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Средства защиты',
        code: 1128,
        description: 'Коврик диэлектрический 750х750',
        price: 472.86,
        measure: 'шт',
        spec: false,
        quantity: 17,
        sort: 'Коврик диэлектрический  750х750',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Электроустановочные изделия из каучука',
        code: 7418,
        description: 'Колодка каучуковая на 1 розетку 16A 2P+E IP44',
        price: 128.4,
        measure: 'шт',
        spec: false,
        quantity: 28,
        sort: 'Колодка 1я с/з каучук IP44',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Электроустановочные изделия из каучука',
        code: 9985,
        description: 'Колодка каучуковая переносная на 1 розетку 16A 2P+E IP44',
        price: 96.72,
        measure: 'шт',
        spec: false,
        quantity: 40,
        sort: 'Колодка 1я с/з каучук ПЕРЕНОСНАЯ IP44 (см. картинку)  (15шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 18388,
        description: 'Колодка удлинителя 2-м с заземлением',
        price: 70.56,
        measure: 'шт',
        spec: false,
        quantity: 29,
        sort: 'Колодка 2я с/з   (50шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Электроустановочные изделия из каучука',
        code: 1254,
        description: 'Колодка каучуковая на 2 розетки 16A 2P+E IP44',
        price: 193.44,
        measure: 'шт',
        spec: false,
        quantity: 23,
        sort: 'Колодка 2я с/з каучук IP44',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 17534,
        description: 'Колодка удлинителя 3-м с заземлением',
        price: 97.8,
        measure: 'шт',
        spec: false,
        quantity: 63,
        sort: 'Колодка 3я с/з   (40шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Электроустановочные изделия из каучука',
        code: 869,
        description: 'Колодка каучуковая на 3 розетки IP44',
        price: 231.36,
        measure: 'шт',
        spec: true,
        quantity: 437,
        sort: 'Колодка 3я с/з каучук IP44   9шт/уп.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 18387,
        description: 'Колодка удлинителя 4-м с заземлением',
        price: 132.24,
        measure: 'шт',
        spec: false,
        quantity: 13,
        sort: 'Колодка 4я с/з   (24шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Электроизмерительные приборы',
        code: 3339,
        description: 'Указатель напряжения Контакт 55 ЭМ (звук)',
        price: 390,
        measure: 'шт',
        spec: false,
        quantity: 1,
        sort: 'Контакт 55 ЭМ (звук) Указатель напряжения',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Плавкие вставки и держатели к ним',
        code: 14034,
        description: 'Контакт основания (держатель) 100А для ПН-2',
        price: 20.52,
        measure: 'шт',
        spec: false,
        quantity: 64,
        sort: 'Контакт основания (держатель) 100А для ПН-2',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Плавкие вставки и держатели к ним',
        code: 941,
        description: 'Контакт основания (держатель) 250А для ПН-2',
        price: 47.34,
        measure: 'шт',
        spec: false,
        quantity: 29,
        sort: 'Контакт основания (держатель) 250А для ПН-2',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Плавкие вставки и держатели к ним',
        code: 11076,
        description: 'Контакт основания (держатель) 400А для ПН-2',
        price: 70.86,
        measure: 'шт',
        spec: false,
        quantity: 10,
        sort: 'Контакт основания (держатель) 400А для ПН-2',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода СИП и арматура к ним',
        code: 9253,
        description: 'Комплект промежуточной подвески КОПП-1500',
        price: 276.3,
        measure: 'шт',
        spec: false,
        quantity: 95,
        sort: 'КОПП-1500 Комплект промежуточной подвески   (20шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабельные каналы (короба)',
        code: 3130,
        description: 'Кабель-канал ПВХ 12х12',
        price: 9.3,
        measure: 'м',
        spec: false,
        quantity: 646,
        sort: 'Короб  12х12 ПВХ (200м в упаковке)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабельные каналы (короба)',
        code: 2808,
        description: 'Кабель-канал ПВХ 15х10',
        price: 9.54,
        measure: 'м',
        spec: false,
        quantity: 1032,
        sort: 'Короб  15х10 ПВХ (200м в упаковке)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабельные каналы (короба)',
        code: 828,
        description: 'Кабель-канал ПВХ 16х16',
        price: 12,
        measure: 'м',
        spec: true,
        quantity: 4282,
        sort: 'Короб  16х16 ПВХ (140м в упаковке)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабельные каналы (короба)',
        code: 1057,
        description: 'Кабель-канал ПВХ 20х10',
        price: 12.36,
        measure: 'м',
        spec: false,
        quantity: 1908,
        sort: 'Короб  20х10 ПВХ (100м в упаковке)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабельные каналы (короба)',
        code: 3132,
        description: 'Кабель-канал ПВХ 25х16',
        price: 19.68,
        measure: 'м',
        spec: true,
        quantity: 6374,
        sort: 'Короб  25х16 ПВХ (80м в упаковке)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабельные каналы (короба)',
        code: 829,
        description: 'Кабель-канал ПВХ 25х25',
        price: 24.48,
        measure: 'м',
        spec: false,
        quantity: 5818,
        sort: 'Короб  25х25 ПВХ (80м в упаковке)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабельные каналы (короба)',
        code: 830,
        description: 'Кабель-канал ПВХ 40х16',
        price: 29.7,
        measure: 'м',
        spec: false,
        quantity: 528,
        sort: 'Короб  40х16 ПВХ (48м/40м в упаковке)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабельные каналы (короба)',
        code: 2312,
        description: 'Кабель-канал ПВХ 40х25',
        price: 34.2,
        measure: 'м',
        spec: true,
        quantity: 6428,
        sort: 'Короб  40х25 ПВХ (40м в упаковке)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабельные каналы (короба)',
        code: 831,
        description: 'Кабель-канал ПВХ 40х40',
        price: 46.32,
        measure: 'м',
        spec: false,
        quantity: 1490,
        sort: 'Короб  40х40 ПВХ (30м в упаковке)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабельные каналы (короба)',
        code: 1820,
        description: 'Кабель-канал ПВХ 60х40',
        price: 66.12,
        measure: 'м',
        spec: true,
        quantity: 3986,
        sort: 'Короб  60х40 ПВХ (40м в упаковке)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабельные каналы (короба)',
        code: 832,
        description: 'Кабель-канал ПВХ 60х60',
        price: 90.06,
        measure: 'м',
        spec: false,
        quantity: 76,
        sort: 'Короб  60х60 ПВХ (18м. в упаковке)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабельные каналы (короба)',
        code: 4141,
        description: 'Кабель-канал ПВХ 80х40',
        price: 110.1,
        measure: 'м',
        spec: false,
        quantity: 192,
        sort: 'Короб  80х40 ПВХ (30м в упаковке)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабельные каналы (короба)',
        code: 833,
        description: 'Кабель-канал ПВХ 80х60',
        price: 132.96,
        measure: 'м',
        spec: false,
        quantity: 104,
        sort: 'Короб  80х60 ПВХ (24м в упаковке)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабельные каналы (короба)',
        code: 3258,
        description: 'Кабель-канал ПВХ 100х40',
        price: 131.28,
        measure: 'м',
        spec: false,
        quantity: 463,
        sort: 'Короб 100х40 ПВХ (12м в упаковке)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Кабельные каналы (короба)',
        code: 827,
        description: 'Кабель-канал ПВХ 100х60',
        price: 142.8,
        measure: 'м',
        spec: true,
        quantity: 652,
        sort: 'Короб 100х60 ПВХ (12м в упаковке)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Коробки установочные (подрозетники)',
        code: 14601,
        description: 'Коробка 68х42 установочная скрытой проводки красная (подрозетник стыковочный для бетонных стен)',
        price: 6.66,
        measure: 'шт',
        spec: false,
        quantity: 300,
        sort: 'Коробка  68х42 СП красная (подрозетник для бетон стен)стыковочные узлы   (300шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Коробки установочные (подрозетники)',
        code: 4319,
        description: 'Коробка 68х45 скрытой проводки (для бетонных стен)',
        price: 5.64,
        measure: 'шт',
        spec: false,
        quantity: 1276,
        sort: 'Коробка  68х45 СП синяя (подрозет. для бетон. стен) для соед.в ряд испол. код 15456  (200шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Коробки установочные (подрозетники)',
        code: 12516,
        description: 'Коробка 68х45 установочная для Г/К стен',
        price: 7.86,
        measure: 'шт',
        spec: false,
        quantity: 258,
        sort: 'Коробка  68х45 установочная для Г/К стен   (200шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Коробки распределительные (распаячные)',
        code: 837,
        description: 'Коробка распределительная 70х70х40 открытой установки IP54',
        price: 21.66,
        measure: 'шт',
        spec: false,
        quantity: 1302,
        sort: 'Коробка  70х70х40 распределительная о/у IP54   (150шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Коробки распределительные (распаячные)',
        code: 12878,
        description: 'Коробка распределительная 80х80х50 открытой установки IP54',
        price: 27.72,
        measure: 'шт',
        spec: false,
        quantity: 239,
        sort: 'Коробка  80х80х50 распределительная о/у IP54   (100шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Коробки распределительные (распаячные)',
        code: 835,
        description: 'Коробка распределительная 100х100х50 открытой установки IP54',
        price: 39.18,
        measure: 'шт',
        spec: true,
        quantity: 1021,
        sort: 'Коробка 100х100х50 распределительная о/у IP54   (60шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Коробки распределительные (распаячные)',
        code: 187,
        description: 'Коробка распределительная 150х110х70 открытой установки IP55 ',
        price: 89.16,
        measure: 'шт',
        spec: false,
        quantity: 74,
        sort: 'Коробка 150х110х70 распределительная IP55   (30шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Приборы учета электроэнергии',
        code: 5821,
        description: 'Коробка испытательная для счетчиков КИП прозрачная крышка',
        price: 205.68,
        measure: 'шт',
        spec: false,
        quantity: 3,
        sort: 'Коробка испытательная д/счетчиков КИП прозрачная крышка',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Расходные материалы',
        code: 5961,
        description: 'Круг отрезной по металлу 115х1,2х22мм ',
        price: 36.6,
        measure: 'шт',
        spec: false,
        quantity: 182,
        sort: 'Круг 115х1,2х22мм отрезной по металлу (50/уп., 200-400/кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Расходные материалы',
        code: 43,
        description: 'Круг отрезной по металлу 230x2,5x22,2мм',
        price: 74,
        measure: 'шт',
        spec: false,
        quantity: 124,
        sort: 'Круг 230x2,5x22мм отрезной по металлу  (25шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Линейные люминесцентные лампы',
        code: 719,
        description: 'Лампа люминесцентная ЛБ-20 (18) G13',
        price: 39.66,
        measure: 'шт',
        spec: true,
        quantity: 1225,
        sort: 'ЛБ-20 (18) G13 (25шт/упак) строго кратно упаковке (!!! если их нет продаём ЛД)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Линейные люминесцентные лампы',
        code: 2197,
        description: 'Лампа люминесцентная ЛБ-40 (36) G13',
        price: 51.72,
        measure: 'шт',
        spec: true,
        quantity: 1470,
        sort: 'ЛБ-40 (36) G13 (25 шт/упак) (!!! если их нет продаём ЛД)  кратно упаковке.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники люминесцентные',
        code: 922,
        description: 'Светильник встраиваемый зеркальный ЛВО 4х18 595х595 с ЭПРА',
        price: 708.54,
        measure: 'шт',
        spec: false,
        quantity: 13,
        sort: 'ЛВО  4х18 Светильник встраиваемый зерк. 595х595 с ЭПРА  (2шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Линейные люминесцентные лампы',
        code: 13261,
        description: 'Лампа люминесцентная ЛД-20 (18) G13',
        price: 39.66,
        measure: 'шт',
        spec: false,
        quantity: 2681,
        sort: 'ЛД-20 (18) G13 (25 шт/упак) кратно упаковке',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Линейные люминесцентные лампы',
        code: 13262,
        description: 'Лампа люминесцентная ЛД-40 (36) G13',
        price: 51.72,
        measure: 'шт',
        spec: false,
        quantity: 941,
        sort: 'ЛД-40 (36) G13 (30 шт/упак) кратно упаковке',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода СИП и арматура к ним',
        code: 21241,
        description: 'Лента бандажная ЛМ-50',
        price: 1874.28,
        measure: 'упак',
        spec: false,
        quantity: 11,
        sort: 'ЛМ-50 Лента бандажная   (3шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы накаливания',
        code: 720,
        description: 'Лампа накаливания ЛОН-40 40Вт 220В Е27',
        price: 11.46,
        measure: 'шт',
        spec: true,
        quantity: 38925,
        sort: 'ЛОН- 40 220В Е27 (100шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Общего назначения'
    },
    {
        folder: 'Лампы накаливания',
        code: 721,
        description: 'Лампа накаливания ЛОН-60 60Вт 220В Е27',
        price: 11.46,
        measure: 'шт',
        spec: true,
        quantity: 82783,
        sort: 'ЛОН- 60 220В Е27 (100шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Общего назначения'
    },
    {
        folder: 'Лампы накаливания',
        code: 722,
        description: 'Лампа накаливания ЛОН-75 75Вт 220В Е27',
        price: 11.46,
        measure: 'шт',
        spec: false,
        quantity: 694,
        sort: 'ЛОН- 75 220В Е27 (154шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Общего назначения'
    },
    {
        folder: 'Лампы накаливания',
        code: 723,
        description: 'Лампа накаливания ЛОН-95 95Вт 220В Е27',
        price: 11.46,
        measure: 'шт',
        spec: false,
        quantity: 2654,
        sort: 'ЛОН- 95 220В Е27 (154шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Общего назначения'
    },
    {
        folder: 'Лампы накаливания',
        code: 2364,
        description: 'Лампа накаливания ЛОН-150 150Вт 220В Е27',
        price: 16.74,
        measure: 'шт',
        spec: false,
        quantity: 234,
        sort: 'ЛОН-150 220В Е27 (100шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Общего назначения'
    },
    {
        folder: 'Лампы накаливания',
        code: 724,
        description: 'Лампа накаливания ЛОН-200 200Вт 220В Е27',
        price: 18.36,
        measure: 'шт',
        spec: false,
        quantity: 212,
        sort: 'ЛОН-200 220В Е27 (90шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Общего назначения'
    },
    {
        folder: 'Лампы накаливания',
        code: 204,
        description: 'Лампа накаливания ЛОН-500 500Вт 220В Е40',
        price: 72.66,
        measure: 'шт',
        spec: false,
        quantity: 182,
        sort: 'ЛОН-500 220В Е40 (48шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Общего назначения'
    },
    {
        folder: 'Светильники люминесцентные',
        code: 762,
        description: 'Светильник ЛПБ-31-11-006 G23 антивандальный',
        price: 310.32,
        measure: 'шт',
        spec: true,
        quantity: 2920,
        sort: 'ЛПБ-31-11-006 Светильник антивандальный G23   (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники люминесцентные',
        code: 767,
        description: 'Светильник ЛПО 01 2х36-012 с ЭПРА',
        price: 602.82,
        measure: 'шт',
        spec: false,
        quantity: 200,
        sort: 'ЛПО 01 Светильник 2х36-012 с ЭПРА',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники люминесцентные',
        code: 763,
        description: 'Светильник ЛПО 1х20(18)',
        price: 332.64,
        measure: 'шт',
        spec: false,
        quantity: 633,
        sort: 'ЛПО 1х20 Светильник   8шт/упак. (!!! стартер код 794 выставляется отдельно кратно упаковкам)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники люминесцентные',
        code: 764,
        description: 'Светильник ЛПО 1х40(36)',
        price: 402.54,
        measure: 'шт',
        spec: false,
        quantity: 643,
        sort: 'ЛПО 1х40 Светильник   4шт/упак. (!!! стартер код 795 выставляется отдельно кратно упаковкам)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники люминесцентные',
        code: 3422,
        description: 'Светильник ЛПО 2х20(18)',
        price: 401.76,
        measure: 'шт',
        spec: false,
        quantity: 142,
        sort: 'ЛПО 2х20 Светильник    4шт/упак.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники люминесцентные',
        code: 765,
        description: 'Светильник ЛПО 2х40(36)',
        price: 577.74,
        measure: 'шт',
        spec: false,
        quantity: 371,
        sort: 'ЛПО 2х40 Светильник   2шт/упак.(!!! стартер код 795 выставляется отдельно кратно упаковкам)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники люминесцентные',
        code: 1002,
        description: 'Светильник ЛПО 418 635 NC м.свет ЭПРА/Мистраль 418 А34HF ECO (накладной зеркальный)',
        price: 1196.04,
        measure: 'шт',
        spec: false,
        quantity: 70,
        sort: 'ЛПО 418 Светильник накладной зерк. с ЭПРА',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники люминесцентные',
        code: 3899,
        description: 'Светильник ЛСП 2х36 IP65 с ЭПРА',
        price: 862.08,
        measure: 'шт',
        spec: true,
        quantity: 688,
        sort: 'ЛСП 2х36 IP65 Светильник с ЭПРА (6шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Электроизмерительные приборы',
        code: 3345,
        description: 'Клещи токоизмерительные М266',
        price: 428.58,
        measure: 'шт',
        spec: false,
        quantity: 7,
        sort: 'М266 Клещи токоизмерительные',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Металлорукав',
        code: 3276,
        description: 'Металлорукав РЗ-ЦХ 12',
        price: 24.66,
        measure: 'м',
        spec: false,
        quantity: 100,
        sort: 'Металлорукав 12 РЗ-ЦХ 50м/бухта',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Металлорукав',
        code: 256,
        description: 'Металлорукав РЗ-ЦХ 15 ',
        price: 28.2,
        measure: 'м',
        spec: false,
        quantity: 50,
        sort: 'Металлорукав 15 РЗ-ЦХ   50м./бухта',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Металлорукав',
        code: 257,
        description: 'Металлорукав РЗ-ЦХ 20 ',
        price: 39.54,
        measure: 'м',
        spec: false,
        quantity: 300,
        sort: 'Металлорукав 20 РЗ-ЦХ (50м)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Металлорукав',
        code: 1876,
        description: 'Металлорукав РЗ-ЦХ 25 ',
        price: 52.38,
        measure: 'м',
        spec: false,
        quantity: 50,
        sort: 'Металлорукав 25 РЗ-ЦХ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Лампы накаливания',
        code: 6269,
        description: 'Лампа накаливания МО 12В 60Вт E27',
        price: 14.34,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'МО 12В 60Вт E27   (100шт./кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Низковольтные'
    },
    {
        folder: 'Лампы накаливания',
        code: 725,
        description: 'Лампа накаливания МО 24В 60Вт E27',
        price: 14.94,
        measure: 'шт',
        spec: false,
        quantity: 230,
        sort: 'МО 24В 60Вт E27   (100шт./кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Низковольтные'
    },
    {
        folder: 'Лампы накаливания',
        code: 1071,
        description: 'Лампа накаливания МО 36В 40Вт E27',
        price: 14.22,
        measure: 'шт',
        spec: false,
        quantity: 352,
        sort: 'МО 36В 40Вт E27 (100 шт./кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Низковольтные'
    },
    {
        folder: 'Лампы накаливания',
        code: 214,
        description: 'Лампа накаливания МО 36В 60Вт E27',
        price: 14.22,
        measure: 'шт',
        spec: false,
        quantity: 695,
        sort: 'МО 36В 60Вт E27 (100 шт./кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': 'Низковольтные'
    },
    {
        folder: 'Клипсы для труб ПВХ и ПНД',
        code: 2665,
        description: 'Муфта 16 соединительная ПВХ',
        price: 2.7,
        measure: 'шт',
        spec: false,
        quantity: 340,
        sort: 'Муфта 16 соединительная ПВХ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клипсы для труб ПВХ и ПНД',
        code: 3312,
        description: 'Муфта 20 соединительная ПВХ',
        price: 3.18,
        measure: 'шт',
        spec: false,
        quantity: 1440,
        sort: 'Муфта 20 соединительная ПВХ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Инструмент ручной',
        code: 6702,
        description: 'Набор отверток диэлектрических 7шт. в коробке',
        price: 419.82,
        measure: 'шт',
        spec: false,
        quantity: 8,
        sort: 'Набор отверток диэл. 7шт. в коробке',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники настенно-потолочные',
        code: 768,
        description: 'Арматура светильника НББ-64-60 (основание косое)',
        price: 21,
        measure: 'шт',
        spec: false,
        quantity: 168,
        sort: 'НББ-64-60 Арматура светильника (основание косое) (100 шт. в упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники настенно-потолочные',
        code: 769,
        description: 'Арматура светильника НББ-64-60 (основание прямое)',
        price: 21,
        measure: 'шт',
        spec: false,
        quantity: 1310,
        sort: 'НББ-64-60 Арматура светильника (основание прямое) (100 шт. в упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники настенно-потолочные',
        code: 770,
        description: 'Рассеиватель НББ-64-60 (шар пластиковый)',
        price: 27.84,
        measure: 'шт',
        spec: false,
        quantity: 1381,
        sort: 'НББ-64-60 Рассеиватель (шар пластиковый)  (50шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники настенно-потолочные',
        code: 771,
        description: 'Рассеиватель НББ-64-60 (шар стеклянный)',
        price: 52.62,
        measure: 'шт',
        spec: false,
        quantity: 1018,
        sort: 'НББ-64-60 Рассеиватель (шар стеклянный)  (4шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники настенно-потолочные',
        code: 7171,
        description: 'Светильник НПП 03-100 IP65',
        price: 156.54,
        measure: 'шт',
        spec: false,
        quantity: 226,
        sort: 'НПП-03-100 Светильник IP65   (5шт./кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники подвесные',
        code: 1775,
        description: 'Арматура светильника НСП 03-60 (НСП 01-60-001)',
        price: 44.88,
        measure: 'шт',
        spec: false,
        quantity: 401,
        sort: 'НСП 03-60 (НСП 01-60-001) Арматура светильника (90 шт. в упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники подвесные',
        code: 2272,
        description: 'Светильник НСП 02-100-001 без решетки',
        price: 233.88,
        measure: 'шт',
        spec: false,
        quantity: 399,
        sort: 'НСП02-100-001 Светильник без решетки  (10шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники подвесные',
        code: 773,
        description: 'Светильник НСП 02-100-003 с решеткой',
        price: 258.9,
        measure: 'шт',
        spec: false,
        quantity: 546,
        sort: 'НСП02-100-003 Светильник с решеткой  (10шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники НШвИ (штыревые)',
        code: 2647,
        description: 'Наконечник штыревой НШвИ 0,5-8 изолированный упаковка 100шт.',
        price: 24.3,
        measure: 'упак',
        spec: false,
        quantity: 24,
        sort: 'НШвИ  0,5-8 Наконечник штыр.изол. (уп.100шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники НШвИ (штыревые)',
        code: 4745,
        description: 'Наконечник штыревой НШвИ 0,75-8 изолированный упаковка 100шт.',
        price: 25.62,
        measure: 'упак',
        spec: false,
        quantity: 17,
        sort: 'НШвИ  0,75-8 Наконечник штыр.изол. (уп.100шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники НШвИ (штыревые)',
        code: 6435,
        description: 'Наконечник штыревой НШвИ 1,0-8 изолированный упаковка 100шт',
        price: 28.62,
        measure: 'упак',
        spec: false,
        quantity: 30,
        sort: 'НШвИ  1,0- 8 Наконечник штыр.изол. (уп.100шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники НШвИ (штыревые)',
        code: 2737,
        description: 'Наконечник штыревой НШвИ 1,5-8 изолированный упаковка 100шт.',
        price: 32.64,
        measure: 'упак',
        spec: false,
        quantity: 15,
        sort: 'НШвИ  1,5- 8 Наконечник штыр.изол. (уп.100шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники НШвИ (штыревые)',
        code: 1617,
        description: 'Наконечник штыревой НШвИ 2,5-8 изолированный упаковка 100шт.',
        price: 38.94,
        measure: 'упак',
        spec: false,
        quantity: 36,
        sort: 'НШвИ  2,5- 8 Наконечник штыр.изол. (уп.100шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники НШвИ (штыревые)',
        code: 3988,
        description: 'Наконечник штыревой НШвИ 4-10 изолированный упаковка 100шт.',
        price: 61.08,
        measure: 'упак',
        spec: false,
        quantity: 8,
        sort: 'НШвИ  4-10 Наконечник штыр.изол. (уп.100шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники НШвИ (штыревые)',
        code: 1619,
        description: 'Наконечник штыревой НШвИ 6-12 изолированный упаковка 100шт.',
        price: 84,
        measure: 'упак',
        spec: false,
        quantity: 10,
        sort: 'НШвИ  6-12 Наконечник штыр.изол. (уп.100шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники НШвИ (штыревые)',
        code: 3399,
        description: 'Наконечник штыревой НШвИ 10-12 изолированный упаковка 100шт.',
        price: 111.24,
        measure: 'упак',
        spec: false,
        quantity: 0,
        sort: 'НШвИ 10-12 Наконечник штыр.изол. (уп.100шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники НШвИ (штыревые)',
        code: 1800,
        description: 'Наконечник штыревой НШвИ 16-12 изолированный упаковка 100шт.',
        price: 137.1,
        measure: 'упак',
        spec: false,
        quantity: 5,
        sort: 'НШвИ 16-12 Наконечник штыр.изол. (уп.100шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 15506,
        description: 'Ограничитель на DIN-рейку (пластик)',
        price: 9.24,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'Ограничитель на DIN-рейку (пластик)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 9968,
        description: 'Ограничитель на Din-рейку металлический',
        price: 7.86,
        measure: 'шт',
        spec: false,
        quantity: 100,
        sort: 'Ограничитель на Din-рейку металл.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Электроизмерительные приборы',
        code: 2611,
        description: 'Отвертка-пробник ОП-1 ',
        price: 86.52,
        measure: 'шт',
        spec: false,
        quantity: 27,
        sort: 'ОП-1 Отвертка-пробник   (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Средства защиты',
        code: 1234,
        description: 'Очки защитные',
        price: 66.88,
        measure: 'шт',
        spec: false,
        quantity: 35,
        sort: 'Очки защитные  (50шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Инструмент ручной',
        code: 6760,
        description: 'Пассатижи диэлектрические 160мм  (6шт.в кор.)',
        price: 514.08,
        measure: 'шт',
        spec: false,
        quantity: 7,
        sort: 'Пассатижи диэлектрические 160мм  (6шт.в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 3997,
        description: 'Провод ПВ1 1,5 белый',
        price: 8.16,
        measure: 'м',
        spec: false,
        quantity: 400,
        sort: 'ПВ1  1,5 Белый   (300м./бухта)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 2455,
        description: 'Провод ПВ1 2,5 белый',
        price: 13.8,
        measure: 'м',
        spec: false,
        quantity: 227,
        sort: 'ПВ1  2,5 Белый  (200м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 657,
        description: 'Провод ПВ1 4 белый',
        price: 21.66,
        measure: 'м',
        spec: false,
        quantity: 1513,
        sort: 'ПВ1  4 Белый  (200м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 658,
        description: 'Провод ПВ1 4 желто-зеленый',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 438,
        sort: 'ПВ1  4 Желто-зеленый',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 660,
        description: 'Провод ПВ1 4 синий',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 543,
        sort: 'ПВ1  4 Синий',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 5693,
        description: 'Провод ПВ1 6 белый',
        price: 35.52,
        measure: 'м',
        spec: false,
        quantity: 993,
        sort: 'ПВ1  6 Белый  (100м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 13914,
        description: 'Провод ПВ1 6 желто-зеленый',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 270,
        sort: 'ПВ1  6 Желто-зеленый',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 5694,
        description: 'Провод ПВ1 6 синий',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 305,
        sort: 'ПВ1  6 Синий',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 1244,
        description: 'Провод ПВ1 10 белый',
        price: 53.94,
        measure: 'м',
        spec: false,
        quantity: 202,
        sort: 'ПВ1 10 Белый',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 1642,
        description: 'Провод ПВ1 16 белый',
        price: 89.52,
        measure: 'м',
        spec: false,
        quantity: 75,
        sort: 'ПВ1 16 Белый  ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 7019,
        description: 'Провод ПВ-3 0,75 белый',
        price: 5.58,
        measure: 'м',
        spec: false,
        quantity: 1100,
        sort: 'ПВ3   0,75 Белый',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 7225,
        description: 'Провод ПВ3 1,5 белый',
        price: 8.34,
        measure: 'м',
        spec: false,
        quantity: 1220,
        sort: 'ПВ3   1,5 Белый   (500м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 2342,
        description: 'Провод ПВ3 2,5 белый',
        price: 13.56,
        measure: 'м',
        spec: false,
        quantity: 910,
        sort: 'ПВ3   2,5 Белый   (400м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 1228,
        description: 'Провод ПВ-3 4 белый',
        price: 21.6,
        measure: 'м',
        spec: false,
        quantity: 208,
        sort: 'ПВ3   4 Белый   (400м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 1229,
        description: 'Провод ПВ-3 4 желто-зеленый',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 49,
        sort: 'ПВ3   4 Желто-зеленый',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 668,
        description: 'Провод ПВ-3 4 синий',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 1695,
        sort: 'ПВ3   4 Синий ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 669,
        description: 'Провод ПВ-3 6 белый',
        price: 31.92,
        measure: 'м',
        spec: false,
        quantity: 744,
        sort: 'ПВ3   6 Белый',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 227,
        description: 'Провод ПВ-3 6 желто-зеленый',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 1036,
        sort: 'ПВ3   6 Желто-зеленый',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 673,
        description: 'Провод ПВ-3 6 черный',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 200,
        sort: 'ПВ3   6 Черный',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 1436,
        description: 'Провод ПВ-3 10 белый',
        price: 54.36,
        measure: 'м',
        spec: false,
        quantity: 551,
        sort: 'ПВ3  10 Белый   (200м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 226,
        description: 'Провод ПВ-3 10 желто-зеленый',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 182,
        sort: 'ПВ3  10 Желто-зеленый',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 1437,
        description: 'Провод ПВ-3 10 синий',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 200,
        sort: 'ПВ3  10 Синий',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 663,
        description: 'Провод ПВ-3 16 белый',
        price: 88.2,
        measure: 'м',
        spec: false,
        quantity: 300,
        sort: 'ПВ3  16 Белый   (150м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 664,
        description: 'Провод ПВ-3 16 желто-зеленый',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 148,
        sort: 'ПВ3  16 Желто-зеленый',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 12991,
        description: 'Провод ПВ-3 25 белый',
        price: 139.62,
        measure: 'м',
        spec: false,
        quantity: 685,
        sort: 'ПВ3  25 Белый',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 8208,
        description: 'Провод ПВ-3 25 желто-зеленый',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 244,
        sort: 'ПВ3  25 Желто-зеленый',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 12992,
        description: 'Провод ПВ-3 25 синий',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 425,
        sort: 'ПВ3  25 Синий',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 4377,
        description: 'Провод ПВ-3 35 белый',
        price: 194.76,
        measure: 'м',
        spec: false,
        quantity: 150,
        sort: 'ПВ3  35 Белый',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 5366,
        description: 'Провод ПВ-3 50 белый',
        price: 288.3,
        measure: 'м',
        spec: false,
        quantity: 81,
        sort: 'ПВ3  50 Белый',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)',
        code: 1559,
        description: 'Провод ПВ-3 70 белый',
        price: 402.6,
        measure: 'м',
        spec: false,
        quantity: 185,
        sort: 'ПВ3  70 Белый',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провод ПВС',
        code: 1063,
        description: 'Провод ПВС 2х0,75',
        price: 12.36,
        measure: 'м',
        spec: false,
        quantity: 1300,
        sort: 'ПВС 2х  0,75   (200м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провод ПВС',
        code: 3203,
        description: 'Провод ПВС 2х1,5',
        price: 21.24,
        measure: 'м',
        spec: false,
        quantity: 3121,
        sort: 'ПВС 2х  1,5   (100м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провод ПВС',
        code: 674,
        description: 'Провод ПВС 2х2,5',
        price: 33,
        measure: 'м',
        spec: false,
        quantity: 1384,
        sort: 'ПВС 2х  2,5   (100м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провод ПВС',
        code: 228,
        description: 'Провод ПВС 3х0,75',
        price: 17.28,
        measure: 'м',
        spec: false,
        quantity: 663,
        sort: 'ПВС 3х  0,75   (200м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провод ПВС',
        code: 229,
        description: 'Провод ПВС 3х1,5',
        price: 28.74,
        measure: 'м',
        spec: true,
        quantity: 2756,
        sort: 'ПВС 3х  1,5   (100м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провод ПВС',
        code: 18627,
        description: 'Провод ПВС 3х1,5',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 1590,
        sort: 'ПВС 3х  1,5   (100м.) ГОСТ (для удлинителей)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провод ПВС',
        code: 230,
        description: 'Провод ПВС 3х2,5',
        price: 45.48,
        measure: 'м',
        spec: true,
        quantity: 4942,
        sort: 'ПВС 3х  2,5   (100м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провод ПВС',
        code: 18628,
        description: 'Провод ПВС 3х2,5',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 2720,
        sort: 'ПВС 3х  2,5   (100м.) ГОСТ (для удлинителей)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провод ПВС',
        code: 2458,
        description: 'Провод ПВС 3х4',
        price: 71.82,
        measure: 'м',
        spec: false,
        quantity: 650,
        sort: 'ПВС 3х  4',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провод ПВС',
        code: 231,
        description: 'Провод ПВС 4х1,5',
        price: 37.14,
        measure: 'м',
        spec: false,
        quantity: 661,
        sort: 'ПВС 4х  1,5   (100м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провод ПВС',
        code: 232,
        description: 'Провод ПВС 4х2,5',
        price: 59.4,
        measure: 'м',
        spec: false,
        quantity: 1010,
        sort: 'ПВС 4х  2,5   (100м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провод ПВС',
        code: 233,
        description: 'Провод ПВС 4х4',
        price: 99.06,
        measure: 'м',
        spec: false,
        quantity: 400,
        sort: 'ПВС 4х  4   (100м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провод ПВС',
        code: 234,
        description: 'Провод ПВС 4х6',
        price: 144.24,
        measure: 'м',
        spec: false,
        quantity: 500,
        sort: 'ПВС 4х  6   (100м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провод ПВС',
        code: 2179,
        description: 'Провод ПВС 4х10',
        price: 233.4,
        measure: 'м',
        spec: false,
        quantity: 100,
        sort: 'ПВС 4х 10',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провод ПВС',
        code: 3044,
        description: 'Провод ПВС 5х2,5',
        price: 78.66,
        measure: 'м',
        spec: false,
        quantity: 225,
        sort: 'ПВС 5х  2,5   (100м.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Средства защиты',
        code: 1124,
        description: 'Перчатки латексные диэлектрические',
        price: 353.58,
        measure: 'пар',
        spec: false,
        quantity: 177,
        sort: 'Перчатки латексные диэлектрические',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Средства защиты',
        code: 236,
        description: 'Перчатки Х/Б с ПВХ точки 4-нитка',
        price: 13.08,
        measure: 'пар',
        spec: false,
        quantity: 752,
        sort: 'Перчатки Х/Б с ПВХ точки 4-нитка',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Хомуты нейлоновые',
        code: 25457,
        description: 'Площадка 20х20 самоклеющаяся под хомут белая',
        price: 115.98,
        measure: 'упак',
        spec: false,
        quantity: 36,
        sort: 'Площадка 20х20 самоклеющаяся под хомут белая (100шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Плавкие вставки и держатели к ним',
        code: 746,
        description: 'Плавкая вставка ПН-2 100А',
        price: 56.04,
        measure: 'шт',
        spec: false,
        quantity: 78,
        sort: 'ПН-2 100 А Плавкая вставка  (20шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Плавкие вставки и держатели к ним',
        code: 6170,
        description: 'Плавкая вставка ПН-2 100/80А',
        price: 55.92,
        measure: 'шт',
        spec: false,
        quantity: 15,
        sort: 'ПН-2 100/80А Плавкая вставка ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Плавкие вставки и держатели к ним',
        code: 747,
        description: 'Плавкая вставка ПН-2 250А',
        price: 74.22,
        measure: 'шт',
        spec: false,
        quantity: 32,
        sort: 'ПН-2 250 А Плавкая вставка   (20шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Плавкие вставки и держатели к ним',
        code: 6504,
        description: 'Плавкая вставка ПН-2 250/160А',
        price: 74.88,
        measure: 'шт',
        spec: false,
        quantity: 16,
        sort: 'ПН-2 250/160А Плавкая вставка',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Плавкие вставки и держатели к ним',
        code: 748,
        description: 'Плавкая вставка ПН-2 400А',
        price: 137.58,
        measure: 'шт',
        spec: false,
        quantity: 16,
        sort: 'ПН-2 400 А Плавкая вставка  (8шт./в кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Прожектора светодиодные',
        code: 9781,
        description: 'Прожектор светодиодный 10W',
        price: 143.76,
        measure: 'шт',
        spec: false,
        quantity: 305,
        sort: 'Прожектор светодиодный  10W   20шт/кор',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Прожектора светодиодные',
        code: 9782,
        description: 'Прожектор светодиодный 20W',
        price: 210.36,
        measure: 'шт',
        spec: false,
        quantity: 309,
        sort: 'Прожектор светодиодный  20W   (40шт/кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Прожектора светодиодные',
        code: 9808,
        description: 'Прожектор светодиодный 30W',
        price: 312.42,
        measure: 'шт',
        spec: false,
        quantity: 226,
        sort: 'Прожектор светодиодный  30W   (20шт/кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Прожектора светодиодные',
        code: 9783,
        description: 'Прожектор светодиодный 50W',
        price: 436.26,
        measure: 'шт',
        spec: true,
        quantity: 52,
        sort: 'Прожектор светодиодный  50W',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Прожектора светодиодные',
        code: 9784,
        description: 'Прожектор светодиодный 70W',
        price: 782.52,
        measure: 'шт',
        spec: false,
        quantity: 14,
        sort: 'Прожектор светодиодный  70W',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Прожектора светодиодные',
        code: 21280,
        description: 'Прожектор светодиодный 100W',
        price: 975,
        measure: 'шт',
        spec: true,
        quantity: 76,
        sort: 'Прожектор светодиодный 100W   (10шт/кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Прожектора светодиодные',
        code: 20801,
        description: 'Прожектор светодиодный 150W',
        price: 1387.86,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'Прожектор светодиодный 150W',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПУНП, ПУгНП и ШВВП',
        code: 3129,
        description: 'Провод ПУгНП 2х1,5',
        price: 18.06,
        measure: 'м',
        spec: false,
        quantity: 1235,
        sort: 'ПУгНП 2х  1,5   (200м./бухта)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПУНП, ПУгНП и ШВВП',
        code: 4321,
        description: 'Провод ПУгНП 2х2,5',
        price: 27.12,
        measure: 'м',
        spec: false,
        quantity: 471,
        sort: 'ПУгНП 2х  2,5   (200м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПУНП, ПУгНП и ШВВП',
        code: 2801,
        description: 'Провод ПУгНП 3х1,5',
        price: 26.1,
        measure: 'м',
        spec: false,
        quantity: 1167,
        sort: 'ПУгНП 3х  1,5  (100м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПУНП, ПУгНП и ШВВП',
        code: 1549,
        description: 'Провод ПУгНП 3х2,5',
        price: 39.78,
        measure: 'м',
        spec: false,
        quantity: 2420,
        sort: 'ПУгНП 3х  2,5   (200м./бух)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПУНП, ПУгНП и ШВВП',
        code: 675,
        description: 'Провод ПУНП 2х1,5',
        price: 18.6,
        measure: 'м',
        spec: false,
        quantity: 220,
        sort: 'ПУНП 2х  1,5   (100м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПУНП, ПУгНП и ШВВП',
        code: 676,
        description: 'Провод ПУНП 2х2,5',
        price: 28.2,
        measure: 'м',
        spec: false,
        quantity: 130,
        sort: 'ПУНП 2х  2,5  (100м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПУНП, ПУгНП и ШВВП',
        code: 2196,
        description: 'Провод ПУНП 3х1,5 ',
        price: 27.12,
        measure: 'м',
        spec: false,
        quantity: 70,
        sort: 'ПУНП 3х  1,5  (100м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПУНП, ПУгНП и ШВВП',
        code: 1053,
        description: 'Провод ПУНП 3х2,5',
        price: 41.46,
        measure: 'м',
        spec: false,
        quantity: 290,
        sort: 'ПУНП 3х  2,5   (100м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 5176,
        description: 'Розетка с заземляющим контактом на DIN-рейку',
        price: 124.32,
        measure: 'шт',
        spec: false,
        quantity: 111,
        sort: 'РАр10-3-ОП Розетка с зазем. контактом на DIN-рейку  (5шт.уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 2655,
        description: 'Розетка 1-я открытой проводки без заземления',
        price: 32.16,
        measure: 'шт',
        spec: false,
        quantity: 111,
        sort: 'Роз.1ОП б/з    120шт/кор. (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 870,
        description: 'Розетка 1-я открытой проводки с заземлением',
        price: 39.54,
        measure: 'шт',
        spec: false,
        quantity: 1109,
        sort: 'Роз.1ОП с/з     60шт/кор.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 13052,
        description: 'Розетка 1-я открытой проводки с заземлением IP54',
        price: 117.48,
        measure: 'шт',
        spec: false,
        quantity: 244,
        sort: 'Роз.1ОП с/з IP54',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 1164,
        description: 'Розетка 1-я открытой проводки с/з брызгозащищенная полугерметичная с крышкой',
        price: 84.78,
        measure: 'шт',
        spec: false,
        quantity: 185,
        sort: 'Роз.1ОП с/з с крышкой  бр/защ полугерметичная 50шт/кор.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 1907,
        description: 'Розетка 1-я скрытой проводки с заземлением',
        price: 58.74,
        measure: 'шт',
        spec: false,
        quantity: 213,
        sort: 'Роз.1СП с/з  70шт/кор. (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 14106,
        description: 'Розетка 1-я скрытой проводки с заземлением со шторками',
        price: 66.24,
        measure: 'шт',
        spec: false,
        quantity: 390,
        sort: 'Роз.1СП с/з со шторками  (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 2216,
        description: 'Розетка 2-я открытой проводки без заземления',
        price: 52.98,
        measure: 'шт',
        spec: false,
        quantity: 86,
        sort: 'Роз.2ОП б/з    90шт/кор.  (20шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 1757,
        description: 'Розетка 2-я открытой проводки с заземлением',
        price: 59.76,
        measure: 'шт',
        spec: false,
        quantity: 855,
        sort: 'Роз.2ОП с/з     60шт/кор. (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 872,
        description: 'Розетка 2-я открытой проводки с заземлением брызгозащищенная полугерметичная с крышкой',
        price: 173.82,
        measure: 'шт',
        spec: false,
        quantity: 186,
        sort: 'Роз.2ОП с/з с крышкой бр/защ полугерметичная (30шт в коробке)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 873,
        description: 'Розетка 2-я скрытой проводки с заземлением',
        price: 71.58,
        measure: 'шт',
        spec: false,
        quantity: 139,
        sort: 'Роз.2СП с/з 22шт/кор.  (50шт./упак./10шт.упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 14107,
        description: 'Розетка 2-я скрытой проводки с заземлением со шторками',
        price: 88.26,
        measure: 'шт',
        spec: false,
        quantity: 49,
        sort: 'Роз.2СП с/з со шторками   (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 3492,
        description: 'Розетка 3-я открытой проводки с заземлением',
        price: 146.04,
        measure: 'шт',
        spec: false,
        quantity: 241,
        sort: 'Роз.3ОП с/з     35шт/кор.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Розетки и вилки',
        code: 1756,
        description: 'Розетка 4-я открытой проводки с заземлением',
        price: 198.48,
        measure: 'шт',
        spec: false,
        quantity: 173,
        sort: 'Роз.4ОП с/з   (25шт./упак./6шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Промышленные разъемы',
        code: 17171,
        description: 'Соединительный элемент РШ-ВШ 32А 3-штырьковый 220В открытой установки белый',
        price: 118.74,
        measure: 'шт',
        spec: false,
        quantity: 6,
        sort: 'РШ-ВШ 32А (3 штырьк.) 220В о/у белый',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Расходные материалы',
        code: 1236,
        description: 'Саморез 3,5х35',
        price: 0.68,
        measure: 'шт',
        spec: false,
        quantity: 7680,
        sort: 'Саморез 3,5х35',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Расходные материалы',
        code: 5519,
        description: 'Саморез 3,5х41',
        price: 0.84,
        measure: 'шт',
        spec: false,
        quantity: 24900,
        sort: 'Саморез 3,5х41  (1000 шт./уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 25601,
        description: 'Светодиодный модуль для ДПБ 12 Вт',
        price: 157.4,
        measure: 'шт',
        spec: true,
        quantity: 21000,
        sort: 'Светодиодный модуль для ДПБ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Расходные материалы',
        code: 4000,
        description: 'Соединительный изолирующий зажим СИЗ-1 (от 1,0 до 3,0 кв. мм) уп. 100 шт.',
        price: 59.76,
        measure: 'упак',
        spec: false,
        quantity: 5,
        sort: 'СИЗ-1 Соед. изолир. зажим (от 1,0 до 3,0 кв. мм) уп. 100 шт.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Расходные материалы',
        code: 19456,
        description: 'Соединительный изолирующий зажим СИЗ-2 (от 2,5 до 4,5 кв. мм) синий уп. 100 шт.',
        price: 93,
        measure: 'упак',
        spec: false,
        quantity: 4,
        sort: 'СИЗ-2 Соед. изолир. зажим (от 2,5 до 4,5 кв. мм) синий уп. 100 шт.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Расходные материалы',
        code: 4001,
        description: 'Соединительный изолирующий зажим СИЗ-3 (от 2,5 до 5,5 кв мм) уп. 100 шт.',
        price: 128.46,
        measure: 'упак',
        spec: false,
        quantity: 11,
        sort: 'СИЗ-3 Соед. изолир. зажим от (2,5 до 5,5 кв мм) уп. 100 шт.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Расходные материалы',
        code: 4003,
        description: 'Соединительный изолирующий зажим СИЗ-4 (от 3,5 до 11 кв мм) уп. 100 шт.',
        price: 176.04,
        measure: 'упак',
        spec: false,
        quantity: 3,
        sort: 'СИЗ-4 Соед. изолир. зажим (от 3,5 до 11 кв мм) уп. 100 шт.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода СИП и арматура к ним',
        code: 5843,
        description: 'Провод СИП-4 2х16',
        price: 35.16,
        measure: 'м',
        spec: true,
        quantity: 1100,
        sort: 'СИП-4 2х 16   (400м./бухта)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода СИП и арматура к ним',
        code: 7054,
        description: 'Провод СИП-4 4х16',
        price: 70.56,
        measure: 'м',
        spec: true,
        quantity: 3705,
        sort: 'СИП-4 4х 16   (200м./бухта)+ БАРАБАН',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Пускорегулирующая аппаратура для люминесцентных ламп',
        code: 794,
        description: 'Стартер СК-127 для ламп 18-20Вт',
        price: 5.4,
        measure: 'шт',
        spec: true,
        quantity: 14623,
        sort: 'СК-127 Стартер д/ламп 18-20Вт   (25шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Пускорегулирующая аппаратура для люминесцентных ламп',
        code: 795,
        description: 'Стартер СК-220 для ламп 36-40Вт',
        price: 4.98,
        measure: 'шт',
        spec: true,
        quantity: 14782,
        sort: 'СК-220 Стартер д/ламп 36-40Вт    (25шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 21992,
        description: 'Светильник светодиодный уличный СКУ 50 Вт',
        price: 2051.52,
        measure: 'шт',
        spec: false,
        quantity: 10,
        sort: 'СКУ  50 Вт светильник светодиодный уличный',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 21189,
        description: 'Светильник СПО 60х1 под LED лампу T8 ТДМ',
        price: 305.04,
        measure: 'шт',
        spec: false,
        quantity: 2,
        sort: 'СПО  60х1 Светильник под LED лампу T8 ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 24747,
        description: 'Светильник СПО 60х2 под LED лампу T8 (поликарбонат) ТДМ',
        price: 334.44,
        measure: 'шт',
        spec: false,
        quantity: 66,
        sort: 'СПО  60х2 Светильник под LED лампу T8 (поликарбонат) ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 20157,
        description: 'Светильник СПО 120х1 под LED лампу T8 ТДМ',
        price: 339.48,
        measure: 'шт',
        spec: false,
        quantity: 116,
        sort: 'СПО 120х1 Светильник под LED лампу T8 ТДМ  (4шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 23849,
        description: 'Светильник СПО 120х2 под LED лампу T8 (поликарбонат) ТДМ',
        price: 378,
        measure: 'шт',
        spec: true,
        quantity: 233,
        sort: 'СПО 120х2 Светильник под LED лампу T8 (поликарбонат) ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 17292,
        description: 'Светильник светодиодный СПП 2101 8Вт IP65',
        price: 168.3,
        measure: 'шт',
        spec: true,
        quantity: 2987,
        sort: 'СПП 2101 Светильник светодиодный 8Вт  (40шт./упак.)  ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светильники светодиодные',
        code: 16972,
        description: 'Светильник светодиодный СПП 2301 12Вт IP65',
        price: 193.08,
        measure: 'шт',
        spec: true,
        quantity: 4840,
        sort: 'СПП 2301 Светильник светодиодный 12Вт  (40шт./упак.)(на складе есть медуза 10вт IP65 код 20 540)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные фонари и аварийные указатели',
        code: 6675,
        description: 'Светильник ССА 1001 "ВЫХОД-EXIT" односторонний светодиодный IP20',
        price: 453.48,
        measure: 'шт',
        spec: false,
        quantity: 40,
        sort: 'ССА 1001 "ВЫХОД-EXIT" светильник одностор. светодиод. IP20 20шт.кор.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода СИП и арматура к ним',
        code: 17622,
        description: 'Скрепа-бугель усиленная СУ-20 (100 шт./уп.)',
        price: 1101,
        measure: 'упак',
        spec: false,
        quantity: 1,
        sort: 'СУ-20 Скрепа-бугель усиленная (100шт/уп)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трансформаторы',
        code: 14147,
        description: 'Трансформатор тока Т-0,66 30/5, класс точности 0,5, номинальная вторичная нагрузка 5 ВА',
        price: 485.7,
        measure: 'шт',
        spec: false,
        quantity: 7,
        sort: 'Т-0,66  30/5 кл. 0,5 5ВА Трансформатор тока   2018-11шт',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трансформаторы',
        code: 14942,
        description: 'Трансформатор тока Т-0,66 50/5, класс точности 0,5, номинальная вторичная нагрузка 5 ВА',
        price: 485.7,
        measure: 'шт',
        spec: false,
        quantity: 21,
        sort: 'Т-0,66  50/5 кл. 0,5 5ВА Трансформатор тока   2017г-7шт,2011г.-6шт.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трансформаторы',
        code: 3506,
        description: 'Трансформатор тока Т-0,66 75/5, класс точности 0,5, номинальная вторичная нагрузка 5 ВА',
        price: 485.7,
        measure: 'шт',
        spec: false,
        quantity: 2,
        sort: 'Т-0,66  75/5 кл. 0,5 5ВА Трансформатор тока   2017г.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трансформаторы',
        code: 3507,
        description: 'Трансформатор тока Т-0,66 100/5, класс точности 0,5, номинальная вторичная нагрузка 5 ВА',
        price: 485.7,
        measure: 'шт',
        spec: false,
        quantity: 12,
        sort: 'Т-0,66 100/5 кл. 0,5 5ВА Трансформатор тока   2018г.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трансформаторы',
        code: 3502,
        description: 'Трансформатор тока Т-0,66 150/5, класс точности 0,5, номинальная вторичная нагрузка 5 ВА',
        price: 485.7,
        measure: 'шт',
        spec: false,
        quantity: 7,
        sort: 'Т-0,66 150/5 кл. 0,5 5ВА Трансформатор тока',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трансформаторы',
        code: 3504,
        description: 'Трансформатор тока Т-0,66 200/5, класс точности 0,5, номинальная вторичная нагрузка 5 ВА',
        price: 485.7,
        measure: 'шт',
        spec: false,
        quantity: 9,
        sort: 'Т-0,66 200/5 кл. 0,5 5ВА Трансформатор тока   2018г.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трансформаторы',
        code: 3505,
        description: 'Трансформатор тока Т-0,66 300/5, класс точности 0,5, номинальная вторичная нагрузка 5 ВА',
        price: 485.7,
        measure: 'шт',
        spec: false,
        quantity: 12,
        sort: 'Т-0,66 300/5 кл. 0,5 5ВА Трансформатор тока ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трансформаторы',
        code: 10913,
        description: 'Трансформатор тока Т-0,66 400/5, класс точности 0,5, номинальная вторичная нагрузка 5 ВА',
        price: 485.7,
        measure: 'шт',
        spec: false,
        quantity: 2,
        sort: 'Т-0,66 400/5 кл. 0,5 5ВА Трансформатор тока',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники алюминиевые под опрессовку',
        code: 2708,
        description: 'Наконечник кабельный ТА-16-8-5,4 алюминиевый под опрессовку',
        price: 6.72,
        measure: 'шт',
        spec: false,
        quantity: 10,
        sort: 'ТА- 16-8-5,4 Наконечник алюмин.   (100шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники алюминиевые под опрессовку',
        code: 4712,
        description: 'Наконечник кабельный ТА-25-8-7 алюминиевый под опрессовку',
        price: 9.78,
        measure: 'шт',
        spec: false,
        quantity: 98,
        sort: 'ТА- 25-8-7 Наконечник алюмин. опрес.   (100шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники алюминиевые под опрессовку',
        code: 13209,
        description: 'Наконечник кабельный ТА-35-10-8 алюминиевый под опрессовку',
        price: 10.68,
        measure: 'шт',
        spec: false,
        quantity: 264,
        sort: 'ТА- 35-10-8 Наконечник алюмин. опрес.   (50шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники алюминиевые под опрессовку',
        code: 13210,
        description: 'Наконечник кабельный ТА-50-10-9 алюминиевый под опрессовку',
        price: 13.38,
        measure: 'шт',
        spec: false,
        quantity: 46,
        sort: 'ТА- 50-10-9 Наконечник алюмин. опрес.   (50шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники алюминиевые под опрессовку',
        code: 17629,
        description: 'Наконечник кабельный ТА 70-10-12 алюминиевый под опрессовку ',
        price: 17.76,
        measure: 'шт',
        spec: false,
        quantity: 7,
        sort: 'ТА 70-10-12 Наконечник алюмин. опрес.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники алюминиевые под опрессовку',
        code: 888,
        description: 'Наконечник кабельный ТА-95-12-13 алюминиевый под опрессовку',
        price: 20.04,
        measure: 'шт',
        spec: false,
        quantity: 17,
        sort: 'ТА- 95-12-13 Наконечник алюмин.опрес.   (25шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники алюминиевые под опрессовку',
        code: 13211,
        description: 'Наконечник кабельный ТА-120-12-14 алюминиевый под опрессовку',
        price: 27.6,
        measure: 'шт',
        spec: false,
        quantity: 66,
        sort: 'ТА-120-12-14 Наконечник алюмин.опрес.   (25шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники медные луженые под опрессовку',
        code: 17044,
        description: 'Наконечник кабельный ТМЛ-2,5-6-2,6 медный луженый под опрессовку',
        price: 5.46,
        measure: 'шт',
        spec: false,
        quantity: 665,
        sort: 'ТМЛ-  2,5-6-2,6 Наконечник мед.луж.опрес.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники медные луженые под опрессовку',
        code: 4513,
        description: 'Наконечник кабельный ТМЛ-4-5-3 медный луженый под опрессовку',
        price: 5.88,
        measure: 'шт',
        spec: false,
        quantity: 147,
        sort: 'ТМЛ-  4-5-3 Наконечник мед.луж.опрес.   (100шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники медные луженые под опрессовку',
        code: 3438,
        description: 'Наконечник кабельный ТМЛ-6-6-4 медный луженый под опрессовку',
        price: 6.54,
        measure: 'шт',
        spec: false,
        quantity: 3289,
        sort: 'ТМЛ-  6-6-4 Наконечник мед.луж.опрес.   (100шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники медные луженые под опрессовку',
        code: 1086,
        description: 'Наконечник кабельный ТМЛ-10-6-6 медный луженый под опрессовку',
        price: 11.64,
        measure: 'шт',
        spec: false,
        quantity: 472,
        sort: 'ТМЛ- 10-6-6 Наконечник мед.луж.опрес.   (100шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники медные луженые под опрессовку',
        code: 843,
        description: 'Наконечник кабельный ТМЛ-16-8-6 медный луженый под опрессовку',
        price: 12.78,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'ТМЛ- 16-8-6 Наконечник мед.луж.опрес.   (100шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники медные луженые под опрессовку',
        code: 844,
        description: 'Наконечник кабельный ТМЛ-25-8-7 медный луженый под опрессовку',
        price: 18.18,
        measure: 'шт',
        spec: false,
        quantity: 353,
        sort: 'ТМЛ- 25-8-7 Наконечник мед.луж.опрес.    (100шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники медные луженые под опрессовку',
        code: 845,
        description: 'Наконечник кабельный ТМЛ-35-8-10 медный луженый под опрессовку',
        price: 22.44,
        measure: 'шт',
        spec: false,
        quantity: 615,
        sort: 'ТМЛ- 35-8-10  Наконечник мед.луж.опрес.   (100шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники медные луженые под опрессовку',
        code: 846,
        description: 'Наконечник кабельный ТМЛ-50-10-11 медный луженый под опрессовку',
        price: 30.42,
        measure: 'шт',
        spec: false,
        quantity: 186,
        sort: 'ТМЛ- 50-10-11 Наконечник мед.луж.опрес.    (100шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники медные луженые под опрессовку',
        code: 12325,
        description: 'Наконечник кабельный ТМЛ-70-12-13 медный луженый под опрессовку',
        price: 38.94,
        measure: 'шт',
        spec: false,
        quantity: 87,
        sort: 'ТМЛ- 70-12-13 Наконечник мед.луж.опрес.   (100шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники медные луженые под опрессовку',
        code: 12326,
        description: 'Наконечник кабельный ТМЛ-95-12-15 медный луженый под опрессовку',
        price: 68.88,
        measure: 'шт',
        spec: false,
        quantity: 195,
        sort: 'ТМЛ- 95-12-15 Наконечник мед.луж.опрес.   (25шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники медные луженые под опрессовку',
        code: 13214,
        description: 'Наконечник кабельный ТМЛ-120-14-17 медный луженый под опрессовку',
        price: 94.44,
        measure: 'шт',
        spec: false,
        quantity: 50,
        sort: 'ТМЛ-120-14-17 Наконечник мед.луж.опрес.   (25шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Наконечники медные луженые под опрессовку',
        code: 1085,
        description: 'Наконечник кабельный ТМЛ-150-14-17 медный луженый под опрессовку',
        price: 135.06,
        measure: 'шт',
        spec: false,
        quantity: 47,
        sort: 'ТМЛ-150-14-17 Наконечник мед.луж.опрес.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубы для электропроводки',
        code: 298,
        description: 'Труба гофрированная ПВХ d16мм (100м)',
        price: 4.5,
        measure: 'м',
        spec: true,
        quantity: 4100,
        sort: 'Труба 16 гофр ПВХ (100м)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубы для электропроводки',
        code: 299,
        description: 'Труба жесткая гладкая ПВХ d16мм (3м)',
        price: 10.44,
        measure: 'м',
        spec: false,
        quantity: 1441,
        sort: 'Труба 16 жесткая ПВХ (по 3м)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубы для электропроводки',
        code: 3463,
        description: 'Труба гофрированная с протяжкой ПНД d16мм (100м)',
        price: 8.82,
        measure: 'м',
        spec: true,
        quantity: 2500,
        sort: 'Труба 16 ПНД гофрированная с протяжкой (100м)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубы для электропроводки',
        code: 300,
        description: 'Труба гофрированная ПВХ d20мм (100м)',
        price: 5.94,
        measure: 'м',
        spec: true,
        quantity: 11200,
        sort: 'Труба 20 гофр ПВХ (100м)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубы для электропроводки',
        code: 317,
        description: 'Труба жесткая гладкая ПВХ d20мм (3м)',
        price: 12.3,
        measure: 'м',
        spec: false,
        quantity: 495,
        sort: 'Труба 20 жесткая ПВХ (по 3м)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубы для электропроводки',
        code: 301,
        description: 'Труба гофрированная с протяжкой ПНД d20мм (100м)',
        price: 11.64,
        measure: 'м',
        spec: true,
        quantity: 2900,
        sort: 'Труба 20 ПНД гофрированная с протяжкой   (100м./бух.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубы для электропроводки',
        code: 2905,
        description: 'Труба гофрированная ПВХ d25мм (50м)',
        price: 9.6,
        measure: 'м',
        spec: false,
        quantity: 800,
        sort: 'Труба 25 гофр ПВХ (50м)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубы для электропроводки',
        code: 10020,
        description: 'Труба жесткая гладкая ПВХ d25мм (3м)',
        price: 18.12,
        measure: 'м',
        spec: false,
        quantity: 339,
        sort: 'Труба 25 жесткая ПВХ (по 3м)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубы для электропроводки',
        code: 11977,
        description: 'Труба гофрированная с протяжкой ПНД d25мм (50м)',
        price: 15.54,
        measure: 'м',
        spec: false,
        quantity: 550,
        sort: 'Труба 25 ПНД гофрированная с протяжкой (50м)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубы для электропроводки',
        code: 1231,
        description: 'Труба гофрированная ПВХ d32мм (50м)',
        price: 14.64,
        measure: 'м',
        spec: false,
        quantity: 400,
        sort: 'Труба 32 гофр ПВХ (25м)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубы для электропроводки',
        code: 4786,
        description: 'Труба жесткая гладкая ПВХ d32мм (3м)',
        price: 40.5,
        measure: 'м',
        spec: false,
        quantity: 15,
        sort: 'Труба 32 жесткая ПВХ (по 3м)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубы для электропроводки',
        code: 15859,
        description: 'Труба гофрированная с протяжкой ПНД d32мм (25м)',
        price: 19.86,
        measure: 'м',
        spec: false,
        quantity: 600,
        sort: 'Труба 32 ПНД гофрированная с протяжкой (25м)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубы для электропроводки',
        code: 2017,
        description: 'Труба гофрированная ПВХ d40мм',
        price: 19.32,
        measure: 'м',
        spec: false,
        quantity: 220,
        sort: 'Труба 40 гофр ПВХ (20м) ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубы для электропроводки',
        code: 4105,
        description: 'Труба жесткая гладкая ПВХ d40мм (3м)',
        price: 39.42,
        measure: 'м',
        spec: false,
        quantity: 24,
        sort: 'Труба 40 жесткая ПВХ (по 3м)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубы для электропроводки',
        code: 3388,
        description: 'Труба гофрированная ПВХ d50мм (15м)',
        price: 23.22,
        measure: 'м',
        spec: false,
        quantity: 300,
        sort: 'Труба 50 гофр ПВХ (15м)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 3188,
        description: 'Трубка термоусаживаемая ТУТ 1,5/0,75 желтая',
        price: 4.5,
        measure: 'м',
        spec: false,
        quantity: 24,
        sort: 'ТУТ  1,5/0,75 Трубка термоусаживаемая желтая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 3187,
        description: 'Трубка термоусаживаемая ТУТ 1,5/0,75 зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 13,
        sort: 'ТУТ  1,5/0,75 Трубка термоусаживаемая зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 3186,
        description: 'Трубка термоусаживаемая ТУТ 1,5/0,75 красная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 13,
        sort: 'ТУТ  1,5/0,75 Трубка термоусаживаемая красная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 3184,
        description: 'Трубка термоусаживаемая ТУТ 1,5/0,75 синяя',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 9,
        sort: 'ТУТ  1,5/0,75 Трубка термоусаживаемая синяя',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 3185,
        description: 'Трубка термоусаживаемая ТУТ 1,5/0,75 черная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 10,
        sort: 'ТУТ  1,5/0,75 Трубка термоусаживаемая черная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5782,
        description: 'Трубка термоусаживаемая ТУТ 3/1,5 желтая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 10,
        sort: 'ТУТ  3/1,5 Трубка термоусаживаемая желтая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5784,
        description: 'Трубка термоусаживаемая ТУТ 3/1,5 желто-зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 25,
        sort: 'ТУТ  3/1,5 Трубка термоусаживаемая желто-зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5783,
        description: 'Трубка термоусаживаемая ТУТ 3/1,5 зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 10,
        sort: 'ТУТ  3/1,5 Трубка термоусаживаемая зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5788,
        description: 'Трубка термоусаживаемая ТУТ 3/1,5 красная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 10,
        sort: 'ТУТ  3/1,5 Трубка термоусаживаемая красная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5063,
        description: 'Трубка термоусаживаемая ТУТ 3/1,5 синяя',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 9,
        sort: 'ТУТ  3/1,5 Трубка термоусаживаемая синяя',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5494,
        description: 'Трубка термоусаживаемая ТУТ 3/1,5 черная',
        price: 5.52,
        measure: 'м',
        spec: false,
        quantity: 12,
        sort: 'ТУТ  3/1,5 Трубка термоусаживаемая черная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5785,
        description: 'Трубка термоусаживаемая ТУТ 4/2 желтая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 9,
        sort: 'ТУТ  4/2 Трубка термоусаживаемая желтая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5786,
        description: 'Трубка термоусаживаемая ТУТ 4/2 желто-зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 10,
        sort: 'ТУТ  4/2 Трубка термоусаживаемая желто-зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5064,
        description: 'Трубка термоусаживаемая ТУТ 4/2 зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 14,
        sort: 'ТУТ  4/2 Трубка термоусаживаемая зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5787,
        description: 'Трубка термоусаживаемая ТУТ 4/2 красная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 13,
        sort: 'ТУТ  4/2 Трубка термоусаживаемая красная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 18683,
        description: 'Трубка термоусаживаемая ТУТ 4/2 синяя',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 13,
        sort: 'ТУТ  4/2 Трубка термоусаживаемая синяя',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5006,
        description: 'Трубка термоусаживаемая ТУТ 4/2 черная',
        price: 7.32,
        measure: 'м',
        spec: false,
        quantity: 10,
        sort: 'ТУТ  4/2 Трубка термоусаживаемая черная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5007,
        description: 'Трубка термоусаживаемая ТУТ 6/3 желтая',
        price: 13.02,
        measure: 'м',
        spec: false,
        quantity: 8,
        sort: 'ТУТ  6/3 Трубка термоусаживаемая желтая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 14465,
        description: 'Трубка термоусаживаемая ТУТ 6/3 желто-зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 10,
        sort: 'ТУТ  6/3 Трубка термоусаживаемая желто-зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 12802,
        description: 'Трубка термоусаживаемая ТУТ 6/3 зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 12,
        sort: 'ТУТ  6/3 Трубка термоусаживаемая зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5398,
        description: 'Трубка термоусаживаемая ТУТ 6/3 красная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 11,
        sort: 'ТУТ  6/3 Трубка термоусаживаемая красная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 12803,
        description: 'Трубка термоусаживаемая ТУТ 6/3 синяя',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 13,
        sort: 'ТУТ  6/3 Трубка термоусаживаемая синяя',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5399,
        description: 'Трубка термоусаживаемая ТУТ 6/3 черная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 11,
        sort: 'ТУТ  6/3 Трубка термоусаживаемая черная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 11182,
        description: 'Трубка термоусаживаемая ТУТ 8/4 желтая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 13,
        sort: 'ТУТ  8/4 Трубка термоусаживаемая желтая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 11184,
        description: 'Трубка термоусаживаемая ТУТ 8/4 желто-зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 12,
        sort: 'ТУТ  8/4 Трубка термоусаживаемая желто-зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5008,
        description: 'Трубка термоусаживаемая ТУТ 8/4 зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 10,
        sort: 'ТУТ  8/4 Трубка термоусаживаемая зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5075,
        description: 'Трубка термоусаживаемая ТУТ 8/4 красная',
        price: 10.2,
        measure: 'м',
        spec: false,
        quantity: 10,
        sort: 'ТУТ  8/4 Трубка термоусаживаемая красная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 11183,
        description: 'Трубка термоусаживаемая ТУТ 8/4 синяя',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 12,
        sort: 'ТУТ  8/4 Трубка термоусаживаемая синяя',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 12531,
        description: 'Трубка термоусаживаемая ТУТ 8/4 черная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 13,
        sort: 'ТУТ  8/4 Трубка термоусаживаемая черная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 14466,
        description: 'Трубка термоусаживаемая ТУТ 10/5 белая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 12,
        sort: 'ТУТ 10/5 Трубка термоусаживаемая белая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5237,
        description: 'Трубка термоусаживаемая ТУТ 10/5 желтая',
        price: 13.38,
        measure: 'м',
        spec: false,
        quantity: 12,
        sort: 'ТУТ 10/5 Трубка термоусаживаемая желтая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5239,
        description: 'Трубка термоусаживаемая ТУТ 10/5 желто-зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 13,
        sort: 'ТУТ 10/5 Трубка термоусаживаемая желто-зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5076,
        description: 'Трубка термоусаживаемая ТУТ 10/5 зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 10,
        sort: 'ТУТ 10/5 Трубка термоусаживаемая зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5238,
        description: 'Трубка термоусаживаемая ТУТ 10/5 красная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 12,
        sort: 'ТУТ 10/5 Трубка термоусаживаемая красная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5009,
        description: 'Трубка термоусаживаемая ТУТ 10/5 синяя',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 10,
        sort: 'ТУТ 10/5 Трубка термоусаживаемая синяя',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 14467,
        description: 'Трубка термоусаживаемая ТУТ 10/5 черная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 9,
        sort: 'ТУТ 10/5 Трубка термоусаживаемая черная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 8557,
        description: 'Трубка термоусаживаемая ТУТ 12/6 черная',
        price: 18.48,
        measure: 'м',
        spec: false,
        quantity: 18,
        sort: 'ТУТ 12/6 Трубка термоусаживаемая черная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5883,
        description: 'Трубка термоусаживаемая ТУТ 16/8 желтая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 14,
        sort: 'ТУТ 16/8 Трубка термоусаживаемая желтая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5886,
        description: 'Трубка термоусаживаемая ТУТ 16/8 желто-зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 9,
        sort: 'ТУТ 16/8 Трубка термоусаживаемая желто-зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5061,
        description: 'Трубка термоусаживаемая ТУТ 16/8 зеленая',
        price: 26.7,
        measure: 'м',
        spec: false,
        quantity: 10,
        sort: 'ТУТ 16/8 Трубка термоусаживаемая зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5884,
        description: 'Трубка термоусаживаемая ТУТ 16/8 красная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 13,
        sort: 'ТУТ 16/8 Трубка термоусаживаемая красная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5885,
        description: 'Трубка термоусаживаемая ТУТ 16/8 синяя',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 10,
        sort: 'ТУТ 16/8 Трубка термоусаживаемая синяя',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 14608,
        description: 'Трубка термоусаживаемая ТУТ 16/8 черная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 12,
        sort: 'ТУТ 16/8 Трубка термоусаживаемая черная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5240,
        description: 'Трубка термоусаживаемая ТУТ 20/10 желтая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 11,
        sort: 'ТУТ 20/10 Трубка термоусаживаемая желтая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5242,
        description: 'Трубка термоусаживаемая ТУТ 20/10 желто-зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 10,
        sort: 'ТУТ 20/10 Трубка термоусаживаемая желто-зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5243,
        description: 'Трубка термоусаживаемая ТУТ 20/10 зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 11,
        sort: 'ТУТ 20/10 Трубка термоусаживаемая зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5241,
        description: 'Трубка термоусаживаемая ТУТ 20/10 красная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 11,
        sort: 'ТУТ 20/10 Трубка термоусаживаемая красная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 2627,
        description: 'Трубка термоусаживаемая ТУТ 20/10 синяя',
        price: 50.34,
        measure: 'м',
        spec: false,
        quantity: 11,
        sort: 'ТУТ 20/10 Трубка термоусаживаемая синяя',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5062,
        description: 'Трубка термоусаживаемая ТУТ 20/10 черная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 21,
        sort: 'ТУТ 20/10 Трубка термоусаживаемая черная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5876,
        description: 'Трубка термоусаживаемая ТУТ 25/12,5 желтая',
        price: 67.2,
        measure: 'м',
        spec: false,
        quantity: 5,
        sort: 'ТУТ 25/12,5 Трубка термоусаживаемая желтая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5880,
        description: 'Трубка термоусаживаемая ТУТ 25/12,5 желто-зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 48,
        sort: 'ТУТ 25/12,5 Трубка термоусаживаемая желто-зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5877,
        description: 'Трубка термоусаживаемая ТУТ 25/12,5 зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 23,
        sort: 'ТУТ 25/12,5 Трубка термоусаживаемая зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5878,
        description: 'Трубка термоусаживаемая ТУТ 25/12,5 красная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 21,
        sort: 'ТУТ 25/12,5 Трубка термоусаживаемая красная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5879,
        description: 'Трубка термоусаживаемая ТУТ 25/12,5 синяя',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 10,
        sort: 'ТУТ 25/12,5 Трубка термоусаживаемая синяя',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 9065,
        description: 'Трубка термоусаживаемая ТУТ 25/12,5 черная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 15,
        sort: 'ТУТ 25/12,5 Трубка термоусаживаемая черная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5244,
        description: 'Трубка термоусаживаемая ТУТ 30/15 желтая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 5,
        sort: 'ТУТ 30/15 Трубка термоусаживаемая желтая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5247,
        description: 'Трубка термоусаживаемая ТУТ 30/15 желто-зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 12,
        sort: 'ТУТ 30/15 Трубка термоусаживаемая желто-зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5245,
        description: 'Трубка термоусаживаемая ТУТ 30/15 зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 6,
        sort: 'ТУТ 30/15 Трубка термоусаживаемая зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 5246,
        description: 'Трубка термоусаживаемая ТУТ 30/15 красная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 5,
        sort: 'ТУТ 30/15 Трубка термоусаживаемая красная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 2353,
        description: 'Трубка термоусаживаемая ТУТ 30/15 синяя',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 5,
        sort: 'ТУТ 30/15 Трубка термоусаживаемая синяя',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 2626,
        description: 'Трубка термоусаживаемая ТУТ 30/15 черная',
        price: 78.18,
        measure: 'м',
        spec: false,
        quantity: 5,
        sort: 'ТУТ 30/15 Трубка термоусаживаемая черная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 4888,
        description: 'Трубка термоусаживаемая ТУТ 40/20 желтая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 5,
        sort: 'ТУТ 40/20 Трубка термоусаживаемая желтая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 4892,
        description: 'Трубка термоусаживаемая ТУТ 40/20 желто-зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 8,
        sort: 'ТУТ 40/20 Трубка термоусаживаемая желто-зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 4889,
        description: 'Трубка термоусаживаемая ТУТ 40/20 зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 5,
        sort: 'ТУТ 40/20 Трубка термоусаживаемая зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 4890,
        description: 'Трубка термоусаживаемая ТУТ 40/20 красная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 5,
        sort: 'ТУТ 40/20 Трубка термоусаживаемая красная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 4891,
        description: 'Трубка термоусаживаемая ТУТ 40/20 синяя',
        price: 111.72,
        measure: 'м',
        spec: false,
        quantity: 8,
        sort: 'ТУТ 40/20 Трубка термоусаживаемая синяя',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 3885,
        description: 'Трубка термоусаживаемая ТУТ 40/20 черная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 6,
        sort: 'ТУТ 40/20 Трубка термоусаживаемая черная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 12804,
        description: 'Трубка термоусаживаемая ТУТ 60/30 желтая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 15,
        sort: 'ТУТ 60/30 Трубка термоусаживаемая желтая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 12805,
        description: 'Трубка термоусаживаемая ТУТ 60/30 зеленая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 17,
        sort: 'ТУТ 60/30 Трубка термоусаживаемая зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 12806,
        description: 'Трубка термоусаживаемая ТУТ 60/30 красная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 20,
        sort: 'ТУТ 60/30 Трубка термоусаживаемая красная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 12807,
        description: 'Трубка термоусаживаемая ТУТ 60/30 синяя',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 16,
        sort: 'ТУТ 60/30 Трубка термоусаживаемая синяя',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 8149,
        description: 'Трубка термоусаживаемая ТУТ 60/30 черная',
        price: 178.08,
        measure: 'м',
        spec: false,
        quantity: 6,
        sort: 'ТУТ 60/30 Трубка термоусаживаемая черная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 12808,
        description: 'Трубка термоусаживаемая ТУТ 80/40 желтая',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 5,
        sort: 'ТУТ 80/40 Трубка термоусаживаемая желтая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 12809,
        description: 'Трубка термоусаживаемая ТУТ 80/40 зеленая',
        price: 277.92,
        measure: 'м',
        spec: false,
        quantity: 16,
        sort: 'ТУТ 80/40 Трубка термоусаживаемая зеленая',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 12810,
        description: 'Трубка термоусаживаемая ТУТ 80/40 красная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 8,
        sort: 'ТУТ 80/40 Трубка термоусаживаемая красная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 12811,
        description: 'Трубка термоусаживаемая ТУТ 80/40 синяя',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 5,
        sort: 'ТУТ 80/40 Трубка термоусаживаемая синяя',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трубка термоусаживаемая ТУТ',
        code: 4994,
        description: 'Трубка термоусаживаемая ТУТ 80/40 черная',
        price: 0,
        measure: 'м',
        spec: false,
        quantity: 8,
        sort: 'ТУТ 80/40 Трубка термоусаживаемая черная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Бирки кабельные',
        code: 3918,
        description: 'Бирка кабельная У-134 квадратная',
        price: 2.76,
        measure: 'шт',
        spec: false,
        quantity: 8020,
        sort: 'У-134 Бирка кабельная квадратная  (100шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Бирки кабельные',
        code: 6057,
        description: 'Бирка кабельная У-135 круглая',
        price: 1.92,
        measure: 'шт',
        spec: false,
        quantity: 1040,
        sort: 'У-135 Бирка кабельная круглая   (100шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Бирки кабельные',
        code: 2656,
        description: 'Бирка кабельная У-136 треугольная',
        price: 2.46,
        measure: 'шт',
        spec: false,
        quantity: 308,
        sort: 'У-136 Бирка кабельная треугольная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Бирки кабельные',
        code: 14907,
        description: 'Бирка кабельная У-153 квадратная',
        price: 1.32,
        measure: 'шт',
        spec: false,
        quantity: 5650,
        sort: 'У-153 Бирка кабельная квадратная',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Сжимы ответвительные',
        code: 848,
        description: 'Сжим ответвительный У-731',
        price: 19.8,
        measure: 'шт',
        spec: false,
        quantity: 711,
        sort: 'У-731 Сжим ответвительный (12шт/уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Сжимы ответвительные',
        code: 1227,
        description: 'Сжим ответвительный У-733',
        price: 19.8,
        measure: 'шт',
        spec: false,
        quantity: 1169,
        sort: 'У-733 Сжим ответвительный (12шт/уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Сжимы ответвительные',
        code: 2266,
        description: 'Сжим ответвительный У-734',
        price: 19.8,
        measure: 'шт',
        spec: false,
        quantity: 106,
        sort: 'У-734 Сжим ответвительный (12шт/уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Сжимы ответвительные',
        code: 9392,
        description: 'Сжим ответвительный У-739',
        price: 19.26,
        measure: 'шт',
        spec: false,
        quantity: 292,
        sort: 'У-739 Сжим ответвительный (12шт/уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Сжимы ответвительные',
        code: 1047,
        description: 'Сжим ответвительный У-859',
        price: 71.58,
        measure: 'шт',
        spec: false,
        quantity: 85,
        sort: 'У-859 Сжим ответвительный   (6шт./уп)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Сжимы ответвительные',
        code: 2806,
        description: 'Сжим ответвительный У-871',
        price: 180.54,
        measure: 'шт',
        spec: false,
        quantity: 162,
        sort: 'У-871 Сжим ответвительный   (4шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Клипсы для труб ПВХ и ПНД',
        code: 18110,
        description: 'Угол 20 соединительный 90 градусов ПВХ ',
        price: 7.98,
        measure: 'шт',
        spec: false,
        quantity: 859,
        sort: 'Угол 20 соединительный 90гр. ПВХ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Удлинители',
        code: 19252,
        description: 'Удлинитель 4х30м ПВС 3х1,5 на пластиковой катушке т/защ. IP44',
        price: 2199.35,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'Удлинитель 4х30м на кат. т/защ. ПВС3х1,5 IP44',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Удлинители',
        code: 18988,
        description: 'Удлинитель 4х30м ПВС3х2,5 на пластиковой катушке т/защ. IP44',
        price: 2906.25,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'Удлинитель 4х30м на кат. т/защ. ПВС3х2,5 IP44',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Удлинители',
        code: 19253,
        description: 'Удлинитель 4х50м ПВС3х1,5 на пластиковой катушке т/защ. IP44',
        price: 3029.06,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'Удлинитель 4х50м на кат. т/защ. ПВС3х1,5 IP44',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Удлинители',
        code: 19254,
        description: 'Удлинитель 4х50м ПВС3х2,5 на пластиковой катушке т/защ. IP44',
        price: 3866.4,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'Удлинитель 4х50м на кат. т/защ. ПВС3х2,5 IP44',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Светодиодные фонари и аварийные указатели',
        code: 1152,
        description: 'Фонарь светодиодный аккумуляторный налобный',
        price: 289.62,
        measure: 'шт',
        spec: false,
        quantity: 25,
        sort: 'Фонарь налобный светод. аккум.',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Управление освещением',
        code: 16883,
        description: 'Фотореле ФРЛ-01 10Лк 6А',
        price: 138.48,
        measure: 'шт',
        spec: false,
        quantity: 5,
        sort: 'ФРЛ-01 Фотореле 10Лк 6А',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Управление освещением',
        code: 13207,
        description: 'Фотореле ФРЛ-02 5-50Лк 10А',
        price: 178.62,
        measure: 'шт',
        spec: false,
        quantity: 73,
        sort: 'ФРЛ-02 Фотореле 5-50Лк 10А   (100шт./кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Управление освещением',
        code: 12703,
        description: 'Фотореле ФРЛ-03 5-50Лк 25А',
        price: 249.54,
        measure: 'шт',
        spec: false,
        quantity: 81,
        sort: 'ФРЛ-03 Фотореле 5-50Лк 25А   (50шт./кор.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Управление освещением',
        code: 14605,
        description: 'Фотореле ФРЛ-11 2-100Лк 20А DIN-рейка (с датчиком IP65)',
        price: 533.04,
        measure: 'шт',
        spec: false,
        quantity: 2,
        sort: 'ФРЛ-11 Фотореле 2-100Лк 20А DIN-рейка (с датчиком IP65)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Хомуты нейлоновые',
        code: 3125,
        description: 'Хомут нейлоновый белый 100х2,5 (100шт.)',
        price: 27.9,
        measure: 'упак',
        spec: true,
        quantity: 30,
        sort: 'Хомут  100х2,5 нейл. бел. (100шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Хомуты нейлоновые',
        code: 1659,
        description: 'Хомут нейлоновый белый 150х2,5 (100шт.)',
        price: 39.06,
        measure: 'упак',
        spec: false,
        quantity: 41,
        sort: 'Хомут  150х2,5 нейл. бел. (100шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Хомуты нейлоновые',
        code: 23331,
        description: 'Хомут нейлоновый черный 150х2,5 (100шт.)',
        price: 41.76,
        measure: 'упак',
        spec: false,
        quantity: 19,
        sort: 'Хомут  150х2,5 нейл. черн. (100шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Хомуты нейлоновые',
        code: 20231,
        description: 'Хомут нейлоновый черный 200х2,5 (100 шт.)',
        price: 55.98,
        measure: 'упак',
        spec: false,
        quantity: 97,
        sort: 'Хомут  200х2,5 нейл. черн. (100 шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Хомуты нейлоновые',
        code: 340,
        description: 'Хомут нейлоновый белый 200х2,5-3 (100шт.)',
        price: 50.4,
        measure: 'упак',
        spec: true,
        quantity: 66,
        sort: 'Хомут  200х2,5-3 нейл. бел. (100шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Хомуты нейлоновые',
        code: 2851,
        description: 'Хомут нейлоновый белый 250х2,5-3 (100шт.)',
        price: 87.6,
        measure: 'упак',
        spec: false,
        quantity: 29,
        sort: 'Хомут  250х2,5-3 нейл. (100шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Хомуты нейлоновые',
        code: 1660,
        description: 'Хомут нейлоновый белый 300х3,6 (100шт.)',
        price: 127.92,
        measure: 'упак',
        spec: true,
        quantity: 901,
        sort: 'Хомут  300х3,6 нейл. бел. (100шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Хомуты нейлоновые',
        code: 2406,
        description: 'Хомут нейлоновый черный 300х3,6-4 (100шт)',
        price: 129.9,
        measure: 'упак',
        spec: false,
        quantity: 118,
        sort: 'Хомут  300х3,6-4 нейл. черн. (100шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Хомуты нейлоновые',
        code: 3663,
        description: 'Хомут нейлоновый белый 350х4,8-5 (100шт.)',
        price: 189.42,
        measure: 'упак',
        spec: false,
        quantity: 69,
        sort: 'Хомут  350х4,8-5 нейл. бел. (100шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Хомуты нейлоновые',
        code: 342,
        description: 'Хомут нейлоновый белый 400х5 (100шт.)',
        price: 218.16,
        measure: 'упак',
        spec: false,
        quantity: 42,
        sort: 'Хомут  400х5 нейл. бел. (100шт.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Хомуты нейлоновые',
        code: 3079,
        description: 'Хомут нейлоновый белый 500х5,0 (100шт.)',
        price: 325.14,
        measure: 'упак',
        spec: false,
        quantity: 17,
        sort: 'Хомут  500х5,0 нейл. бел. (100шт)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПУНП, ПУгНП и ШВВП',
        code: 677,
        description: 'Провод ШВВП 2х0,75',
        price: 9.48,
        measure: 'м',
        spec: false,
        quantity: 1090,
        sort: 'ШВВП 2х  0,75',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Провода ПУНП, ПУгНП и ШВВП',
        code: 21431,
        description: 'Провод ШВВП 3х0,75',
        price: 14.28,
        measure: 'м',
        spec: false,
        quantity: 200,
        sort: 'ШВВП 3х  0,75    (200м./бухта)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 23009,
        description: 'Шина "N" нулевая 6х9мм 24/1',
        price: 85.32,
        measure: 'шт',
        spec: false,
        quantity: 34,
        sort: 'Шина "N" нулевая 6х9мм 24/1',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 11586,
        description: 'Шина "N" нулевая 8х12мм 22/2 "UNIVERSAL"  (10шт.уп.)',
        price: 173.82,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'Шина "N" нулевая 8х12мм 22/2 "UNIVERSAL"  (10шт.уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 20362,
        description: 'Шина "N" нулевая в изоляторе на DIN-рейку 8x12мм 12 групп',
        price: 109.86,
        measure: 'шт',
        spec: false,
        quantity: 12,
        sort: 'Шина "N" нулевая в изоляторе на DIN-рейку 8x12мм 12 групп',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 15834,
        description: 'Шина нулевая N на DIN-рейку в корпусе 4х11 групп ',
        price: 575.28,
        measure: 'шт',
        spec: false,
        quantity: 20,
        sort: 'Шина "N" нулевая на DIN-рейку в корпусе 4х11 групп ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 15717,
        description: 'Шина нулевая N на DIN-рейку в корпусе 4х7 групп ',
        price: 450.66,
        measure: 'шт',
        spec: false,
        quantity: 12,
        sort: 'Шина "N" нулевая на DIN-рейку в корпусе 4х7 групп ТДМ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 17005,
        description: 'Шина 1ф гребёнка 63А',
        price: 227.94,
        measure: 'шт',
        spec: false,
        quantity: 19,
        sort: 'Шина 1ф гребёнка  63А',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 14243,
        description: 'Шина 2ф гребёнка 63А',
        price: 429.54,
        measure: 'м',
        spec: false,
        quantity: 7,
        sort: 'Шина 2ф гребёнка 63А',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 15301,
        description: 'Шина 3ф гребёнка 63А',
        price: 647.46,
        measure: 'м',
        spec: false,
        quantity: 20,
        sort: 'Шина 3ф гребёнка  63А',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 19498,
        description: 'Шина N-PE 6 на DIN-рейку',
        price: 37.44,
        measure: 'шт',
        spec: false,
        quantity: 99,
        sort: 'Шина N-PE  6 на DIN-рейку   (20шт.уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 15319,
        description: 'Шина N-PE 8 на DIN-рейку',
        price: 43.02,
        measure: 'шт',
        spec: true,
        quantity: 431,
        sort: 'Шина N-PE  8 на DIN-рейку  (20шт.уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 967,
        description: 'Шина N-PE 10 на DIN-рейку',
        price: 45.9,
        measure: 'шт',
        spec: true,
        quantity: 202,
        sort: 'Шина N-PE 10 на DIN-рейку  (10шт.уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 17288,
        description: 'Шина N-PE 12 на DIN-рейку',
        price: 54.36,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'Шина N-PE 12 на DIN-рейку  (20шт.уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Аксессуары для шкафов и щитов',
        code: 18691,
        description: 'Шина нулевая 8/1 6х9мм',
        price: 34.26,
        measure: 'шт',
        spec: false,
        quantity: 14,
        sort: 'Шина нулевая  8/1 6х9мм',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 12006,
        description: 'Щит навесной с монтажной панелью ЩМП-04-2 400х300х220 IP31',
        price: 972.54,
        measure: 'шт',
        spec: false,
        quantity: 1,
        sort: 'ЩМП-04-2 Щит навесной с монтажной панелью 400х300х220 IP31',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩМП',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 9326,
        description: 'Щит навесной с монтажной панелью ЩМП-06-2 500х400х220 IP54',
        price: 1783.62,
        measure: 'шт',
        spec: false,
        quantity: 2,
        sort: 'ЩМП-06-2 Щит навесной с монтажной панелью 500х400х220 IP54',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩМП',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 6480,
        description: 'Щит навесной с монтажной панелью ЩМП-09 (800х600х250мм) IP54',
        price: 3412.26,
        measure: 'шт',
        spec: false,
        quantity: 15,
        sort: 'ЩМП-09 Щит навесной с монтажной панелью 800х600х250 IP54',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩМП',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 5487,
        description: 'Корпус щита навесной металлический ЩРН-12з IP31',
        price: 714.78,
        measure: 'шт',
        spec: false,
        quantity: 3,
        sort: 'ЩРН-12з Корпус щита навесной металлический IP31',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩРН (металл)',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 3485,
        description: 'Корпус щита навесной металлический ЩРН-12з IP54',
        price: 1340.04,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'ЩРН-12з Корпус щита навесной металлический IP54',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩРН (металл)',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 5488,
        description: 'Корпус щита навесной металлический ЩРН-18з IP31',
        price: 938.76,
        measure: 'шт',
        spec: false,
        quantity: 2,
        sort: 'ЩРН-18з Корпус щита навесной металлический IP31',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩРН (металл)',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 1070,
        description: 'Корпус щита навесной металлический ЩРН-24з IP54',
        price: 1494.84,
        measure: 'шт',
        spec: false,
        quantity: 5,
        sort: 'ЩРН-24з Корпус щита навесной металлический IP54',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩРН (металл)',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 11575,
        description: 'Корпус щита навесной металлический ЩРН-24з IР31',
        price: 758.7,
        measure: 'шт',
        spec: false,
        quantity: 5,
        sort: 'ЩРН-24з Корпус щита навесной металлический IР31',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩРН (металл)',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 18743,
        description: 'Корпус щита навесной металлический ЩРН-36з IP31',
        price: 1170.06,
        measure: 'шт',
        spec: false,
        quantity: 2,
        sort: 'ЩРН-36з Корпус щита навесной металлический IP31',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩРН (металл)',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 2659,
        description: 'Бокс ЩРН-П-4 модуля пластик IP40',
        price: 188.16,
        measure: 'шт',
        spec: false,
        quantity: 13,
        sort: 'ЩРН-П- 4 Бокс, пластик IP40',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩРН-П (пластик)',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 6450,
        description: 'Бокс ЩРН-П-6 модулей пластик IP40',
        price: 232.38,
        measure: 'шт',
        spec: false,
        quantity: 26,
        sort: 'ЩРН-П- 6 Бокс, пластик IP40',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩРН-П (пластик)',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 9836,
        description: 'Бокс ЩРН-П-8 модулей пластик IP40',
        price: 285.06,
        measure: 'шт',
        spec: false,
        quantity: 13,
        sort: 'ЩРН-П- 8 Бокс, пластик IP40   (30шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩРН-П (пластик)',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 12377,
        description: 'Бокс ЩРН-П-8 модулей пластик IP65',
        price: 481.98,
        measure: 'шт',
        spec: false,
        quantity: 27,
        sort: 'ЩРН-П- 8 Бокс, пластик IP65',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩРН-П (пластик)',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 9986,
        description: 'Бокс ЩРН-П-10 модулей пластик IP40',
        price: 342.72,
        measure: 'шт',
        spec: false,
        quantity: 5,
        sort: 'ЩРН-П-10 Бокс, пластик IP40  (20шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩРН-П (пластик)',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 815,
        description: 'Бокс ЩРН-П-12 модулей пластик IP40',
        price: 358.98,
        measure: 'шт',
        spec: false,
        quantity: 23,
        sort: 'ЩРН-П-12 Бокс, пластик IP40   (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩРН-П (пластик)',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 1435,
        description: 'Бокс ЩРН-П-18 модулей пластик IP40',
        price: 561.66,
        measure: 'шт',
        spec: false,
        quantity: 22,
        sort: 'ЩРН-П-18 Бокс, пластик IP40  (10шт.уп.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩРН-П (пластик)',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 816,
        description: 'Бокс ЩРН-П-24 модуля пластик IP40',
        price: 747.36,
        measure: 'шт',
        spec: false,
        quantity: 4,
        sort: 'ЩРН-П-24 Бокс, пластик IP40   (10шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩРН-П (пластик)',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 17095,
        description: 'Бокс ЩРН-П-24 модуля пластик IP40 сосна',
        price: 1296.84,
        measure: 'шт',
        spec: false,
        quantity: 9,
        sort: 'ЩРН-П-24 Бокс, пластик IP40 сосна',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩРН-П (пластик)',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 817,
        description: 'Бокс ЩРН-П-36 модулей пластик IP40',
        price: 1293.54,
        measure: 'шт',
        spec: false,
        quantity: 2,
        sort: 'ЩРН-П-36 Бокс, пластик IP40  (5шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'ЩРН-П (пластик)',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 15827,
        description: 'Щит учета электроэнергии ЩУ-1ф/1-1-6 310х300х150 2 двери IP66',
        price: 1533.96,
        measure: 'шт',
        spec: true,
        quantity: 1,
        sort: 'ЩУ-1ф/1-1-6 Щит учета электроэнергии 310х300х150 2 двери IP66',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'Щиты учёта',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 17329,
        description: 'Щит учета электроэнергии ЩУ-3ф/1-0-3 310х300х150 1 дверь IP66',
        price: 1837.98,
        measure: 'шт',
        spec: false,
        quantity: 2,
        sort: 'ЩУ-3ф/1-0-3 Щит учета электроэнергии 310х300х150 1 дверь IP66',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'Щиты учёта',
        '000000043': ''
    },
    {
        folder: 'Шкафы пустые',
        code: 15678,
        description: 'Щит учета электроэнергии ЩУ-3ф/1-1-6 445х400х150 2 двери IP66',
        price: 2201.22,
        measure: 'шт',
        spec: true,
        quantity: 9,
        sort: 'ЩУ-3ф/1-1-6 Щит учета электроэнергии 445х400х150 2 двери IP66',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': 'Щиты учёта',
        '000000043': ''
    },
    {
        folder: 'Пускорегулирующая аппаратура для люминесцентных ламп',
        code: 13308,
        description: 'Электронный пускорегулирующий аппарат 1х18',
        price: 129.78,
        measure: 'шт',
        spec: false,
        quantity: 40,
        sort: 'ЭПРА 1х18 EB-T8-118-EA3 нар.   (100шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Пускорегулирующая аппаратура для люминесцентных ламп',
        code: 13309,
        description: 'Электронный пускорегулирующий аппарат 1х36',
        price: 136.56,
        measure: 'шт',
        spec: false,
        quantity: 242,
        sort: 'ЭПРА 1х36 EB-T8-136-EA3 нар.   (100шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Пускорегулирующая аппаратура для люминесцентных ламп',
        code: 12444,
        description: 'Электронный пускорегулирующий аппарат 2х18',
        price: 161.34,
        measure: 'шт',
        spec: false,
        quantity: 21,
        sort: 'ЭПРА 2х18 EB-T8-218-EA3 нар.   (50шт./упак./30шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Пускорегулирующая аппаратура для люминесцентных ламп',
        code: 12366,
        description: 'Электронный пускорегулирующий аппарат 2х36',
        price: 188.04,
        measure: 'шт',
        spec: true,
        quantity: 259,
        sort: 'ЭПРА 2х36 EB-T8-236-EA3 нар.   (50шт/упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Пускорегулирующая аппаратура для люминесцентных ламп',
        code: 12445,
        description: 'Электронный пускорегулирующий аппарат 4х18',
        price: 242.7,
        measure: 'шт',
        spec: true,
        quantity: 1780,
        sort: 'ЭПРА 4х18 EB-T8-418-EA3 нар.   (50шт./упак.100шт./упак.)',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трансформаторы',
        code: 11699,
        description: 'Ящик с понижающим трансформатором ЯТП-0,25-220-12 IP31',
        price: 1534.8,
        measure: 'шт',
        spec: false,
        quantity: 1,
        sort: 'ЯТП-0,25-220-12 Ящик 250А IP31 с понижающим трансформатором',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трансформаторы',
        code: 2657,
        description: 'Ящик с понижающим трансформатором ЯТП-0,25-220-24 250А IP31',
        price: 1466.94,
        measure: 'шт',
        spec: false,
        quantity: 0,
        sort: 'ЯТП-0,25-220-24 Ящик 250А IP31 с понижающим трансформатором ',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    },
    {
        folder: 'Трансформаторы',
        code: 1489,
        description: 'Ящик с понижающим трансформатором ЯТП-0,25-220-36 250А IP31',
        price: 1534.8,
        measure: 'шт',
        spec: false,
        quantity: 2,
        sort: 'ЯТП-0,25-220-36 Ящик 250А IP31 с понижающим трансформатором',
        '000000024': '',
        '000000025': '',
        '000000026': '',
        '000000027': '',
        '000000028': '',
        '000000029': '',
        '000000030': '',
        '000000031': '',
        '000000033': '',
        '000000034': '',
        '000000035': '',
        '000000036': '',
        '000000037': '',
        '000000038': '',
        '000000039': '',
        '000000040': '',
        '000000041': '',
        '000000042': '',
        '000000043': ''
    }
];

const folders = [{
                    "name":"Кабель и провод"
                    ,"children":[
                        {
                            "name":"Кабели ВВГнг, ВВГнг-LS и -FRLS"
                            ,"children":[]
                        },
                        {
                            "name":"Кабель NYM"
                            ,"children":[]
                        },
                        {
                            "name":"Кабель КГ"
                            ,"children":[]
                        },
                        {
                            "name":"Провод ПВС"
                            ,"children":[]
                        },
                        {
                            "name":"Провода и кабели с алюминиевой жилой"
                            ,"children":[]
                        },
                        {
                            "name":"Провода и кабели связи"
                            ,"children":[]
                        },
                        {
                            "name":"Провода ПВ-3 (ПуГВ) и ПВ-1 (ПуВ)"
                            ,"children":[]
                        },
                        {
                            "name":"Провода ПУНП, ПУгНП и ШВВП"
                            ,"children":[]
                        },
                        {
                            "name":"Провода СИП и арматура к ним"
                            ,"children":[]
                        }
                    ]
                },
                {
                    "name":"Низковольтная автоматика"
                    ,"children":[
                        {
                            "name":"Автоматические выключатели ABB на DIN-рейку"
                            ,"children":[]
                        },
                        {
                            "name":"Автоматические выключатели дифференциального тока (дифференциальные автоматы) 2п"
                            ,"children":[]
                        },
                        {
                            "name":"Автоматические выключатели на DIN-рейку"
                            ,"children":[]
                        },
                        {
                            "name":"Автоматические выключатели на монтажную панель"
                            ,"children":[]
                        },
                        {
                            "name":"Выключатели дифференциального тока (УЗО) 2п"
                            ,"children":[]
                        },
                        {
                            "name":"Выключатели нагрузки (рубильники) и переключатели"
                            ,"children":[]
                        },
                        {
                            "name":"Контакторы"
                            ,"children":[]
                        },
                        {
                            "name":"Плавкие вставки и держатели к ним"
                            ,"children":[]
                        },
                        {
                            "name":"Приборы учета электроэнергии"
                            ,"children":[]
                        },
                        {
                            "name":"Трансформаторы"
                            ,"children":[]
                        }
                    ]
                },
                {
                    "name":"Светотехника"
                    ,"children":[
                        {
                            "name":"Компактные люминесцентные лампы"
                            ,"children":[]
                        },
                        {
                            "name":"Лампы газоразрядные"
                            ,"children":[]
                        },
                        {
                            "name":"Лампы галогеновые"
                            ,"children":[]
                        },
                        {
                            "name":"Лампы накаливания"
                            ,"children":[]
                        },
                        {
                            "name":"Линейные люминесцентные лампы"
                            ,"children":[]
                        },
                        {
                            "name":"Прожектора светодиодные"
                            ,"children":[]
                        },
                        {
                            "name":"Пускорегулирующая аппаратура для люминесцентных ламп"
                            ,"children":[]
                        },
                        {
                            "name":"Светильники люминесцентные"
                            ,"children":[]
                        },
                        {
                            "name":"Светильники настенно-потолочные"
                            ,"children":[]
                        },
                        {
                            "name":"Светильники подвесные"
                            ,"children":[]
                        },
                        {
                            "name":"Светильники светодиодные"
                            ,"children":[]
                        },
                        {
                            "name":"Светодиодные лампы"
                            ,"children":[]
                        },
                        {
                            "name":"Светодиодные фонари и аварийные указатели"
                            ,"children":[]
                        },
                        {
                            "name":"Управление освещением"
                            ,"children":[]
                        }
                    ]
                },
                {
                    "name":"Электромонтажные изделия"
                    ,"children":[
                        {
                            "name":"Аксессуары для шкафов и щитов"
                            ,"children":[]
                        },
                        {
                            "name":"Бирки кабельные"
                            ,"children":[]
                        },
                        {
                            "name":"Гильзы медные луженые"
                            ,"children":[]
                        },
                        {
                            "name":"Зажимы винтовые ЗВИ (клеммные колодки)"
                            ,"children":[]
                        },
                        {
                            "name":"Знаки электробезопасности"
                            ,"children":[]
                        },
                        {
                            "name":"Изолента"
                            ,"children":[]
                        },
                        {
                            "name":"Инструмент ручной"
                            ,"children":[]
                        },
                        {
                            "name":"Кабельные каналы (короба)"
                            ,"children":[]
                        },
                        {
                            "name":"Клеммники WAGO"
                            ,"children":[]
                        },
                        {
                            "name":"Клеммники из PRC"
                            ,"children":[]
                        },
                        {
                            "name":"Клипсы для труб ПВХ и ПНД"
                            ,"children":[]
                        },
                        {
                            "name":"Коробки распределительные (распаячные)"
                            ,"children":[]
                        },
                        {
                            "name":"Коробки установочные (подрозетники)"
                            ,"children":[]
                        },
                        {
                            "name":"Металлорукав"
                            ,"children":[]
                        },
                        {
                            "name":"Наконечники алюминиевые под опрессовку"
                            ,"children":[]
                        },
                        {
                            "name":"Наконечники медные луженые под опрессовку"
                            ,"children":[]
                        },
                        {
                            "name":"Наконечники НШвИ (штыревые)"
                            ,"children":[]
                        },
                        {
                            "name":"Расходные материалы"
                            ,"children":[]
                        },
                        {
                            "name":"Сжимы ответвительные"
                            ,"children":[]
                        },
                        {
                            "name":"Средства защиты"
                            ,"children":[]
                        },
                        {
                            "name":"Трубка термоусаживаемая ТУТ"
                            ,"children":[]
                        },
                        {
                            "name":"Трубы для электропроводки"
                            ,"children":[]
                        },
                        {
                            "name":"Хомуты нейлоновые"
                            ,"children":[]
                        },
                        {
                            "name":"Шкафы пустые"
                            ,"children":[]
                        },
                        {
                            "name":"Электроизмерительные приборы"
                            ,"children":[]
                        },
                        {
                            "name":"Элементы питания и аккумуляторы"
                            ,"children":[]
                        }
                    ]
                },
                {
                    "name":"Электроустановочные изделия"
                    ,"children":[
                        {
                            "name":"Выключатели"
                            ,"children":[]
                        },
                        {
                            "name":"Патроны"
                            ,"children":[]
                        },
                        {
                            "name":"Промышленные разъемы"
                            ,"children":[]
                        },
                        {
                            "name":"Розетки и вилки"
                            ,"children":[]
                        },
                        {
                            "name":"Удлинители"
                            ,"children":[]
                        },
                        {
                            "name":"Электроустановочные изделия из каучука"
                            ,"children":[]
                        }
                    ]
                }
          ];

export {goods, folders};
import React, {useEffect} from 'react';

const ContactPage = (props) => {

    // useEffect(()=>{
    //     const script = document.createElement("script");
    //     script.src="https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A073d5308676f4e8bcc2546dc319ea3e0e27e85b381c46d6f18933e7e63543cd0&amp;width=700&amp;height=468&amp;lang=ru_RU&amp;scroll=true";
    //     script.async = true;
    //     script.id = 'yandexScript';
    //
    //     const scriptElem = document.querySelector('#yandexScript');
    //
    //     if (!scriptElem){
    //         console.log('script: ' + scriptElem);
    //         const mapElem = document.querySelector('#yandexMap');
    //         mapElem.appendChild(script);
    //     }
    //
    // });

    return (
        <div className="contacts">
            <div className="container">
                <div className="row fx fx-wrap">
                    <div className="col-3">
                        <div className="container__box">
                            <div className="title">
                                <h1 className="title__h1">Как добраться</h1>
                            </div>
                            <ul className="contacts__list">
                                <li className="contacts__item">
                                    <i className="contacts__icon contacts__icon--point"></i>
                                    <div className="contacts__info">
                                        <p className="contacts__name">Адрес склада</p>
                                        <p className="contacts__data">г. Москва<br/>
                                            ул. Ясеневая, вл. 14</p>
										<p className="contacts__data relations__item"><a href="https://yandex.ru/maps/-/CCUmRHartA">Открыть в Яндекс.Картах</a></p>
                                    </div>
                                </li>
                                <li className="contacts__item">
                                    <i className="contacts__icon contacts__icon--clock"></i>
                                    <div className="contacts__info">
                                        <p className="contacts__name">Время работы</p>
                                        <p className="contacts__data">Пн-Пт с 9:00 до 18:00</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-9">
                        <div id="map" className="map-">
                            <img src={require('../static/map.jpg')}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ContactPage;
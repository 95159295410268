import React from 'react';
import './productCard.css';
import ENV from "../../settings/env";
import PropertiesTable from  "../../components/PropertiesTable";
const SERVER = ENV.server || 'http://localhost:3001';

class ProductCard extends React.Component{

    constructor(props){
        super(props);
        this.state = props.item;
        this.state.attributes = [];
        this.state.initialAmount = this.props.orderedAmount;
    };

    increaseAmount = (event) => {
        this.props.updateAmount(this.state.code,
            Number(event.currentTarget.previousSibling.value) + this.props.item.rate, this.state.rate);
    };

    decreaseAmount = (event) => {
        this.props.updateAmount(this.state.code,
            Number(event.currentTarget.nextSibling.value) - this.props.item.rate, this.state.rate);
    };

    handleAmountEnter = (event) => {
        this.props.updateAmount(this.state.code, event.target.value);
    };

    handleFocusOut = (event) =>{
        this.props.updateAmount(this.state.code, event.target.value, this.state.rate);
    };

    handleFocus = (event) => event.target.select();

    componentDidMount() {
        this.getAttributes();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        this._input.focus();
    }

    getAttributes = () =>{
        fetch(`${SERVER}\\attributes\\${this.state.code}`)
            .then(res=>res.json())
            .then(attributesRes=> {
                this.setState({attributes:attributesRes});
                return attributesRes;
            })
            .catch(e=>console.log(e));
    };

    restoreAmount = ()=>{
        this.props.updateAmount(this.state.code,
            Number(this.state.initialAmount), this.state.rate);
            this.props.clearItem();
    };

    handleKeyPress = (event) =>{

        switch (event.key) {
            case 'Enter':
                this.props.clearItem();
                break;
            case 'Escape':
                this.restoreAmount();
                break;
        };
    };

    render(){

        return (
            <div className='profile-modal'>
                <div onKeyDown={this.handleKeyPress}
                    className="modal__box">
                    <button className="close modal__close" id={'cancel'} onClick={this.restoreAmount}></button>
                    <div className="product">
                        <div className="product__img"><img src={`${ENV.imgPath}${this.state.code}.jpg`} style={{width: '200px'}} alt="Фото"/></div>
                        <p className="product__name">{`${this.state.description} (${this.state.code})`}</p>
                        <div className="product__info fx fx-between align-center">
                            <div className="product__sum">
                                <p className="product__price">{`${this.state.price} руб.`}</p>
                                <p className="product__quantity">{`Остаток: ${this.state.quantity} ${this.state.measure}`}</p>
                            </div>
                            <div className="amount product__amount flex-center">
                                <span className="speed down" onClick={this.decreaseAmount}></span>
                                <input id={'amount'} type="text" pattern="[0-9]*" inputMode="numeric" ref={c => (this._input = c)}
                                       value={this.props.orderedAmount || 0}
                                       onChange={this.handleAmountEnter}
                                       onFocus={this.handleFocus}
                                       onBlur={this.handleFocusOut} />
                                <span className="speed up" onClick={this.increaseAmount}></span>
                            </div>
                        </div>
                        <div className='w-50 center'>
                            <PropertiesTable
                                properties = {this.state.attributes}
                            />
                        </div>
                        <div className="product__btns fx align-center">
                            <a href="#" className="btn btn_blue" onClick={this.props.clearItem}>Добавить<br/><small>[Enter]</small></a>
                            <a href="#" className="product__close" onClick={this.restoreAmount}>Закрыть<br/><small>[Esc]</small></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

export default ProductCard;
import React from 'react';
import BasketCard from '../../components/BasketCard';
import Login from '../Login/Login';
import ENV from '../../settings/env';

class NavSticky extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            top: 0,
            height: 0,
            scroll: 0,
            searchField: undefined
        }
    }

    handleScroll = () =>{
        this.setState({scroll: window.scrollY});
    };

    componentDidMount() {
        const el = document.querySelector('#navSticky');
        this.setState({searchField:document.querySelector('#searchField')});
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.state.searchField.value = this.props.searchString;
    }

    pageLinkOnClick = (event) => {
        this.props.onRouteChange(event.target.name);
    };

    handleSearchInput = (event) =>{
        const searchField = document.querySelector('#searchField');

        if (searchField.value.length > 1){
            this.props.updateSearchString(searchField.value);
            searchField.placeholder = 'поиск товара';
        } else {
            searchField.placeholder = 'введите более 1го символа';
            this.clearSearchField(searchField);
        }

    };

    handleEnterPress = (event) =>{
        if (event.key === 'Enter') {
            this.handleSearchInput();
        }
    };

    clearSearchField = (searchField) => {
        searchField.value = '';
    };

    clearSearchString = (event) => {
        const searchField = document.querySelector('#searchField');
        searchField.placeholder = 'поиск товара';
        // this.clearSearchField(searchField);
        this.props.updateSearchString('');
        const w = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        if (w < 1200) {
            const searchForm = document.querySelector('#searchForm');
            searchForm.style.display = "none"
        }
    };

    handlepPriceGet = (event) => {

    };

    openSearchPanel = (event) => {
        const searchForm = document.querySelector('#searchForm');
        console.log(searchForm);
        searchForm.style.display="block";
    };

    hideSearchPanel = (event) => {
        const searchForm = document.querySelector('#searchForm');
        console.log(searchForm);
        searchForm.style.display="none";
    };

    toggleMenu = (event) => {
        this.props.onMenuToggle();
    }

    render() {

        const isStick = this.state.scroll > this.state.top;
        let navClass = "flex items-stretch bw-2 shadow-5-l tc bg-transparent"
            + (isStick ?
                " fixed w-100 left-0 top-0 pr3 pl3" :
                " br3 ma2");
        const pageLinkClass = "f6 f5-l link black-90 underline-hover pointer hover-blue dib pa3 ph4-l";
        const route = this.props.route;

        let buttonClass = "navbar-toggle" + (this.props.isMenuOpen ? ' active': '')

        return(
            <div className="header__top fx fx-between align-center">
                <div className="logo">
                    <a href="/"><span> </span><br/><span>Оперативность для профессионалов</span></a>
                </div>
                <div className="search" id={'searchForm'}>
                    <label className="search__field"><input
                        id={'searchField'}
                        onKeyPress={this.handleEnterPress}
                        placeholder="Поиск товаров"
                        type="text" name="searchText">
                    </input></label>
                    <button className="search__btn" onClick={this.handleSearchInput}></button>
                    <span className="close search__close speed" onClick={this.clearSearchString}></span>
                </div>
                <div className="relations">
                    {/*<p className="relations__title">Контакты</p>
                    <p className="relations__item"><a href="tel:+74995022378">+7(499)502-23-78</a></p>
                    <p className="relations__item"><a href="mailto:info@6694040.info">info@6694040.info</a></p> */}
                </div>
                <div className="panel">
                    <button className="panel__search" onClick={this.openSearchPanel}></button>
                    <button className={buttonClass} onClick={this.toggleMenu}>
                        <span className="icon-bar speed"></span>
                        <span className="icon-bar speed"></span>
                        <span className="icon-bar speed"></span>
                    </button>
                </div>
                <BasketCard
                    orderSum={this.props.orderSum}
                    goodsAmount={this.props.goodsAmount}
                    onRouteChange={this.props.onRouteChange}
                />
            </div>
        )

    }

};

export default NavSticky;
import React from 'react';
import ENV from "../settings/env";

class CatalogItem extends React.Component{
    constructor(props){
        super(props);
    }

    increaseAmount = (event) => {
        this.props.updateAmount(this.props.code,
            Number(event.currentTarget.previousSibling.value) + this.props.item.rate, this.props.item.rate);
    };

    decreaseAmount = (event) => {
        this.props.updateAmount(this.props.code,
            Number(event.currentTarget.nextSibling.value) - this.props.item.rate, this.props.item.rate);
    };

    handleAmountEnter = (event) => {
        this.props.updateAmount(this.props.code, event.target.value);
    };

    handleFocusOut = (event) => {
        this.props.updateAmount(this.props.code, event.target.value, this.props.item.rate);
    };

    handleFocus = (event) => event.target.select();

    openProductCard = (event) => {
        event.preventDefault();
        this.props.setSelectedItem(this.props.item);
    };

    handleCancelItem = (event) => {
      this.props.updateAmount(this.props.code,0);
    };

    render(){
        const {code, set, quantity, price, amount, measure} = this.props;
        //const img = `${ENV.imgThumbPath}${code}.jpg`;
        const img = `${ENV.imgPath}${code}.jpg`;
        const alert = window.sessionStorage.getItem(code);
        if (alert) {
            window.sessionStorage.removeItem(code);
            console.log(code, alert);
        }
        const rate = this.props.item.rate;
        const description = this.props.description + ((rate > 1) ? ` (по ${rate} ${measure})` : '');


        return (
            <li className="catalog__item">
                <span className="option-1 catalog__img" onClick={this.openProductCard}><a href="#"><img src={img}
                                                                         alt="Фото"/></a></span>
                <span className="option-2 catalog__code" onClick={this.openProductCard}>{code}</span>
                <span className="option-3 catalog__name" onClick={this.openProductCard}><a
                    href="#">{description}</a></span>
                <div className="option-4 amount flex-center">
                    <span className="speed down" onClick={this.decreaseAmount}></span>
                    <input id={'amount'} type="text" value={Number(amount)}
                           onChange={this.handleAmountEnter}
                           onFocus={this.handleFocus}
                           onBlur={this.handleFocusOut}/>
                    <span className="speed up" onClick={this.increaseAmount}></span>

                </div>
                <span className="option-5 catalog__num">{`${Number(price).toFixed(2).replace('.', ',')}`}</span>
                <span className="option-6 catalog__num">{`${(amount*price).toFixed(2).replace('.', ',')}`}</span>
                <span className="option-7 catalog__num">{`${quantity} ${measure}`}</span>
                {this.props.showCancelButton &&
                <div className='w-10 pointer' onClick={this.handleCancelItem}>
                    ✖
                </div>}
            </li>
        );
    }
};

export default CatalogItem;
import React from 'react';

const Footer = (props) => {
    const today = new Date();
    return(
        <footer className="footer">
            <div className="container">
                <p className="copyright">{'© '}{today.getFullYear()}</p>
            </div>
        </footer>
    )
};

export default Footer;
import React from 'react';

const AboutPage = (props) => {

    return (
        <div className="about">
            <div className="container">
                <div className="wrap">
                    <div className="box">
                        <div className="title">
                            <h1 className="title__h1">Наш коллектив<span
                                className="blue"> </span> представляет электротехническую
                                продукцию различных торговых марок в оптовом и мелкооптовом сегменте.</h1>
                        </div>
                        <p className="paragraph">Мы преднамеренно указываем лишь самые важные характеристики изделий, не
                            вдаваясь в детали и не указывая производителей. В поисках наилучшего соотношения качества и
                            цены перечень наших поставщиков постоянно расширяется, и совершенству в этом не существует
                            предела.</p>
                        <p className="paragraph">Нашей главной задачей является обеспечение покупателей наиболее
                            востребованными позициями из наличия на складе. Никаких «перемещений» и ожиданий: либо есть,
                            либо нет.</p>
                        <p className="paragraph">
                            <a href={('/Electromarket_card.docx')}>Реквизиты</a>
                        </p>
                    </div>

                    <div className="vantage">
                        <div className="title">
                            <h2 className="title__h2">В нашем лице Вы найдёте надёжного партнёра по следующим
                                причинам:</h2>
                        </div>
                        <div className="row fx fx-wrap">

                            <div className="col-4">
                                <p className="vantage__item">стопроцентная актуальность остатков;</p>
                            </div>

                            <div className="col-4">
                                <p className="vantage__item">идентификация покупателя по ИНН с возможностью выставления
                                    счета на Вашу организацию непосредственно с нашего сайта;</p>
                            </div>

                            <div className="col-4">
                                <p className="vantage__item">самовывоз в день возникновения потребности. Информация о&nbsp;зачислении денежных средств поступает мгновенно, и мы сразу же отправляем заказ на
                                    сборку.</p>
                            </div>

                        </div>
                    </div>

                    <div className="title about__text">
                        <h2 className="title__h2"><span>Верим, что Ваше сотрудничество с нами превзойдёт самые высокие ожидания!</span>
                        </h2>
                    </div>

                </div>
            </div>
        </div>
    )
};

export default AboutPage;
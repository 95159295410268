import React from 'react';
import BasketCard from '../../components/BasketCard';
import Login from '../Login/Login';
import ENV from '../../settings/env';

class NavSticky2 extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            top: 0,
            height: 0,
            scroll: 0,
            searchField: undefined
        }
    }

    handleScroll = () =>{
        this.setState({scroll: window.scrollY});
    };

    componentDidMount() {
        const el = document.querySelector('#navSticky');
        this.setState({ searchField:document.querySelector('#searchField')});
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.state.searchField.value = this.props.searchString;
    }

    pageLinkOnClick = (event) => {
        this.props.onRouteChange(event.target.name);
        const w = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        if (w < 1200) {
            this.props.onMenuToggle();
        }
    };

    handleSearchInput = (event) =>{
        const searchField = document.querySelector('#searchField');

        if (searchField.value.length > 3){
            this.props.updateSearchString(searchField.value);
            searchField.placeholder = 'поиск товара';
        } else {
            searchField.placeholder = 'введите более 3х символов';
            this.clearSearchField(searchField);
        }

    };

    handleEnterPress = (event) =>{
        if (event.key === 'Enter') {
            this.handleSearchInput();
        }
    };

    clearSearchField = (searchField) => {
        searchField.value = '';
    };

    clearSearchString = (event) => {
        const searchField = document.querySelector('#searchField');
        searchField.placeholder = 'поиск товара';
        // this.clearSearchField(searchField);
        this.props.updateSearchString('');
    };

    handlepPriceGet = (event) => {

    };

    render() {

        const isStick = this.state.scroll > this.state.top;
        let navClass = "nav fx fx-between align-center"
            + (this.props.isMenuOpen ?
                " open" :
                "");
        const pageLinkClass = "nav__link";
        const route = this.props.route;

        return(
            <div className="box fx align-center" id='navSticky2'>
                <p className="header__info"><strong>Мы отгружаем продукцию <br/>ИСКЛЮЧИТЕЛЬНО</strong> юридическим
                    лицам и&nbsp;индивидуальным предпринимателям!</p>
                <nav className={navClass}>
                    <div className="nav__logo">
                        <a href="/"><span> </span><br/><span>Оперативность для профессионалов</span></a>
                    </div>
                    <ul className="nav__list fx">
                        <li className="nav__item"><a href="#" name='catalog' className={pageLinkClass + ((route==='catalog') ? ' active' : '')} onClick={this.pageLinkOnClick}>Каталог</a></li>
                        <li className="nav__item"><a href="#" name='about' className={pageLinkClass + ((route==='about') ? ' active' : '')} onClick={this.pageLinkOnClick}>О нас</a></li>
                        <li className="nav__item"><a href="#" name='contacts' className={pageLinkClass + ((route==='contacts') ? ' active' : '')} onClick={this.pageLinkOnClick}>Как добраться</a></li>
                        <li className="nav__item"><a href={`https://hypersnab.ru/gsimport/price`}
                                                     download={'ActualPrice.xls'} className="nav__link" onClick={this.pageLinkOnClick}>Прайс</a></li>
                    </ul>
                    <div className="nav__relations">
                        {/*<p className="relations__item"><a href="tel:+74995022378">+7(499)502-23-78</a></p>
                        <p className="relations__item"><a href="mailto:info@6694040.info">info@6694040.info</a></p>*/}
                    </div>
                    <Login
                        toggleProfile = {this.props.toggleProfile}
                        setUserStatus = {this.props.setUserStatus}
                        onRouteChange = {this.props.onRouteChange}
                        inn = {this.props.inn}
                        userName = {this.props.userName}
                        userStatus={this.props.userStatus}
                    />
                </nav>
            </div>
        )

    }

};

export default NavSticky2;
import React, {useState,useEffect} from 'react';

class BasketCard extends React.Component{

    constructor(props){
        super(props)
    }

    basketPressHandler = ()=>{
        this.props.onRouteChange('basket');
    };

    // const [sum, setSum] = useState(100);
    //
    // useEffect(()=>{
    //     document.title = `React hooks says: ${sum}`;
    //     return ()=> alert(`${sum} : ORLY?`);
    // });

    render(){
        return(
            <div className="basket fx align-center" onClick={this.basketPressHandler}>
                <i className="basket__icon"></i>
                <div className="basket__box">
                    <p className="basket__positions"><span className="basket__row">Позиций</span><span
                        className="basket__info"> {`${this.props.goodsAmount}`}</span></p>
                    <p className="basket__sum"><span className="basket__row">Сумма</span><span
                        className="basket__info"> {`${this.props.orderSum}`} ₽</span></p>
                </div>
            </div>
        )
    }

};

export default BasketCard;
import React from 'react';

const CatalogHeader = (props) => {

    const handleOrderRemove = () =>{
        // eslint-disable-next-line no-restricted-globals
        const result = confirm('Вы действительно хотите очистить корзину?');
        if (result) props.removeOrder();
    };

    return (
        <li className="catalog__head">
            <span className="option-1 text-center">{'Фото'}</span>
            <span className="option-2 text-center">{'Код'}</span>
            <span className="option-3">{'Наименование'}</span>
            <span className="option-4">{`Заказано`}</span>
            <span className="option-5">{`Цена (руб.)`}</span>
            <span className="option-6">{`Сумма (руб.)`}</span>
            <span className="option-7">{`Остаток`}</span>
            {props.showCancelButton && <span className='w-10 ma0 red pointer hover-dark-blue underline-hover b' onClick={handleOrderRemove}>{'X'}</span>}
        </li>
    );
};

export default CatalogHeader;